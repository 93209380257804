import { useState, useEffect } from 'react';
import {
    Box,
    FormControlLabel,
    Switch,
    Typography,
    FormGroup,
    Checkbox,
    Grid,
    Paper,
    Chip,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    isWeekend,
    format,
    eachWeekOfInterval,
    eachDayOfInterval,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    isWithinInterval,
    isSameMonth,
    isBefore,
    isAfter,
} from 'date-fns';
import { subDays } from 'date-fns';
import { styled } from "@mui/system";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    width: '100%',
    maxWidth: '600px'
}));

const DayCell = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isCurrentMonth' && prop !== 'isSelected' && prop !== 'isWeekend'
})(({ theme, isCurrentMonth, isSelected, isWeekend }) => ({
    padding: 0,
    textAlign: 'center',
    backgroundColor: isSelected
        ? theme.palette.primary.light
        : isWeekend
            ? theme.palette.grey[50]
            : 'white',
    color: !isCurrentMonth
        ? theme.palette.text.disabled
        : '#000000',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    transition: 'all 0.2s ease-in-out',
    cursor: 'default',
    '&:hover': {
        backgroundColor: isSelected
            ? theme.palette.primary.light
            : theme.palette.grey[100],
    },
    opacity: !isCurrentMonth ? 0.5 : 1,
}));

const WeekdayHeader = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '12px',
    padding: 0
}));

const PreviewHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2)
}));

const DateRangePreview = ({ startDate, endDate, selectedDays = {}, weekendsOnly = false, useSelectedDays = false, dailyEvents = true }) => {
    // console.info('startDate', startDate);
    // console.info('endDate', startDate);
    // console.info('selectedDays', startDate);

    const [calendarDays, setCalendarDays] = useState([]);
    const weekDays = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ];

    useEffect(() => {
        if (startDate && endDate) {
            const monthStart = startOfMonth(startDate);
            const monthEnd = endOfMonth(endDate);

            const weeks = eachWeekOfInterval(
                {
                    start: startOfWeek(monthStart, { weekStartsOn: 1 }),
                    end: endOfWeek(monthEnd, { weekStartsOn: 1 })
                },
                { weekStartsOn: 1 }
            );

            const days = weeks.map(week => {
                return eachDayOfInterval({
                    start: startOfWeek(week, { weekStartsOn: 1 }),
                    end: endOfWeek(week, { weekStartsOn: 1 })
                }).map(day => {
                    const isInRange = isWithinInterval(day, { start: startDate, end: endDate });
                    let isEnabled;

                    if (dailyEvents) {
                        isEnabled = true;
                    } else if (weekendsOnly) {
                        isEnabled = [0, 6].includes(day.getDay());
                    } else if (useSelectedDays) {
                        isEnabled = selectedDays[day.getDay()];
                    } else {
                        isEnabled = true;
                    }

                    return {
                        date: day,
                        isInRange: isInRange && isEnabled,
                        isCurrentMonth: isSameMonth(day, startDate) || isSameMonth(day, endDate),
                        isWeekend: [0, 6].includes(day.getDay())
                    };
                });
            });

            setCalendarDays(days);
        }
    }, [startDate, endDate, selectedDays, weekendsOnly, useSelectedDays, dailyEvents]);

    if (!startDate || !endDate) {
        return null;
    }

    const getSelectedDaysText = () => {
        if (dailyEvents) {
            return 'everyday';
        }
        if (weekendsOnly) {
            return 'weekends';
        }
        if (useSelectedDays) {
            return Object.entries(selectedDays)
                .filter(([_, isSelected]) => isSelected)
                .map(([day]) => weekDays[day])
                .join(', ');
        }
        return 'everyday';
    };

    return (
        <PreviewContainer>
            <PreviewHeader>
                <CalendarMonthIcon />
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                    Schedule Preview
                </Typography>
            </PreviewHeader>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                <Typography variant="body2" color="text.secondary">
                    Events will be scheduled on
                </Typography>
                <Chip
                    label={getSelectedDaysText()}
                    size="small"
                    variant="outlined"
                    sx={{
                        borderColor: 'orange',
                        color: 'orange',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 165, 0, 0.04)'
                        }
                    }}
                />
            </Box>

            <Paper
                elevation={0}
                sx={{
                    p: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    background: '#ffffff',
                }}
            >
                <Grid
                    container
                    sx={{
                        width: '100%',
                        '& > .MuiGrid-item': {
                            width: 'calc(100% / 7)',
                            flexGrow: 0,
                            maxWidth: 'calc(100% / 7)',
                            flexBasis: 'calc(100% / 7)',
                            padding: '2px'
                        }
                    }}
                >
                    {weekDays.map((day, i) => (
                        <Grid item key={i}>
                            <WeekdayHeader
                                sx={{
                                    height: '32px',
                                    fontSize: '16px',
                                    fontWeight: 800,
                                    color: 'text.secondary',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {day.charAt(0)}
                            </WeekdayHeader>
                        </Grid>
                    ))}
                    {calendarDays.flat().map((day, i) => (
                        <Grid item key={i}>
                            <DayCell
                                isCurrentMonth={day.isCurrentMonth}
                                isSelected={day.isInRange}
                                isWeekend={day.isWeekend}
                                sx={{
                                    aspectRatio: '1/1',
                                    height: '40px',
                                    fontSize: '14px',
                                    fontWeight: day.isInRange ? 600 : 400
                                }}
                            >
                                {format(day.date, 'd')}
                            </DayCell>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </PreviewContainer>
    );
};

const StartEndDate = ({
                          startDate,
                          endDate,
                          onStartDateChange,
                          onEndDateChange,
                          onDailyEventsChange,
                          isDailyEvents,
                          label = "Date Range",
                          showWeekendOption = true,
                          showOptionalDays = true,
                          startDateError,
                          endDateError,
                          onStartDateBlur,
                          onEndDateBlur
                      }) => {
    const [weekendsOnly, setWeekendsOnly] = useState(false);
    const [useSelectedDays, setUseSelectedDays] = useState(false);
    const [selectedDays, setSelectedDays] = useState({
        1: true,  // Monday
        2: true,  // Tuesday
        3: true,  // Wednesday
        4: true,  // Thursday
        5: true,  // Friday
        6: true,  // Saturday
        0: true,  // Sunday
    });

    const orderedDays = [
        { key: 1, label: 'Mon' },
        { key: 2, label: 'Tue' },
        { key: 3, label: 'Wed' },
        { key: 4, label: 'Thu' },
        { key: 5, label: 'Fri' },
        { key: 6, label: 'Sat' },
        { key: 0, label: 'Sun' },
    ];

    const handleDailyEventsToggle = (event) => {
        const isChecked = event.target.checked;
        onDailyEventsChange(isChecked);
        if (isChecked) {
            setWeekendsOnly(false);
            setUseSelectedDays(false);
        }
    };

    const handleWeekendToggle = (event) => {
        const isChecked = event.target.checked;
        setWeekendsOnly(isChecked);
        if (isChecked) {
            onDailyEventsChange(false);
            setUseSelectedDays(false);
        }
    };

    const handleSelectedDaysToggle = (event) => {
        const isChecked = event.target.checked;
        setUseSelectedDays(isChecked);
        if (isChecked) {
            onDailyEventsChange(false);
            setWeekendsOnly(false);
        }
    };

    const handleDayToggle = (day) => (event) => {
        setSelectedDays(prev => ({ ...prev, [day]: event.target.checked }));
    };

    const shouldDisableDate = (date) => {
        if (!date) return false;

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Disable past dates
        if (isBefore(date, today)) {
            return true;
        }

        if (isDailyEvents) {
            return false;
        }

        if (weekendsOnly) {
            return !isWeekend(date);
        }

        if (useSelectedDays) {
            return !selectedDays[date.getDay()];
        }

        return false;
    };

    const handleStartDateChange = (newDate) => {
        if (!shouldDisableDate(newDate)) {
            try {
                // Only format if we have a valid date
                const formattedDate = newDate && !isNaN(newDate) ? format(newDate, 'yyyy-MM-dd') : null;
                onStartDateChange(newDate); // Pass the actual Date object, not formatted string

                // If end date exists and is now invalid, clear it
                if (endDate && isAfter(newDate, endDate)) {
                    onEndDateChange(null);
                }
            } catch (error) {
                console.error('Invalid date:', error);
                onStartDateChange(null);
            }
        }
    };

    const handleEndDateChange = (newDate) => {
        if (!shouldDisableDate(newDate)) {
            try {
                // Check if the new end date is after start date
                if (startDate && isBefore(newDate, startDate)) {
                    return;
                }
                // Only format if we have a valid date
                const formattedDate = newDate && !isNaN(newDate) ? format(newDate, 'yyyy-MM-dd') : null;
                onEndDateChange(newDate); // Pass the actual Date object, not formatted string
            } catch (error) {
                console.error('Invalid date:', error);
                onEndDateChange(null);
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
            <TextFieldTitle>Start Date - End Date</TextFieldTitle>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingRight: { md: '50px', lg: '240px' }
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ display: 'flex', gap: '20px' }}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                onClose={onStartDateBlur}
                                format="dd/MM/yyyy"
                                shouldDisableDate={shouldDisableDate}
                                slotProps={{
                                    textField: {
                                        error: !!startDateError,
                                        helperText: startDateError,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                onClose={onEndDateBlur}
                                format="dd/MM/yyyy"
                                minDate={startDate || new Date()}
                                disabled={!startDate}
                                shouldDisableDate={(date) => {
                                    if (!startDate) return true;
                                    return shouldDisableDate(date) || isBefore(date, startDate);
                                }}
                                slotProps={{
                                    textField: {
                                        error: !!endDateError,
                                        helperText: endDateError,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <Box sx={{ flex: 2 }} />
                        </Box>
                    </LocalizationProvider>

                    {/* Keep the rest of your existing JSX for switches and options */}
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Boolean(isDailyEvents)}
                                    onChange={handleDailyEventsToggle}
                                    name="isDailyEvents"
                                />
                            }
                            label="Daily Events"
                        />

                        {showWeekendOption && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={weekendsOnly}
                                        onChange={handleWeekendToggle}
                                        name="weekendsOnly"
                                        disabled={isDailyEvents}
                                    />
                                }
                                label="Weekends Only"
                            />
                        )}

                        {showOptionalDays && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={useSelectedDays}
                                        onChange={handleSelectedDaysToggle}
                                        name="useSelectedDays"
                                        disabled={isDailyEvents}
                                    />
                                }
                                label="Select Specific Days"
                            />
                        )}
                    </Box>

                    {useSelectedDays && (
                        <Box>
                            <Typography sx={{ fontSize: '14px', fontWeight: 500, mb: 1 }}>
                                Select days to include
                            </Typography>
                            <FormGroup
                                row
                                sx={{
                                    gap: 2,
                                    '& .MuiFormControlLabel-root': {
                                        minWidth: '80px',
                                        margin: 0
                                    }
                                }}
                            >
                                {orderedDays.map(({ key, label }) => (
                                    <FormControlLabel
                                        key={label}
                                        control={
                                            <Checkbox
                                                checked={selectedDays[key]}
                                                onChange={handleDayToggle(key)}
                                                name={label}
                                                size="small"
                                            />
                                        }
                                        label={label}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    )}

                    <DateRangePreview
                        startDate={startDate ? subDays(startDate, 1) : startDate}
                        endDate={endDate}
                        selectedDays={selectedDays}
                        weekendsOnly={weekendsOnly}
                        useSelectedDays={useSelectedDays}
                        dailyEvents={isDailyEvents}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default StartEndDate;
