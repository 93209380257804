import { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Divider } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PermissionGate from 'components/shared/PermissionGate';

const AgentPayouts = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const fetchPayouts = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/agent-payouts?page=${paginationModel.page + 1}&limit=${
                    paginationModel.pageSize
                }`
            );
            const result = await response.json();

            if (result.code === 200) {
                const transformedData = result.data.payouts.map(payout => ({
                    id: payout.id,
                    agent_name: payout.agent.name,
                    agent_email: payout.agent.email,
                    period: payout.period,
                    total_earnings: `RM ${parseFloat(payout.total_earnings).toFixed(2)}`,
                    processing_date: new Date(payout.processing_date).toLocaleDateString(),
                    payout_date: payout.payout_date ? new Date(payout.payout_date).toLocaleDateString() : '-',
                    bank_account: payout.bank_account,
                    status: payout.status,
                    transaction_ref: payout.transaction_ref || 'Pending',
                }));

                setData(transformedData);
                setTotalCount(result.data.pagination.totalCount);
            }
        } catch (error) {
            console.error('Error fetching payouts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayouts();
    }, [paginationModel.page, paginationModel.pageSize]);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const columns = [
        {
            field: 'agent_name',
            headerName: 'Agent Name',
            flex: 1.2,
            minWidth: 150,
        },
        {
            field: 'agent_email',
            headerName: 'Agent Email',
            flex: 1.5,
            minWidth: 200,
        },
        {
            field: 'period',
            headerName: 'Period',
            flex: 1,
            minWidth: 130,
        },
        {
            field: 'total_earnings',
            headerName: 'Total Earnings',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'processing_date',
            headerName: 'Processing Date',
            flex: 1.2,
            minWidth: 160,
        },
        {
            field: 'payout_date',
            headerName: 'Payout Date',
            flex: 1.2,
            minWidth: 140,
        },
        {
            field: 'bank_account',
            headerName: 'Bank Account',
            flex: 1.3,
            minWidth: 150,
        },
        {
            field: 'transaction_ref',
            headerName: 'Transaction Ref',
            flex: 1.2,
            minWidth: 160,
        },
    ];

    return (
        <PermissionGate featureCode='AGEN4' fallback={null}>
            <Box sx={{ padding: { xs: 1, md: '32px' } }}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                            width: '100%',
                        }}
                    >
                        <Box>
                            <Typography variant='h4'>Agent Payouts</Typography>
                            <Typography variant='h5'>Track your earnings and payment status</Typography>
                        </Box>
                    </Box>

                    <Divider />

                    <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                        <Box />
                        <Box display='flex' gap='12px'>
                            <TextField
                                variant='outlined'
                                placeholder='Search payouts'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                size='small'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '34px',
                                        '& input': {
                                            height: '34px',
                                            padding: '0 14px',
                                        },
                                        '& .MuiInputBase-input': {
                                            height: '34px',
                                            padding: '0 14px',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Box>

                    <Paper
                        sx={{
                            width: '100%',
                            borderRadius: '16px',
                            marginTop: '24px',
                            border: '1px solid var(--Gray-200, #EAECF0)',
                            background: 'var(--White, #FFF)',
                            boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Gray-900, #101828)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '28px',
                                padding: '20px 24px',
                            }}
                        >
                            Payout Details
                        </Typography>

                        <Divider sx={{ my: 0 }} />

                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={data}
                            columns={columns}
                            rowCount={totalCount}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            paginationMode='server'
                            loading={loading}
                            checkboxSelection
                        />
                    </Paper>
                </Box>
            </Box>
        </PermissionGate>
    );
};

export default AgentPayouts;
