import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import Verified from './AdminAccountStatusContents/Verified';
import Warning from './AdminAccountStatusContents/Warning';
import Incomplete from './AdminAccountStatusContents/Incomplete';
import Expired from './AdminAccountStatusContents/Expired';
import Billing from './AdminAccountStatusContents/Billing';
import Subscriptions from './AdminAccountStatusContents/Subscriptions';
import PermissionGate from 'components/shared/PermissionGate';

const AdminAccountStatus = () => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#billing') {
            setValue(4);
        } else if (hash === '#subscriptions') {
            setValue(5);
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderTabContent = index => {
        switch (index) {
            case 0:
                return (
                    <PermissionGate featureCode='ACCT_READ' fallback={null}>
                        <Verified />
                    </PermissionGate>
                );
            case 1:
                return (
                    <PermissionGate featureCode='ACCT_READ1' fallback={null}>
                        <Warning />
                    </PermissionGate>
                );
            case 2:
                return (
                    <PermissionGate featureCode='ACCT_READ2' fallback={null}>
                        <Incomplete />
                    </PermissionGate>
                );
            case 3:
                return (
                    <PermissionGate featureCode='ACCT_READ3' fallback={null}>
                        <Expired />
                    </PermissionGate>
                );
            case 4:
                return (
                    // <PermissionGate featureCode='ACCT_READ4' fallback={null}>
                        <Billing />
                    // </PermissionGate>
                );
            case 5:
                return (
                    // <PermissionGate featureCode='ACCT_READ4' fallback={null}>
                        <Subscriptions />
                    // </PermissionGate>
                );
            default:
                return null;
        }
    };

    return (
        <Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Account Status</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            Overview of Your Current Account Activity
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        <PermissionGate featureCode='ACCT_READ' fallback={null}>
                            <Tab label='Verified' />
                        </PermissionGate>
                        <PermissionGate featureCode='ACCT_READ1' fallback={null}>
                            <Tab label='Warning' />
                        </PermissionGate>
                        <PermissionGate featureCode='ACCT_READ2' fallback={null}>
                            <Tab label='Incomplete' />
                        </PermissionGate>
                        <PermissionGate featureCode='ACCT_READ3' fallback={null}>
                            <Tab label='Expired' />
                        </PermissionGate>
                        {/*<PermissionGate featureCode='ACCT_READ4' fallback={null}>*/}
                            <Tab label='Billing' />
                            <Tab label='Subscriptions' />
                        {/*</PermissionGate>*/}
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '10px 0 10px 0' }} />

                <Box>{renderTabContent(value)}</Box>
            </Box>
        </Box>
    );
};

export default AdminAccountStatus;
