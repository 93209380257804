import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
    Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import keycloak from '../../../keycloak';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PurchaseDetailsModal = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [purchaseDetails, setPurchaseDetails] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const calculateUsageTier = (totalPurchases = 0) => {
        if (totalPurchases <= 50) {
            return { tier: "Standard", additionalFee: 0 };
        }
        return { 
            tier: "Premium", 
            additionalFee: totalPurchases * 0.03, // 3% additional fee for purchases above 50
            description: "3% additional fee for purchases above 50" 
        };
    };

    useEffect(() => {
        if (open) {
            const fetchPurchaseDetails = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchases?id=${id}`, {
                        headers: {
                            Authorization: `Bearer ${keycloak.token}`
                        }
                    });

                    if (response.data.code === 200) {
                        setPurchaseDetails(response.data.data.purchase);
                    }
                } catch (error) {
                    console.error("Error fetching purchase details:", error);
                }
            };

            fetchPurchaseDetails();
        }
    }, [open, id]);

    const getCustomerName = (details) => {
        if (!details?.customer) return 'N/A';
        const { first_name = '', last_name = '' } = details.customer;
        return `${first_name} ${last_name}`.trim() || 'N/A';
    };

    const getAgentName = (details) => {
        if (!details?.agent) return 'N/A';
        const { first_name = '', last_name = '' } = details.agent;
        return `${first_name} ${last_name}`.trim() || 'N/A';
    };

    return (
        <Box>
            <Tooltip title="View purchase details">
                <IconButton onClick={handleClickOpen} size="small">
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Purchase Details"}
                </DialogTitle>
                <DialogContent>
                    {purchaseDetails ? (
                        <Box sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                {/* Basic Information */}
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Transaction Summary</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Invoice Number</Typography>
                                    <Typography variant="body1">{purchaseDetails.invoice_number || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Purchase Date</Typography>
                                    <Typography variant="body1">
                                        {purchaseDetails.created_at ? new Date(purchaseDetails.created_at).toLocaleDateString() : 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Customer Name</Typography>
                                    <Typography variant="body1">{getCustomerName(purchaseDetails)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Agent Name</Typography>
                                    <Typography variant="body1">{getAgentName(purchaseDetails)}</Typography>
                                </Grid>

                                {/* Price Breakdown Section */}
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                    <Typography variant="h6" gutterBottom>Price Breakdown</Typography>
                                </Grid>
                                
                                {/* Subtotal */}
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Subtotal</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        RM{(Number(purchaseDetails.total_amount || 0)).toFixed(2)}
                                    </Typography>
                                </Grid>

                                {/* Agent Commission */}
                                {Number(purchaseDetails.agent_commission_rate) > 0 && (
                                    <>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.secondary">
                                                Agent Commission ({purchaseDetails.agent_commission_rate}%)
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="error.main">
                                                -RM{(Number(purchaseDetails.total_amount || 0) * Number(purchaseDetails.agent_commission_rate || 0) / 100).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}

                                {/* Voucher */}
                                {Number(purchaseDetails.voucher_amount) > 0 && (
                                    <>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.secondary">
                                                Voucher Applied
                                                {purchaseDetails.voucher_code && ` (${purchaseDetails.voucher_code})`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1" color="error.main">
                                                -RM{(Number(purchaseDetails.voucher_amount || 0)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}

                                {/* Coupon */}
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Coupon
                                        {purchaseDetails.coupon_code && ` (${purchaseDetails.coupon_code})`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {Number(purchaseDetails.coupon_amount) > 0 ? (
                                        <Typography variant="body1" color="error.main">
                                            -RM{(Number(purchaseDetails.coupon_amount || 0)).toFixed(2)}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" color="text.secondary">
                                            Not Applied
                                        </Typography>
                                    )}
                                </Grid>

                                {/* Usage Tier */}
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Usage Tier
                                        <Tooltip title={calculateUsageTier(Number(purchaseDetails.total_purchases || 0)).description || "No additional fees"}>
                                            <IconButton size="small">
                                                <HelpOutlineIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography variant="body2">
                                            {calculateUsageTier(Number(purchaseDetails.total_purchases || 0)).tier}
                                        </Typography>
                                        {calculateUsageTier(Number(purchaseDetails.total_purchases || 0)).additionalFee > 0 && (
                                            <Typography variant="body1" color="error.main">
                                                +RM{calculateUsageTier(Number(purchaseDetails.total_purchases || 0)).additionalFee.toFixed(2)}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                {/* Total Net Sales */}
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" fontWeight="bold">Net Sales</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        RM{(
                                            Number(purchaseDetails.total_amount || 0) - 
                                            (Number(purchaseDetails.total_amount || 0) * Number(purchaseDetails.agent_commission_rate || 0) / 100) -
                                            Number(purchaseDetails.voucher_amount || 0) -
                                            Number(purchaseDetails.coupon_amount || 0) +
                                            calculateUsageTier(Number(purchaseDetails.total_purchases || 0)).additionalFee
                                        ).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <Typography>Loading purchase details...</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="cancel">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PurchaseDetailsModal;