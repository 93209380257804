import keycloak from "../keycloak";

export const getToken = async () => {
    try {
        // Check if token needs refreshing
        if (keycloak.isTokenExpired()) {
            await keycloak.updateToken(30);
        }
        
        if (!keycloak.token) {
            throw new Error('No authentication token available');
        }
        
        return keycloak.token;
    } catch (error) {
        console.error('Error getting token:', error);
        // Redirect to login if token cannot be obtained
        keycloak.login();
        throw new Error('Failed to get authentication token');
    }
};
