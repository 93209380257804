import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Tabs, Tab, Divider, Typography, CircularProgress } from '@mui/material';
import { useKeycloak } from '../../../contexts/KeycloakContext';
import UserProfileSettings from './ProfileSettingsContents/UserProfileSettings';
import BusinessDetail from './ProfileSettingsContents/BusinessDetails';
import PublicData from './ProfileSettingsContents/PublicData';
import PermissionGate from 'components/shared/PermissionGate';
import { useOrganizationRoles } from 'components/shared/useOrganizationRoles';

const ProfileSettings = () => {
    const [value, setValue] = useState(0);
    const [organizationRoles, setOrganizationRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { keycloak } = useKeycloak();
    const tabsContainerRef = useRef(null);
    const isOrgAdmin = useOrganizationRoles().includes('org_admin');

    const tabs = [
        { label: 'User', component: UserProfileSettings, featureCode: 'PROF_READ' },
        { label: 'Business Details', component: BusinessDetail, featureCode: 'PROF_READ1' },
        { label: 'Public Details', component: PublicData, featureCode: 'PROF_READ2' }
    ];

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#public-details') {
            setValue(2);
        }
        
        if (keycloak?.tokenParsed?.organization_roles) {
            setOrganizationRoles(keycloak.tokenParsed.organization_roles);
        }
    }, [keycloak]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('add-appointments');
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Profile Settings</Typography>
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 400,
                                color: '#888',
                            }}
                        >
                            Manages user information, business public details.
                        </Typography>
                        {organizationRoles.length > 0 && (
                            <Typography
                                sx={{
                                    fontSize: '0.875rem',
                                    fontWeight: 500,
                                    color: '#666',
                                    mt: 1
                                }}
                            >
                                Current Organization Roles: {organizationRoles.join(', ')}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>{/* Action buttons if needed */}</Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        {tabs.map((tab, index) => (
                            isOrgAdmin ? (
                                <Tab key={index} label={tab.label} />
                            ) : (
                                <PermissionGate key={index} featureCode={tab.featureCode} fallback={null}>
                                    <Tab label={tab.label} />
                                </PermissionGate>
                            )
                        ))}
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {isOrgAdmin ? (
                        tabs.map((tab, index) => (
                            value === index && <tab.component key={index} />
                        ))
                    ) : (
                        tabs.map((tab, index) => (
                            value === index && (
                                <PermissionGate
                                    key={index}
                                    featureCode={tab.featureCode}
                                    fallback={null}
                                >
                                    <tab.component />
                                </PermissionGate>
                            )
                        ))
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileSettings;
