import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

export const getOrganizationDomain = async (organizationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/organization-domains`, {
            params: {
                organization_id: organizationId,
                type: 'Subdomain'
            },
            headers: {
                Authorization: `Bearer ${await authService.ensureValidToken()}`
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching organization domain:', error);
        throw error;
    }
};
