import React, { useEffect, useState } from 'react';
import HtmlContentRenderer from '../../shared/HtmlContentRenderer';
import { Button, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import PermissionGate from 'components/shared/PermissionGate';

const AgentMaterials = () => {
    const [materials, setMaterials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetchMaterials();
    }, []);

    const fetchMaterials = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_API_URL}/agent-materials`);

            if (response.data.data.materials && response.data.data.materials.length > 0) {
                const materialsWithMedia = await Promise.all(
                    response.data.data.materials.map(async material => {
                        const documentLinksResponse = await axios.get(`${BASE_API_URL}/agent-document-links`, {
                            params: { material_id: material.id },
                        });
                        const trainingVideosResponse = await axios.get(`${BASE_API_URL}/agent-training-videos`, {
                            params: { material_id: material.id },
                        });

                        return {
                            ...material,
                            documents: documentLinksResponse.data.data.documentLinks || [],
                            videos: trainingVideosResponse.data.data.trainingVideos || [],
                        };
                    })
                );

                setMaterials(materialsWithMedia);
            }
        } catch (error) {
            console.error('Error fetching materials:', error);
            setError('Failed to load materials. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color='error'>{error}</Typography>;
    }

    const renderDocumentLinks = documents => (
        <div style={{ marginTop: '15px' }}>
            <h3 style={{ fontSize: '1rem', color: '#333', marginBottom: '10px' }}>Documents:</h3>
            <ol style={{ paddingLeft: '0', margin: 0, listStylePosition: 'inside' }}>
                {documents.map((doc, index) => (
                    <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px', minWidth: '25px' }}>{index + 1}.</span>
                        <div style={{ flex: 1, marginRight: '10px' }}>
                            <span style={{ color: '#333' }}>{doc.document_name}</span>
                        </div>
                        <Button
                            variant='cancel'
                            color='secondary'
                            href={doc.document_url}
                            target='_blank'
                            rel='noopener noreferrer'
                            size='small'
                        >
                            View Document
                        </Button>
                    </li>
                ))}
            </ol>
        </div>
    );

    const renderVideoLinks = videos => (
        <div style={{ marginTop: '15px' }}>
            <h3 style={{ fontSize: '1rem', color: '#333', marginBottom: '10px' }}>Training Videos:</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -10px' }}>
                {videos.map((video, index) => (
                    <div
                        key={index}
                        style={{ width: '50%', padding: '0 10px', marginBottom: '20px', boxSizing: 'border-box' }}
                    >
                        <div style={{ marginBottom: '10px' }}>
                            <input
                                type='text'
                                value={video.video_url || ''}
                                readOnly
                                style={{
                                    width: '100%',
                                    padding: '5px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                }}
                            />
                        </div>
                        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                            <iframe
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                src={video.video_url}
                                title={video.video_title || `Training Video ${index + 1}`}
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <PermissionGate featureCode='AGEN5' fallback={null}>
            <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: '20px' }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    {loading && (
                        <div style={{ textAlign: 'center', padding: '50px', fontSize: '1.2rem', color: '#666' }}>
                            Loading materials...
                        </div>
                    )}

                    {error && (
                        <div
                            style={{
                                backgroundColor: '#fee',
                                color: '#c00',
                                padding: '15px',
                                borderRadius: '4px',
                                marginBottom: '20px',
                            }}
                        >
                            Error loading materials: {error}
                        </div>
                    )}

                    {!loading && !error && materials.length === 0 && (
                        <div style={{ textAlign: 'center', color: '#666', padding: '30px' }}>No materials found.</div>
                    )}

                    {!loading &&
                        !error &&
                        materials.map(material => (
                            <div
                                key={material.id}
                                style={{
                                    background: 'white',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    marginBottom: '20px',
                                    padding: '20px',
                                }}
                            >
                                <h2 style={{ fontSize: '1.25rem', color: '#1a1a1a', marginBottom: '15px' }}>
                                    {material.title}
                                </h2>

                                <p style={{ color: '#444', marginBottom: '15px' }}>{material.description}</p>

                                <div style={{ marginBottom: '15px' }}>
                                    <HtmlContentRenderer
                                        content={material.content}
                                        containerStyles={{ maxWidth: '100%' }}
                                    />
                                </div>

                                {material.documents &&
                                    material.documents.length > 0 &&
                                    renderDocumentLinks(material.documents)}
                                {material.videos && material.videos.length > 0 && renderVideoLinks(material.videos)}

                                <div style={{ color: '#666', fontSize: '0.875rem', marginTop: '15px' }}>
                                    Created at: {new Date(material.created_at).toLocaleDateString()}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </PermissionGate>
    );

    // return (
    //     <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: '20px' }}>
    //         <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
    //             {loading && (
    //                 <div style={{ textAlign: 'center', padding: '50px', fontSize: '1.2rem', color: '#666' }}>
    //                     Loading materials...
    //                 </div>
    //             )}
    //
    //             {error && (
    //                 <div style={{ backgroundColor: '#fee', color: '#c00', padding: '15px', borderRadius: '4px', marginBottom: '20px' }}>
    //                     Error loading materials: {error}
    //                 </div>
    //             )}
    //
    //             {!loading && !error && materials.length === 0 && (
    //                 <div style={{ textAlign: 'center', color: '#666', padding: '30px' }}>
    //                     No materials found.
    //                 </div>
    //             )}
    //
    //             {!loading && !error && materials.map((material) => (
    //                 <div key={material.id} style={{ background: 'white', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginBottom: '20px', padding: '20px' }}>
    //                     <h2 style={{ fontSize: '1.25rem', color: '#1a1a1a', marginBottom: '15px' }}>
    //                         {material.title}
    //                     </h2>
    //
    //                     <p style={{ color: '#444', marginBottom: '15px' }}>{material.description}</p>
    //
    //                     <div style={{ marginBottom: '15px' }}>
    //                         <HtmlContentRenderer content={material.content} containerStyles={{ maxWidth: '100%' }} />
    //                     </div>
    //
    //                     {material.documents && material.documents.length > 0 && renderDocumentLinks(material.documents)}
    //                     {material.videos && material.videos.length > 0 && renderVideoLinks(material.videos)}
    //
    //                     <div style={{ color: '#666', fontSize: '0.875rem', marginTop: '15px' }}>
    //                         Created at: {new Date(material.created_at).toLocaleDateString()}
    //                     </div>
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );
};

export default AgentMaterials;
