import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Divider,
    Paper,
    CircularProgress
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import keycloak from "../../../../keycloak";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

// Reusable styled components
const RoleSectionHeader = ({ title }) => (
    <Box sx={{
        bgcolor: '#f5f5f5',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        px: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
    }}>
        <Typography sx={{ fontSize: '13px', fontWeight: '600', color: 'text.primary' }}>
            {title}
        </Typography>
    </Box>
);

const RoleItem = ({ item, isLast, selected, onSelect }) => (
    <>
        <FormControlLabel
            sx={{
                m: 0,
                p: 1.5,
                width: '100%',
                '&:hover': { bgcolor: '#f8f9fa' }
            }}
            control={
                <Checkbox 
                    checked={selected} 
                    onChange={(e) => onSelect(item, e.target.checked)} 
                    size="small" 
                />
            }
            label={
                <Box sx={{ ml: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: '500' }}>{item.name}</Typography>
                    {item.description && (
                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                            {item.description}
                        </Typography>
                    )}
                </Box>
            }
        />
        {!isLast && <Divider sx={{m:0}} />}
    </>
);

const RoleSection = ({ role, selectedRoles, onSelectRole }) => (
    <Paper variant="outlined" sx={{ mb: 2, borderRadius: 1 }}>
        <RoleSectionHeader title={role.title} />
        <Box sx={{ px: 0 }}>
            {role.items.map((item, index) => (
                <RoleItem
                    key={item.id}
                    item={item}
                    isLast={index === role.items.length - 1}
                    selected={selectedRoles.some(r => r.id === item.id)}
                    onSelect={onSelectRole}
                />
            ))}
        </Box>
    </Paper>
);

const AddMemberModal = ({ open, onClose, onSubmit }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [email, setEmail] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const fetchRoles = async () => {
        try {
            const organizationId = keycloak.tokenParsed?.organization_id;
            if (!organizationId) {
                throw new Error('Organization ID not found');
            }

            const response = await axios.get(`${BASE_API_URL}/roles`, {
                params: { organization_id: organizationId }
            });

            // Group roles by category
            const groupedRoles = response.data.data.roles.reduce((acc, role) => {
                const category = role.category || 'Roles';
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({
                    ...role,
                    description: role.description || `${role.name} role`
                });
                return acc;
            }, {});

            // Convert to array format
            const formattedRoles = Object.entries(groupedRoles).map(([title, items]) => ({
                title,
                items
            }));

            setRoles(formattedRoles);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setError('Failed to load roles. Please try again.');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchRoles();
        }
    }, [open]);

    const handleSelectRole = (role, isSelected) => {
        if (isSelected) {
            setSelectedRoles([...selectedRoles, role]);
        } else {
            setSelectedRoles(selectedRoles.filter(r => r.id !== role.id));
        }
    };

    const handleSubmit = () => {
        if (!email) {
            setError('Please enter an email address');
            return;
        }
        if (selectedRoles.length === 0) {
            setError('Please select at least one role');
            return;
        }

        onSubmit({
            email: email,
            roleIds: selectedRoles.map(role => role.id)
        });
        
        // Reset form
        setEmail('');
        setSelectedRoles([]);
        setError(null);
        onClose();
    };

    const filteredRoles = searchQuery
        ? roles.map(section => ({
            ...section,
            items: section.items.filter(item => 
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.description.toLowerCase().includes(searchQuery.toLowerCase())
            )
        })).filter(section => section.items.length > 0)
        : roles;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    maxHeight: '90vh'
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Add New Team Member
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ pt: 2 }}>
                    {error && (
                        <Typography color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {/* Email Input Section */}
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <FormLabel sx={{ fontSize: '14px', fontWeight: '600', mb: 1, color: 'text.primary' }}>
                            Enter team member email address
                        </FormLabel>
                        <TextField
                            variant="outlined"
                            placeholder="ada@eventlah.com"
                            size="small"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={Boolean(error && !email)}
                        />
                    </FormControl>

                    {/* Roles Section */}
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontSize: '14px', fontWeight: '600', mb: 1, color: 'text.primary' }}>
                            Select team member roles
                        </FormLabel>

                        {/* Search Field */}
                        <TextField
                            variant="outlined"
                            placeholder="Search by role..."
                            size="small"
                            fullWidth
                            sx={{ mb: 2 }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : (
                            filteredRoles.map((role) => (
                                <RoleSection
                                    key={role.title}
                                    role={role}
                                    selectedRoles={selectedRoles}
                                    onSelectRole={handleSelectRole}
                                />
                            ))
                        )}
                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2.5, pt: 1.5 }}>
                <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading || !email || selectedRoles.length === 0}
                >
                    Add Member
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddMemberModal;
