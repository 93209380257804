import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    try {
        const token = await authService.ensureValidToken();
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    } catch (error) {
        console.error('Error getting auth token:', error);
        throw new Error('Failed to get authentication token');
    }
};

export const fetchOrganizationProfiles = createAsyncThunk(
    'organizationProfiles/fetchOrganizationProfiles',
    async (organizationId, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/organizations?id=${organizationId}`, { headers });
            // console.log('updateOrgData', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchOnboardingProfile = createAsyncThunk(
    'organizationProfiles/fetchOnboardingProfile',
    async (email, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();

            const response = await axios.post(
                `${API_BASE_URL}/onboarding-status`,
                { email }, // Send email in the request body
                { headers }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const saveOrganizationProfile = createAsyncThunk(
    'organizationProfiles/saveOrganizationProfile',
    async (profile, { rejectWithValue }) => {
        console.log('saveOrganizationProfile', profile);
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/organizations`, profile, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateOrganizationProfile = createAsyncThunk(
    'organizationProfiles/updateOrganizationProfile',
    async ({ id, profile }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            // console.log('Payload data:', profile);
            const response = await axios.patch(`${API_BASE_URL}/organizations?id=${id}`, profile, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const organizationProfilesSlice = createSlice({
    name: 'organizationProfiles',
    initialState: {
        profiles: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationProfiles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationProfiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profiles = [action.payload];
            })
            .addCase(fetchOrganizationProfiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organization profiles';
            })
            .addCase(saveOrganizationProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveOrganizationProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profiles.push(action.payload);
            })
            .addCase(saveOrganizationProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to save organization profile';
            })
            .addCase(updateOrganizationProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOrganizationProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (state.profiles.length > 0) {
                    state.profiles[0] = {
                        ...state.profiles[0],
                        ...action.payload
                    };
                } else {
                    state.profiles.push(action.payload);
                }
            })
            .addCase(updateOrganizationProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update organization profile';
            });
    },
});

export default organizationProfilesSlice.reducer;
