import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    TextField,
    Switch,
    FormControlLabel,
    Divider,
    CircularProgress,
    Alert,
    Stack,
    Button,
    Grid,
    MenuItem,
} from '@mui/material';
import axios from '../../../utils/axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminPaymentSettings = () => {
    // General Settings
    const [settings, setSettings] = useState({
        cardChangeCoolingPeriod: '',
        paymentGracePeriod: '',
        maxFailedAttempts: '',
        autoSuspendOnFailure: false,
        
        // Payment Gateway Settings
        enabled: false,
        title: '',
        description: '',
        merchantId: '',
        secretKey: '',
        hashType: 'md5',
        environmentMode: 'Live'
    });

    // UI States
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/admin-payment-settings`, {
                params: { organization_id: 1 }
            });
            setSettings(prevSettings => ({
                ...prevSettings,
                ...response.data.data
            }));
        } catch (error) {
            setError('Failed to fetch payment settings. Please try again.');
            console.error('Error fetching settings:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field) => (event) => {
        setSettings(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    const handleSwitchChange = (field) => (event) => {
        setSettings(prev => ({
            ...prev,
            [field]: event.target.checked
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);
            setSuccess(false);

            await axios.put(`${API_BASE_URL}/admin-payment-settings`, {
                ...settings,
                organization_id: 1
            });
            setSuccess(true);
        } catch (error) {
            setError('Failed to update payment settings. Please try again.');
            console.error('Error updating settings:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>Payment Settings</Typography>
            
            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
            )}
            
            {success && (
                <Alert severity="success" sx={{ mb: 3 }}>Settings updated successfully!</Alert>
            )}

            <Paper sx={{ p: 3 }}>
                <Stack spacing={3}>
                    <Typography variant="h6">Cooling Periods</Typography>
                    
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Card Change Cooling Period (hours)"
                            type="number"
                            value={settings.cardChangeCoolingPeriod}
                            onChange={handleChange('cardChangeCoolingPeriod')}
                            fullWidth
                            helperText="Time required between card changes"
                        />
                        <TextField
                            label="Payment Grace Period (hours)"
                            type="number"
                            value={settings.paymentGracePeriod}
                            onChange={handleChange('paymentGracePeriod')}
                            fullWidth
                            helperText="Grace period after failed payment"
                        />
                    </Box>

                    <Divider />
                    
                    <Typography variant="h6">Failed Payment Handling</Typography>
                    
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Max Failed Payment Attempts"
                            type="number"
                            value={settings.maxFailedAttempts}
                            onChange={handleChange('maxFailedAttempts')}
                            fullWidth
                            helperText="Maximum number of failed payment attempts before action"
                        />
                    </Box>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={settings.autoSuspendOnFailure}
                                onChange={handleSwitchChange('autoSuspendOnFailure')}
                            />
                        }
                        label="Automatically suspend account after max failed attempts"
                    />

                    <Divider />
                    
                    <Typography variant="h6">Payment Gateway Configuration</Typography>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Enable / Disable</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Switch
                                size="small"
                                checked={settings.enabled}
                                onChange={handleSwitchChange('enabled')}
                            />
                            <Typography variant="body2" component="span" sx={{ml: 1}}>
                                Enable Senangpay
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Title</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                size="small"
                                value={settings.title}
                                onChange={handleChange('title')}
                            />
                        </Grid>

                        <Grid sx={{ textAlign: 'start', alignSelf: 'flex-start' }} item xs={4}>
                            <Typography>Description</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                size="small"
                                value={settings.description}
                                onChange={handleChange('description')}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Merchant ID</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                size="small"
                                value={settings.merchantId}
                                onChange={handleChange('merchantId')}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Secret Key</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                size="small"
                                type="password"
                                value={settings.secretKey}
                                onChange={handleChange('secretKey')}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Hash Type</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                value={settings.hashType}
                                onChange={handleChange('hashType')}
                            >
                                <MenuItem value="md5">MD5</MenuItem>
                                <MenuItem value="sha256">SHA256</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography sx={{ textAlign: 'start' }}>Environment Mode</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                value={settings.environmentMode}
                                onChange={handleChange('environmentMode')}
                            >
                                <MenuItem value="Live">Live</MenuItem>
                                <MenuItem value="Test">Test</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                        <Button variant="outlined" onClick={() => window.history.back()}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading}
                            sx={{ color: '#FFF' }}
                        >
                            Save Settings
                        </Button>
                    </Box>
                </Stack>
            </Paper>
        </Box>
    );
};

export default AdminPaymentSettings;
