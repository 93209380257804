import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationProfiles, updateOrganizationProfile, saveOrganizationProfile } from '../../../redux/organizationProfilesSlice';
import {
    Box, Typography, CircularProgress, TextField, Button, Snackbar, Alert,
    Divider, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { styled } from "@mui/system";
import EditNoteIcon from '@mui/icons-material/EditNote';
import keycloak from "../../../keycloak";
import axios from "axios";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const CompanyProfile = ({ requiredFields = [], onFormSubmitSuccess }) => {
    const dispatch = useDispatch();
    const { profiles, status, error } = useSelector((state) => state.organizationProfiles);
    const [isEditing, setIsEditing] = useState(false);
    const [organizationData, setOrganizationData] = useState({
        id: '',
        code: '',
        name: '',
        description: '',
        registration_number: '',
        company_address_1: '',
        company_address_2: '',
        company_postcode: '',
        company_city: '',
        company_state: '',
        company_country: '',
        storeName: '',
        contactNumber: '',
        emailAddress: '',
        operatingHours: '',
        operatingDays: '',
        storeDescription: '',
        storeUrl: ''
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (status === 'idle') {
            const token = keycloak.tokenParsed;
            let organization = token?.organization;
            if (organization) {
                dispatch(fetchOrganizationProfiles(organization))
                    .unwrap()
                    .then((data) => console.log('Fetched organization profiles:', data))
                    .catch((error) => console.error('Failed to fetch organization profiles:', error));
            }
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (profiles && profiles.length > 0) {
            setOrganizationData(profiles[0]?.data || {});
        }
    }, [profiles]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrganizationData(prev => ({ ...prev, [name]: value }));
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (Object.keys(organizationData).length === 0) {
                throw new Error('Organization data is required');
            }

            const apiData = organizationData;

            let result;
            if (organizationData.id) {
                result = await dispatch(updateOrganizationProfile({ id: organizationData.id, profile: apiData })).unwrap();
            } else {
                if (apiData.id === '') {
                    delete apiData.id;
                }
                apiData.code = apiData.registration_number;
                result = await dispatch(saveOrganizationProfile(apiData)).unwrap();
                let response =  result;

                /* update keycloak data */
                const token = keycloak.tokenParsed;
                let userEmail = token.email;
                let userId = await getUserIdByEmail(userEmail);
                const userAttributes = {
                    organization: [response.data.id.toString()]
                };
                await updateUserAttribute(userId, userAttributes);
            }

            console.log('API response:', result);

            if (result.data) {
                setOrganizationData(result.data);
            }

            setIsEditing(false);
            setSnackbar({ open: true, message: 'Organization profile updated successfully', severity: 'success' });

            if (onFormSubmitSuccess) {
                onFormSubmitSuccess();
            }
        } catch (error) {
            console.error('Failed to update organization profile:', error);
            setSnackbar({ open: true, message: 'Failed to update organization profile', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (profiles && profiles.length > 0) {
            setOrganizationData(profiles[0]?.data || {});
        }
    };

    async function getUserIdByEmail(email) {
        try {
            const adminToken = await getAdminToken();
            const keycloakUrl = process.env.KEYCLOAK_URL || 'https://accounts.eventlah.com';
            // ${keycloakUrl}
            const searchEndpoint = `${keycloakUrl}/admin/realms/eventlah/users?email=${encodeURIComponent(email)}`;

            const response = await axios.get(searchEndpoint, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });

            if (response.data && response.data.length > 0) {
                return response.data[0].id;
            } else {
                throw new Error('User not found');
            }
        } catch (error) {
            console.error('Error fetching user ID by email:', error);
            throw error;
        }
    }

    async function getUserAttributes(userId) {
        try {
            const adminToken = await getAdminToken();
            const keycloakUrl = process.env.KEYCLOAK_URL || 'https://accounts.eventlah.com';
            const userEndpoint = `${keycloakUrl}/admin/realms/eventlah/users/${userId}`;

            const response = await axios.get(userEndpoint, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });

            return response.data.attributes || {};
        } catch (error) {
            console.error('Error fetching user attributes:', error);
            throw error;
        }
    }

    async function updateUserAttribute(userId, newAttributes) {
        try {
            const adminToken = await getAdminToken();
            const keycloakUrl = process.env.KEYCLOAK_URL || 'https://accounts.eventlah.com';
            const updateEndpoint = `${keycloakUrl}/admin/realms/eventlah/users/${userId}`;

            // Fetch current attributes
            const currentAttributes = await getUserAttributes(userId);

            // Merge current attributes with new attributes
            const mergedAttributes = {
                ...currentAttributes,
                ...newAttributes,
                // Ensure new attributes overwrite old ones if keys are the same
                ...Object.fromEntries(
                    Object.entries(newAttributes).map(([key, value]) => [
                        key,
                        Array.isArray(value) ? value : [value.toString()]
                    ])
                )
            };

            const response = await axios.put(updateEndpoint,
                { attributes: mergedAttributes },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('User attributes updated successfully');
            return response.data;
        } catch (error) {
            console.error('Error updating user attributes:', error);
            throw error;
        }
    }

    async function getAdminToken() {
        const keycloakUrl = process.env.KEYCLOAK_URL || 'https://accounts.eventlah.com';
        const tokenEndpoint = `${keycloakUrl}/realms/eventlah/protocol/openid-connect/token`;

        const data = ({
            grant_type: 'client_credentials',
            client_id: 'ui-merchants-clients',
            client_secret: 'CnmzCRKFlO1vsaCCLR7iWjjxwVK3HTYw'
        });

        try {
            const response = await axios.post(tokenEndpoint, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.data.access_token;
        } catch (error) {
            console.error('Error getting admin token:', error);
            throw error;
        }
    }



    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (status === 'failed') {
        return <Typography variant="body2" color="error">Error: {error}</Typography>;
    }

    if (!organizationData) {
        return <Typography variant="body2">No organization data available.</Typography>;
    }

    const malaysiaStates = [
        "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
        "Pahang", "Penang", "Perak", "Perlis", "Sabah",
        "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
    ];

    const countries = ["Malaysia"];

    const renderField = (label, field, required = false, options = null) => (
        <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle variant="subtitle2">
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {isEditing ? (
                    options ? (
                        <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                            <InputLabel id={`${field}-select-label`}>Select {label}</InputLabel>
                            <Select
                                labelId={`${field}-select-label`}
                                id={`${field}-select`}
                                name={field}
                                value={organizationData[field] || ''}
                                onChange={handleInputChange}
                                required={requiredFields.includes(field)}
                                label={`Select ${label}`}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Typography variant="body2">{option}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            name={field}
                            value={organizationData[field] || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label={`Enter ${label}`}
                            required={requiredFields.includes(field)}
                            sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        />
                    )
                ) : (
                    <Typography variant="body1">{organizationData[field] || 'N/A'}</Typography>
                )}
            </Box>
            <Divider />
        </>
    );

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
            <Box sx={{display:'flex', justifyContent: 'space-between', marginBottom: 2}}>
                <Typography variant="h5">
                    Business Details
                </Typography>
                {isEditing ? (
                    <Box>
                        <Button variant='cancel' onClick={handleCancel} sx={{height:'32px', marginRight: 1}}>
                            <Typography variant="button">Cancel</Typography>
                        </Button>
                        <Button variant='save' type="submit" sx={{height:'32px'}} disabled={loading}>
                            <Typography variant="button">{loading ? 'Saving...' : 'Save'}</Typography>
                        </Button>
                    </Box>
                ) : (
                    <Button variant='cancel' onClick={handleEditClick} sx={{height:'32px'}}>
                        <EditNoteIcon/> <Typography variant="button">Edit</Typography>
                    </Button>
                )}
            </Box>
            {renderField('Company Name', 'name', true)}
            {renderField('Registration No.', 'registration_number', true)}
            {renderField('Company Address Line 1', 'company_address_1', true)}
            {renderField('Company Address Line 2', 'company_address_2')}
            {renderField('Postcode', 'company_postcode', true)}
            {renderField('City', 'company_city')}
            {renderField('State', 'company_state', true, malaysiaStates)}
            {renderField('Country', 'company_country', true, countries)}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <Typography variant="body2">{snackbar.message}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CompanyProfile;