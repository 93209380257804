import {Route} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";
import { styled } from '@mui/material/styles';

const LoadingContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    height: '160px',
});

const LogoContainer = styled('div')({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
});

const Logo = styled('img')({
    height: '40px',
    width: 'auto',
    objectFit: 'contain',
    animation: 'heartbeat 1.5s ease infinite',
    '@keyframes heartbeat': {
        '0%': {
            transform: 'scale(1)',
        },
        '14%': {
            transform: 'scale(1.3)',
        },
        '28%': {
            transform: 'scale(1)',
        },
        '42%': {
            transform: 'scale(1.3)',
        },
        '70%': {
            transform: 'scale(1)',
        },
    },
});

const CenteredLoading = () => (
    <LoadingContainer>
        <LogoContainer>
            <Logo
                src="/fantet-logo.png"
                alt="Fantet Logo"
            />
        </LogoContainer>
    </LoadingContainer>
);

export default CenteredLoading;