import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../utils/axios';

// Async thunk for fetching theme components
export const fetchThemeComponents = createAsyncThunk(
    'themeComponents/fetchThemeComponents',
    async ({ page = 1, limit = 10, organization_id }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/theme-components`, {
                params: { page, limit, organization_id }
            });

            if (response.data.code !== 200) {
                return rejectWithValue(response.data);
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response?.data || {
                    message: error.message,
                    code: 500,
                    description: 'Failed to fetch theme components'
                }
            );
        }
    }
);

// Async thunk for updating a theme component
export const updateThemeComponent = createAsyncThunk(
    'themeComponents/updateThemeComponent',
    async ({ id, themeComponentData }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.patch(
                `/theme-components?id=${id}`,
                themeComponentData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Update response:', response.data);

            // Check if response indicates an error
            if (response.data.code !== 200 && response.data.code !== 201) {
                return rejectWithValue(response.data);
            }

            return response.data;
        } catch (error) {
            console.error('Update error:', error);
            return rejectWithValue(
                error.response?.data || {
                    message: error.message,
                    code: 500,
                    description: 'Failed to update theme component'
                }
            );
        }
    }
);

const initialState = {
    themeComponents: [],
    pagination: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 0,
        limit: 10
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    updateStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    updateError: null
};

const themeComponentsSlice = createSlice({
    name: 'themeComponents',
    initialState,
    reducers: {
        resetUpdateStatus: (state) => {
            state.updateStatus = 'idle';
            state.updateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch cases
            .addCase(fetchThemeComponents.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchThemeComponents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.themeComponents = action.payload.data.themeComponents;
                state.pagination = action.payload.data.pagination;
                state.error = null;
            })
            .addCase(fetchThemeComponents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.description || 'Failed to fetch theme components';
            })
            // Update cases
            .addCase(updateThemeComponent.pending, (state) => {
                state.updateStatus = 'loading';
                state.updateError = null;
            })
            .addCase(updateThemeComponent.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                const updatedComponent = action.payload.data;
                const index = state.themeComponents.findIndex(
                    component => component.id === updatedComponent.id
                );
                if (index !== -1) {
                    state.themeComponents[index] = updatedComponent;
                }
                state.updateError = null;
            })
            .addCase(updateThemeComponent.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.updateError = action.payload?.description || 'Failed to update theme component';
            });
    }
});

// Action creators
export const { resetUpdateStatus } = themeComponentsSlice.actions;

// Selectors
export const selectAllThemeComponents = (state) => state.themeComponents.themeComponents;
export const selectThemeComponentsStatus = (state) => state.themeComponents.status;
export const selectThemeComponentsError = (state) => state.themeComponents.error;
export const selectUpdateStatus = (state) => state.themeComponents.updateStatus;
export const selectUpdateError = (state) => state.themeComponents.updateError;
export const selectPagination = (state) => state.themeComponents.pagination;

export default themeComponentsSlice.reducer;
