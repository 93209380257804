import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, TextField, Divider, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Alert } from '@mui/material';
import { styled } from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')({
    color: 'red',
    marginLeft: '4px',
});

const malaysiaStates = [
    "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
    "Pahang", "Penang", "Perak", "Perlis", "Sabah",
    "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
];

const FirstStepForm = ({ organizationData = {}, setOrganizationData, requiredFields = [], onSave }) => {
    const [formData, setFormData] = useState(() => ({
        companyName: organizationData.name || '',
        registrationNo: organizationData.registration_number || '',
        addressLine1: organizationData.company_address_1 || '',
        addressLine2: organizationData.company_address_2 || '',
        postcode: organizationData.company_postcode || '',
        city: organizationData.company_city || '',
        state: organizationData.company_state || '',
        country: organizationData.company_country || 'Malaysia'
    }));
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Update form data when organizationData prop changes
    useEffect(() => {
        const newData = {
            companyName: organizationData.name || '',
            registrationNo: organizationData.registration_number || '',
            addressLine1: organizationData.company_address_1 || '',
            addressLine2: organizationData.company_address_2 || '',
            postcode: organizationData.company_postcode || '',
            city: organizationData.company_city || '',
            state: organizationData.company_state || '',
            country: organizationData.company_country || 'Malaysia'
        };

        // Only update if there are actual changes
        if (JSON.stringify(newData) !== JSON.stringify(formData)) {
            setFormData(newData);
        }
    }, [organizationData]);

    // Validate form when data or required fields change
    useEffect(() => {
        const isValid = requiredFields.every(field => {
            const value = formData[field];
            if (!value) return false;
            if (typeof value !== 'string') return false;
            return value.trim() !== '';
        });
        setIsFormValid(isValid);
    }, [formData, requiredFields]);

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'postcode' ? value.replace(/\D/g, '').slice(0, 5) : value
        }));
        setError(null);
    }, []);

    const handleSave = useCallback(async () => {
        if (!isFormValid) return;
        
        setIsLoading(true);
        setError(null);

        try {
            await onSave(formData);
        } catch (error) {
            console.error("Error saving form data:", error);
            setError(error.message || 'Failed to save form data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [formData, onSave, isFormValid]);

    const renderField = useCallback((label, field, required = false, options = null) => (
        <React.Fragment key={field}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {options ? (
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel id={`${field}-select-label`}>Select {label}</InputLabel>
                        <Select
                            labelId={`${field}-select-label`}
                            id={`${field}-select`}
                            name={field}
                            value={options.includes(formData[field] || '') ? formData[field] : ''}
                            onChange={handleInputChange}
                            required={requiredFields.includes(field)}
                            label={`Select ${label}`}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        name={field}
                        value={formData[field] || ''}
                        onChange={handleInputChange}
                        required={requiredFields.includes(field)}
                        fullWidth
                        label={`Enter ${label}`}
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        inputProps={field === 'postcode' ? {
                            maxLength: 5,
                            pattern: '[0-9]*',
                            inputMode: 'numeric'
                        } : {}}
                        error={field === 'postcode' && formData[field]?.length > 0 && formData[field]?.length < 5}
                        helperText={field === 'postcode' && formData[field]?.length > 0 && formData[field]?.length < 5 ? "Postcode must be 5 digits" : ""}
                    />
                )}
            </Box>
            <Divider />
        </React.Fragment>
    ), [formData, handleInputChange, requiredFields]);

    const formFields = useMemo(() => [
        { label: 'Company Name', field: 'companyName', required: true },
        { label: 'Registration No.', field: 'registrationNo', required: true },
        { label: 'Company Address Line 1', field: 'addressLine1', required: true },
        { label: 'Company Address Line 2', field: 'addressLine2' },
        { label: 'Postcode', field: 'postcode', required: true },
        { label: 'City', field: 'city', required: true },
        { label: 'State', field: 'state', required: true, options: malaysiaStates },
        { label: 'Country', field: 'country', required: true, options: ['Malaysia'] }
    ], []);

    return (
        <Box component="form" noValidate autoComplete="off">
            <Box sx={{marginBottom: 2}}>
                <Typography variant='h6'>
                    Business Details
                </Typography>
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            {formFields.map(({ label, field, required, options }) =>
                renderField(label, field, required, options)
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!isFormValid || isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default React.memo(FirstStepForm);