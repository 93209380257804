import React, { useState } from 'react';
import { Button, Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import BarChartIcon from '@mui/icons-material/BarChart';
import SecurityHistory from './TeamSecurityContents/SecurityHistory';
import InstalledApps from './TeamSecurityContents/InstalledApps';
import DataSharing from './TeamSecurityContents/DataSharing';
import ViewRolePermissions from './TeamSecurityContents/ViewRolePermissions';
import Roles from './TeamSecurityContents/Roles';
import PermissionGate from 'components/shared/PermissionGate';

const TeamSecurity = () => {
    const [value, setValue] = useState(0);

    // Function to render tab content based on index
    const renderTabContent = index => {
        if (index === 0) {
            return (
                <PermissionGate
                    featureCode='RDTEM'
                    fallback={
                        // <PermissionGate featureCode='TEAM_READ' fallback={null}>
                            <Roles />
                        // </PermissionGate>
                    }
                >
                    <ViewRolePermissions />
                </PermissionGate>
            );
        }
        return (
            // <PermissionGate featureCode='TEAM_READ' fallback={null}>
                <Roles />
            // </PermissionGate>
        );
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Team and Security</Typography>
                        <Typography variant='h5'>
                            Manage your team members and their roles to enhance collaboration and productivity.
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                        {/*<Button variant="cancel" startIcon={<FilterListIcon />}>*/}
                        {/*    Filter*/}
                        {/*</Button>*/}
                        {/*<Button variant="cancel" startIcon={<GetAppIcon />}>*/}
                        {/*    Export*/}
                        {/*</Button>*/}
                        {/*<Button variant="cancel" startIcon={<BarChartIcon />}>*/}
                        {/*    Analyze*/}
                        {/*</Button>*/}
                        {/*<Button variant="save" startIcon={<AddIcon />} sx={{ minWidth: 200 }}>*/}
                        {/*    Create Subscription*/}
                        {/*</Button>*/}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        {/*<PermissionGate featureCode='RDTEM' fallback={null}>*/}
                            <Tab label='Team' />
                        {/*</PermissionGate>*/}
                        {/*<PermissionGate featureCode='TEAM_READ' fallback={null}>*/}
                            <Tab label='Roles' />
                        {/*</PermissionGate>*/}
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>{renderTabContent(value)}</Box>
            </Box>
        </Box>
    );
};

export default TeamSecurity;
