import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Typography, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import LinkIcon from '@mui/icons-material/Link';
import BusinessIcon from '@mui/icons-material/Business';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PublicIcon from '@mui/icons-material/Public';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from "@mui/icons-material/Description";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import { fetchSingleOrganization } from '../../redux/adminOrganizationSlice';

const adminSettings = [
    { icon: <AccountBalanceIcon />, title: 'Company Name', description: 'Manage and update your company name and legal details.', path: 'company-name' },
    { icon: <EmailIcon />, title: 'Email', description: 'Manage your primary contact email and set up notification preferences.', path: 'email' },
    { icon: <ReceiptIcon />, title: 'All Transactions', description: 'View and manage all transactions made by your business.', path: 'all-transactions' },
    { icon: <ConfirmationNumberIcon />, title: 'Ticketing Transactions', description: 'View and manage all ticketing transactions.', path: 'ticketing-transactions' },
    { icon: <PaymentIcon />, title: 'Type of Payment Processor', description: 'Select and configure your preferred payment processors.', path: 'payment-processor' },
    { icon: <LinkIcon />, title: 'Public URL Generated', description: 'Generate and manage public URLs for your business.', path: 'public-url' },
    // { icon: <BusinessIcon />, title: 'Business Details', description: 'Detailed information about your business operations and structure.', path: 'business-details' },
    { icon: <TimelineIcon />, title: 'Recent Activity', description: 'Track and view the most recent activities related to your account.', path: 'recent-activity' },
    { icon: <ShoppingCartIcon />, title: 'List of Products Created', description: 'Manage the list of products your business offers.', path: 'products-created' },
    { icon: <DescriptionIcon />, title: 'TosPage, SSM, eKYC', description: 'Manage compliance documents such as Terms of Service (TosPage), SSM certificates, and electronic Know Your Customer (eKYC) details.', path: 'compliance-documents' },
    // { icon: <PublicIcon />, title: 'Public Details', description: 'Add and manage public details visible to customers.', path: 'public-details' },
    { icon: <VerifiedUserIcon />, title: 'Account Status', description: 'Check the current status of your merchant account.', path: 'account-status' },
    { icon: <SecurityIcon />, title: 'Security Settings', description: 'Manage account security, including passwords and two-factor authentication.', path: 'security-settings' }
];

const CustomCard = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    border: 'none',
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: '#fff',
    height: '100%',
    cursor: 'pointer',
}));

const CardContentBox = styled(CardContent)(({ theme }) => ({
    padding: '10px',
    display: 'flex',
    gap: 2,
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: '3px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
}));

const AdminSettings = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedOrganization, status, error } = useSelector(state => state.adminOrganizations);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleOrganization(id));
        }
    }, [dispatch, id]);

    const handleCardClick = (path) => {
        navigate(path);
    };

    // Debug logging
    console.log('Redux State:', { selectedOrganization, status, error });

    if (status === 'loading') return <Typography>Loading...</Typography>;

    if (status === 'failed') {
        console.error('Error fetching organization:', error);
        return <Typography>Error: {error && typeof error === 'object' ? error.message || JSON.stringify(error) : error || 'An error occurred'}</Typography>;
    }

    // Check if selectedOrganization exists and has the data property
    if (!selectedOrganization || !selectedOrganization.data) {
        console.error('Organization data not found:', selectedOrganization);
        return <Typography>Organization not found</Typography>;
    }

    // Use the data property of selectedOrganization
    const organizationData = selectedOrganization.data;
    console.log('Organization Data:', organizationData);

    const isDataIncomplete = !organizationData.name || !organizationData.registrationNumber || !organizationData.id;

    return (
        <Box>
            {isDataIncomplete && (
                <Alert severity="warning" sx={{ marginBottom: 2 }}>
                    Some organization details are missing. Please ensure all required information is provided.
                </Alert>
            )}
            <Typography variant="h1">{organizationData.name || 'Company Name Not Available'}</Typography>
            <Typography variant="h2" sx={{ paddingBottom:'22px'}}>{organizationData.description || 'No description available'}</Typography>
            <Typography variant="body1">Registration Number: {organizationData.registrationNumber || 'Not available'}</Typography>
            <Typography variant="body1">Organization Code: {organizationData.code || 'Not available'}</Typography>
            <Typography variant="body1">Organization ID: {organizationData.id || 'Not available'}</Typography>

            <Box paddingRight='15%' marginTop='20px'>
                <Grid container spacing={3}>
                    {adminSettings.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                            <CustomCard onClick={() => handleCardClick(item.path)}>
                                <CardContentBox>
                                    <Box
                                        sx={{
                                            height: '30px',
                                            width: '30px',
                                            backgroundColor: 'white',
                                            borderRadius: '7px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '15px',
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                lineHeight: '20px',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <DescriptionTypography>
                                            {item.description}
                                        </DescriptionTypography>
                                    </Box>
                                </CardContentBox>
                            </CustomCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default AdminSettings;