import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Box, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import ExitToApp from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useKeycloak } from '../../contexts/KeycloakContext';

const CustomText = styled(Typography)(({ theme }) => ({
    color: '#f1f1f1',
    fontFamily: 'Inter',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
}));

const HeaderProfile = () => {
    const { userInfo, logout } = useKeycloak();
    const name = userInfo?.name || 'No name available';
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLogout = () => {
        logout();
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomText onClick={handleClick} sx={{ cursor: 'pointer' }}>
                {name}
            </CustomText>
            <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
                <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        width: '250px',
                        height: 'auto',
                        backgroundColor: 'var(--Gray-50, #F9FAFB)',
                        borderRadius: '7px',
                        marginTop: '10px',
                        marginLeft: '40px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    component={Link}
                    to='/settings/profile-settings'
                    onClick={handleClose}
                    sx={{
                        border: 'none',
                        backgroundColor: 'white',
                        height: '50px',
                    }}
                >
                    <AccountCircle style={{ marginLeft: 14, marginRight: 32 }} />
                    Profile
                </MenuItem>
                {/*<MenuItem*/}
                {/*    component={Link}*/}
                {/*    to='#'*/}
                {/*    onClick={handleClose}*/}
                {/*    sx={{*/}
                {/*        border: 'none',*/}
                {/*        backgroundColor: 'white',*/}
                {/*        height: '50px',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <VerifiedUser style={{ marginLeft: 14, marginRight: 32 }} />*/}
                {/*    Verification*/}
                {/*</MenuItem>*/}
                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        border: 'none',
                        backgroundColor: 'white',
                        height: '50px',
                    }}
                >
                    <ExitToApp style={{ marginLeft: 14, marginRight: 32 }} />
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default HeaderProfile;
