import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Card,
    TextField,
    Button,
    IconButton,
    Tooltip,
    Grid,
    Paper,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Alert,
    useTheme,
    alpha
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
    Share as ShareIcon,
    WhatsApp as WhatsAppIcon,
    Check as CheckIcon,
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    LinkedIn as LinkedInIcon,
    Telegram as TelegramIcon,
} from '@mui/icons-material';

const AgentReferral = ({ agentCode }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [shareAnchorEl, setShareAnchorEl] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);

    const referralLink = `https://www.orchardvilla.com.my/iftar2024/?promo=${agentCode}`;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopied(true);
            setShowSnackbar(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
            setShowSnackbar(true);
        }
    };

    const handleShareClick = (event) => setShareAnchorEl(event.currentTarget);
    const handleShareClose = () => setShareAnchorEl(null);

    const shareActions = {
        whatsapp: () => {
            window.open(`https://wa.me/?text=${encodeURIComponent(`Join me for an amazing Iftar event! ${referralLink}`)}`, '_blank');
        },
        facebook: () => {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`, '_blank');
            handleShareClose();
        },
        twitter: () => {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Join me for an amazing Iftar event!')}`, '_blank');
            handleShareClose();
        },
        linkedin: () => {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(referralLink)}`, '_blank');
            handleShareClose();
        },
        telegram: () => {
            window.open(`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Join me for an amazing Iftar event!')}`, '_blank');
            handleShareClose();
        }
    };

    const socialPlatforms = [
        { icon: <FacebookIcon sx={{ color: '#1877F2' }} />, label: 'Facebook', action: shareActions.facebook },
        // { icon: <TwitterIcon sx={{ color: '#1DA1F2' }} />, label: 'Twitter', action: shareActions.twitter },
        // { icon: <LinkedInIcon sx={{ color: '#0A66C2' }} />, label: 'LinkedIn', action: shareActions.linkedin },
        // { icon: <TelegramIcon sx={{ color: '#0088cc' }} />, label: 'Telegram', action: shareActions.telegram }
    ];

    return (
        <Box>
            <Card
                sx={{
                    overflow: 'hidden',
                    boxShadow: theme.shadows[3],
                    '&:hover': {
                        boxShadow: theme.shadows[6]
                    }
                }}
            >
                {/* Hero Section */}
                <Box
                    sx={{
                        background: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.48)}, ${alpha(theme.palette.primary.main, 0.48)})`,
                        backgroundColor: 'common.white', // This creates the white base
                        position: 'relative',
                        py: 6,
                        px: 4,
                        color: 'primary.darker',  // Using darker shade for text
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            opacity: 0.36,
                            background: 'repeating-linear-gradient(45deg, #fff, #fff 10px, transparent 10px, transparent 20px)'
                        }}
                    />
                    <Box sx={{ position: 'relative', textAlign: 'center' }}>
                        <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
                            Agent Referral Program
                        </Typography>
                        <Typography variant="h6" sx={{ opacity: 0.9, maxWidth: 600, mx: 'auto' }}>
                            Share your unique code and earn rewards with every successful transactions
                        </Typography>
                    </Box>
                </Box>


                {/* Referral Code Section */}
                <Box sx={{ p: 4 }}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 4,
                            textAlign: 'center',
                            bgcolor: alpha(theme.palette.primary.dark, 0.05),
                            border: '2px dashed',
                            borderColor: 'primary.dark',
                            mb: 4,
                            width: 330,
                            mx: 'auto'
                        }}
                    >
                        <Typography
                            variant="h3"
                            component="div"
                            fontWeight="bold"
                            color="primary.dark"
                            sx={{ letterSpacing: 4 }}
                        >
                            {agentCode}
                        </Typography>
                    </Paper>

                    {/* Referral Link Section */}
                    <Box>
                        <TextField
                            fullWidth
                            value={referralLink}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <Tooltip title={copied ? "Copied!" : "Copy link"}>
                                        <IconButton onClick={copyToClipboard} edge="end">
                                            {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            sx={{ mb: 3 }}
                        />

                        {/* Share Buttons */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    startIcon={<WhatsAppIcon />}
                                    onClick={shareActions.whatsapp}
                                    sx={{
                                        bgcolor: '#25D366',
                                        '&:hover': { bgcolor: '#128C7E' },
                                        whiteSpace: 'nowrap',
                                        borderWidth: 2,
                                        height: 46,  // Add this line
                                        color:'white',
                                    }}
                                >
                                    Share via WhatsApp
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    startIcon={<ShareIcon />}
                                    onClick={handleShareClick}
                                    sx={{
                                        borderWidth: 2,
                                        height: 46,
                                        color: 'primary.dark',
                                        borderColor: 'primary.dark',
                                        '&:hover': {
                                            borderWidth: 2,
                                            borderColor: 'primary.dark',
                                        }
                                    }}
                                >
                                    More
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* How it Works Section */}
                <Box sx={{ p: 2, bgcolor: 'grey.50' }}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom align="center">
                        How It Works
                    </Typography>
                    <Grid container spacing={4} sx={{ mt: 0 }}>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'primary.light',
                                        color: 'white',
                                        mx: 'auto',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="h5" sx={{ color: 'black' }}>1</Typography>
                                </Paper>
                                <Typography variant="h6" gutterBottom>Share Your Code</Typography>
                                <Typography color="text.secondary">
                                    Share your unique referral code with friends and family
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'primary.light',
                                        color: 'white',
                                        mx: 'auto',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="h5" sx={{ color: 'black' }}>2</Typography>
                                </Paper>
                                <Typography variant="h6" gutterBottom>They Book</Typography>
                                <Typography color="text.secondary">
                                    Your referrals make a purchase using your code
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'primary.light',
                                        color: 'white',
                                        mx: 'auto',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="h5" sx={{ color: 'black' }}>3</Typography>
                                </Paper>
                                <Typography variant="h6" gutterBottom>You Earn</Typography>
                                <Typography color="text.secondary">
                                    Earn rewards for every successful transactions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Share Menu */}
                <Menu
                    anchorEl={shareAnchorEl}
                    open={Boolean(shareAnchorEl)}
                    onClose={handleShareClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {socialPlatforms.map((platform) => (
                        <MenuItem
                            key={platform.label}
                            onClick={platform.action}
                            sx={{ py: 1.5 }}
                        >
                            <ListItemIcon>{platform.icon}</ListItemIcon>
                            <ListItemText>{platform.label}</ListItemText>
                        </MenuItem>
                    ))}
                </Menu>

                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={2000}
                    onClose={() => setShowSnackbar(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setShowSnackbar(false)}
                        severity="success"
                        variant="filled"
                        elevation={6}
                    >
                        Link copied to clipboard!
                    </Alert>
                </Snackbar>
            </Card>
        </Box>
    );
};

export default AgentReferral;