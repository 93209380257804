import {
    Card,
    Button,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Container, Tabs, Tab,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useEffect, useState } from "react";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledCard = styled(Card, {
    shouldForwardProp: prop => prop !== 'isFeatured',
})(({ theme, isFeatured }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${isFeatured ? '#1A1A1A' : theme.palette.divider}`,
    borderRadius: 16,
    boxShadow: isFeatured ? `0 0 0 2px #1A1A1A` : 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    position: 'relative',
    '&:before': isFeatured ? {
        content: '""',
        position: 'absolute',
        top: -2,
        right: -2,
        bottom: -2,
        left: -2,
        background: 'linear-gradient(45deg, #1A1A1A, #333333)',
        borderRadius: '18px',
        zIndex: -1,
    } : {},
    transition: 'transform 0.2s ease-in-out',
}));

const HeaderSection = styled(Box)(() => ({
    minHeight: 140,
    display: 'flex',
    flexDirection: 'column',
}));

const PriceSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    minHeight: 100,
}));

const FeaturesSection = styled(Box)(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
}));

const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
}));

const DiscountBadge = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(90deg, #FFEB3B 0%, #FF9800 100%)',
    color: 'black',
    padding: '4px 12px',
    borderRadius: 16,
    display: 'inline-block',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
}));

const FeatureList = styled(List)(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}));

const FeaturedBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: -8,
    left: -1,
    right: -1,
    background: '#1A1A1A',
    color: '#FFFFFF',
    padding: '6px 24px',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 600,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    border: '1px solid #1A1A1A',
    borderBottom: 'none',
}));

const PlanTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    '.MuiTabs-flexContainer': {
        justifyContent: 'center',
    },
    '.MuiTab-root': {
        minWidth: 150,
    }
}));

const SavingsBadge = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(90deg, #22C55E 0%, #16A34A 100%)',
    color: 'white',
    padding: '4px 12px',
    borderRadius: 16,
    display: 'inline-block',
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
    fontWeight: 600,
}));

const MerchantSubscriptionPlans = () => {
    const [plans, setPlans] = useState([]);
    const [billingCycle, setBillingCycle] = useState('monthly');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/organization-plans`);
                setPlans(response.data.plans);
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        };

        fetchPlans();
    }, []);

    const handleBillingCycleChange = (event, newValue) => {
        setBillingCycle(newValue);
    };

    const calculateYearlyPrice = (monthlyPrice) => {
        // 10 months price for yearly subscription (2 months free)
        return monthlyPrice * 10;
    };

    const calculateMonthlySavings = (monthlyPrice) => {
        const yearlyTotal = monthlyPrice * 12;
        const discountedYearlyPrice = calculateYearlyPrice(monthlyPrice);
        return ((yearlyTotal - discountedYearlyPrice) / 12).toFixed(2);
    };

    const organizeFeatures = (planFeatures) => {
        const featureMap = new Map();
        const rootFeatures = [];

        // First pass: Create map of all features and identify root features
        planFeatures.forEach(planFeature => {
            const feature = planFeature.feature;
            featureMap.set(feature.id, {
                ...planFeature,
                children: []
            });

            // If feature has no parent, it's a root feature
            if (!feature.parent_id) {
                rootFeatures.push(featureMap.get(feature.id));
            }
        });

        // Second pass: Build hierarchy
        planFeatures.forEach(planFeature => {
            const feature = planFeature.feature;
            if (feature.parent_id) {
                const parentFeature = featureMap.get(feature.parent_id);
                if (parentFeature) {
                    // Check if child already exists to prevent duplicates
                    if (!parentFeature.children.some(child => child.feature.id === feature.id)) {
                        parentFeature.children.push(featureMap.get(feature.id));
                    }
                }
            }
        });

        // Sort children by name for consistent display
        const sortChildren = (features) => {
            features.forEach(feature => {
                if (feature.children && feature.children.length > 0) {
                    feature.children.sort((a, b) => a.feature.name.localeCompare(b.feature.name));
                    sortChildren(feature.children);
                }
            });
        };

        // Sort root features and their children
        rootFeatures.sort((a, b) => a.feature.name.localeCompare(b.feature.name));
        sortChildren(rootFeatures);

        return rootFeatures;
    };

    const formatLimitText = (feature) => {
        const name = feature.feature.name.toLowerCase();
        const limit = feature.max_limit;
        const min_limit = feature.min_limit;

        if (name.includes('growth')) {
            if (min_limit !== null && min_limit !== undefined) {
                return `${feature.commission_rate}% per ticket price (${min_limit}-${limit} tickets)`;
            } else {
                return `${feature.commission_rate}% per ticket price (${limit}+ tickets)`;
            }
        } else if (name.includes('storage') || name.includes('space')) {
            return `${limit}GB`;
        } else if (name.includes('bandwidth')) {
            return `${limit}GB/mo`;
        } else if (name.includes('user') || name.includes('member')) {
            return `${limit} users`;
        } else if (name.includes('event')) {
            return `${limit} events`;
        } else if (name.includes('product')) {
            return `${limit} products`;
        } else if (name.includes('booking')) {
            return `${limit} bookings`;
        } else {
            return `Up to ${limit}`;
        }
    };

    const FeatureItem = ({ feature, depth = 0 }) => (
        <>
            <ListItem 
                disablePadding 
                sx={{ 
                    pl: depth * 2, 
                    mb: feature.children?.length > 0 ? 0.5 : 0 
                }}
            >
                <ListItemIcon sx={{ minWidth: 24 }}>  
                    <CheckIcon sx={{ fontSize: depth === 0 ? 18 : 16 }} />  
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Box component="span" sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            ...(feature.children?.length > 0 ? {
                                fontWeight: 600,
                                fontSize: '0.9rem'  
                            } : {
                                fontSize: depth === 0 ? '0.9rem' : '0.85rem',  
                                color: depth === 0 ? 'text.primary' : 'text.secondary'  
                            })
                        }}>
                            <span>{feature.feature.name}</span>
                            {(feature.max_limit !== null || feature.label) && feature.children?.length === 0 && (
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    {feature.max_limit !== null && (
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={(theme) => ({
                                                backgroundColor: '#EAECF0',
                                                color: '#344054',
                                                px: 0.75,
                                                py: 0.25,
                                                borderRadius: 1,
                                                fontSize: '0.7rem',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 500,
                                                border: `1px solid ${theme.palette.action.disabledBackground}`
                                            })}
                                        >
                                            {formatLimitText(feature)}
                                        </Typography>
                                    )}
                                    {feature.label && (
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={{
                                                backgroundColor: '#FFE5E5',
                                                color: '#FF4D4D',
                                                px: 0.75,
                                                py: 0.25,
                                                borderRadius: 1,
                                                fontSize: '0.7rem',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 600,
                                                animation: 'pulse 2s infinite',
                                                '@keyframes pulse': {
                                                    '0%': { opacity: 1 },
                                                    '50%': { opacity: 0.6 },
                                                    '100%': { opacity: 1 }
                                                }
                                            }}
                                        >
                                            {feature.label}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                    }
                    primaryTypographyProps={{
                        sx: { 
                            lineHeight: 1.2,  
                            my: 0.25  
                        }
                    }}
                />
            </ListItem>
            {feature.children?.map(child => (
                <FeatureItem 
                    key={child.id} 
                    feature={child} 
                    depth={depth + 1}
                />
            ))}
        </>
    );

    const handleSubscribe = async (planId) => {
        try {
            navigate(`/merchant/subscribe/${planId}?billing=${billingCycle}`);
        } catch (error) {
            console.error('Error subscribing to plan:', error);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ py: 2 }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center">
                    Choose Your Plan
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" align="center">
                    Select the perfect plan for your business needs
                </Typography>
            </Box>

            <PlanTabs
                value={billingCycle}
                onChange={handleBillingCycleChange}
                centered
            >
                <Tab label="Monthly" value="monthly" />
                <Tab label="Yearly" value="yearly" />
            </PlanTabs>

            <Grid container spacing={3}>
                {plans
                    .filter(plan => plan.is_active)
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .map((plan) => {
                        const monthlyPrice = parseFloat(plan.price_monthly);
                        const yearlyPrice = calculateYearlyPrice(monthlyPrice);
                        const displayPrice = billingCycle === 'yearly' ? yearlyPrice : monthlyPrice;
                        const monthlySavings = calculateMonthlySavings(monthlyPrice);

                        return (
                            <Grid item xs={12} md={4} key={plan.id}>
                                <StyledCard isFeatured={plan.is_most_popular}>
                                    {plan.is_most_popular && (
                                        <FeaturedBadge>
                                            <WorkspacePremiumIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                            Most Popular
                                        </FeaturedBadge>
                                    )}
                                    <HeaderSection>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                                                {plan.name}
                                            </Typography>
                                            {plan.is_most_popular && (
                                                <WorkspacePremiumIcon sx={{ color: '#FFD700', fontSize: 20 }} />
                                            )}
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <SmallText>{plan.description}</SmallText>
                                        </Box>
                                    </HeaderSection>

                                    <PriceSection>
                                        {billingCycle === 'yearly' && (
                                            <SavingsBadge>
                                                Save RM{monthlySavings}/mo
                                            </SavingsBadge>
                                        )}
                                        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 0.5 }}>
                                            <Typography component="span" sx={{ fontWeight: 600 }}>RM</Typography>
                                            <Typography variant="h4" component="span" sx={{ fontWeight: 600 }}>
                                                {displayPrice.toFixed(2)}
                                            </Typography>
                                            <SmallText sx={{ ml: 1 }}>
                                                /{billingCycle === 'yearly' ? 'year' : 'month'}
                                            </SmallText>
                                        </Box>
                                        {billingCycle === 'yearly' && (
                                            <Typography variant="caption" color="text.secondary">
                                                Equivalent to RM{(yearlyPrice / 12).toFixed(2)}/month
                                            </Typography>
                                        )}
                                    </PriceSection>

                                    <FeaturesSection>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{ mb: 3 }}
                                            onClick={() => handleSubscribe(plan.id)}
                                        >
                                            {plan.name.toLowerCase() === 'enterprise' ? 'Contact Sales' : 'Choose Plan'}
                                        </Button>

                                        <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                            Features included:
                                        </Typography>

                                        <FeatureList disablePadding>
                                            {organizeFeatures(plan.planFeatures)
                                                .filter(feature => !feature.feature.name.toLowerCase().includes('growth'))
                                                .map((feature) => (
                                                    <FeatureItem key={feature.id} feature={feature} />
                                                ))}
                                        </FeatureList>

                                        <Box sx={{ mt: 3 }}>
                                            <FeatureList disablePadding>
                                                {organizeFeatures(plan.planFeatures)
                                                    .filter(feature => feature.feature.name.toLowerCase().includes('growth'))
                                                    .sort((a, b) => (a.min_limit || 0) - (b.min_limit || 0))
                                                    .map((feature, featureIndex) => (
                                                        <FeatureItem key={featureIndex} feature={feature} />
                                                    ))}
                                            </FeatureList>
                                        </Box>
                                    </FeaturesSection>
                                </StyledCard>
                            </Grid>
                        );
                    })}
            </Grid>
        </Container>
    );
};

export default MerchantSubscriptionPlans;
