import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useOrganizationRoles } from './useOrganizationRoles';

/**
 * PermissionGate component that controls access to features based on permissions
 * @param {string} featureCode - The feature code to check permission for
 * @param {React.ReactNode} children - The content to render if permission is granted
 * @param {React.ReactNode} fallback - Optional content to render if permission is denied
 * @param {string} requiredPermission - Optional specific permission required (read/write/delete)
 */
const PermissionGate = ({
                          featureCode,
                          children,
                          fallback = null,
                          requiredPermission = 'read'
                        }) => {
  const { features = [], loading = false } = useSelector((state) => state?.permissions || {});
  const organizationRoles = useOrganizationRoles();

  if (loading) {
    return null;
  }

  // Check if user has org_admin role
  const isOrgAdmin = organizationRoles.includes('org_admin');

  // If user is org_admin, allow access to all features
  if (isOrgAdmin) {
    return children;
  }

  const feature = features.find(f => f?.code === featureCode);

  if (!feature || !feature.isActive) {
    return fallback;
  }

  const hasPermission = (() => {
    switch (requiredPermission) {
      case 'write':
        return feature.canWrite;
      case 'delete':
        return feature.canDelete;
      case 'read':
      default:
        return feature.canRead;
    }
  })();

  if (!hasPermission) {
    return fallback;
  }

  return children;
};

PermissionGate.propTypes = {
  featureCode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  requiredPermission: PropTypes.oneOf(['read', 'write', 'delete'])
};

export default PermissionGate;
