import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Tabs, Tab, Divider, Typography, Grid } from '@mui/material';
import {
    Add as AddIcon,
    CalendarMonth as AppointmentsIcon,
    PaidOutlined as RevenueIcon,
    AttachMoney as ValueIcon,
    EventBusy as CancelledIcon,
} from '@mui/icons-material';
import Active from './Active';
import Incoming from './Incoming';
import Past from './Past';
import AnalyticsWidgetSummary from '../../../shared/AnalyticsWidgetSummary';
import PermissionGate from 'components/shared/PermissionGate';

const Appointments = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = () => {
        navigate('add-appointments');
    };

    const renderTabContent = () => {
        switch (value) {
            case 0:
                return (
                    <PermissionGate featureCode='APPO_READ' fallback={null}>
                        <Active />
                    </PermissionGate>
                );
            case 1:
                return (
                    <PermissionGate featureCode='APPO_READ1' fallback={null}>
                        <Incoming />
                    </PermissionGate>
                );
            case 2:
                return (
                    <PermissionGate featureCode='APPO_READ2' fallback={null}>
                        <Past />
                    </PermissionGate>
                );
            default:
                return null;
        }
    };

    return (
        <Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Appointments</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            Manage your appointments and schedules.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <PermissionGate featureCode='CREA1' fallback={null}>
                            <Button variant='save' color='primary' startIcon={<AddIcon />} onClick={handleClick}>
                                Add New Appointments
                            </Button>
                        </PermissionGate>
                    </Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='APPO_READ3' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Monthly Appointments'
                                percent={3.2}
                                total={2845}
                                icon={
                                    <AppointmentsIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: '#ffeb3b',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='APPO_READ4' fallback={null}>
                            <AnalyticsWidgetSummary
                                title="Today's Revenue"
                                percent={4.5}
                                total={15420}
                                color='secondary'
                                icon={
                                    <RevenueIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'secondary.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='APPO_READ5' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Average Appointment Value'
                                percent={1.8}
                                total={542}
                                color='warning'
                                icon={
                                    <ValueIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'warning.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [400, 250, 480, 320, 500, 380, 450, 542],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='APPO_READ6' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Cancelled Appointments'
                                percent={-0.5}
                                total={124}
                                color='error'
                                icon={
                                    <CancelledIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'error.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [220, 280, 180, 250, 160, 200, 140, 124],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                </Grid>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        <PermissionGate featureCode='APPO_READ' fallback={null}>
                            <Tab label='Active' />
                        </PermissionGate>
                        <PermissionGate featureCode='APPO_READ1' fallback={null}>
                            <Tab label='Incoming' />
                        </PermissionGate>
                        <PermissionGate featureCode='APPO_READ2' fallback={null}>
                            <Tab label='Past' />
                        </PermissionGate>
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '10px 0 10px 0' }} />

                <Box>{renderTabContent()}</Box>
            </Box>
        </Box>
    );
};

export default Appointments;
