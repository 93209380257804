import { useCallback, useEffect, useMemo, useState } from 'react';
import {Box, Divider, InputAdornment, OutlinedInput, Paper, Skeleton, TextField, Typography,} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import _, {debounce} from 'lodash'; // For debounce
import BookingDetailsModal from "./actions/BookingDetailsModal";
import BookingList from "./actions/BookingList";
import ChangeDateDialog from "./actions/ChangeDate";
import GetBooking from "./actions/GetBooking";
import { fetchTicketingOverviewCurrentData } from "./TicketingOverviewAPI";
import SearchIcon from "@mui/icons-material/Search";

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const TicketingOverviewCurrent = () => {
    const [organizationId, setOrganizationId] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ticketData, setTicketData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchTicketingOverviewCurrentData(paginationModel, searchQuery, organizationId);
                if (data.code === 200 && data.data && Array.isArray(data.data.purchases)) {
                    const mappedData = data.data.purchases.map((purchase) => {
                        const { id, created_at, invoice_number, ticketing_total_amount, ticketing_total_quantity, customer, agent, ticketing_details } = purchase;
                        return {
                            id,
                            created_at,
                            invoice_number,
                            ticketing_total_amount,
                            ticketing_total_quantity,
                            ticketing_invoice_number: ticketing_details[0]?.event_id,
                            customer_name: `${customer?.first_name || ''} ${customer?.last_name || ''}`.trim(),
                            agent_name: agent ? `${agent.first_name || ''} ${agent.last_name || ''}`.trim() : 'N/A',
                            event_name: ticketing_details[0]?.event?.name || 'No event',
                            event_date: ticketing_details[0]?.event?.daily_events[0]?.event_date || 'No daily event',
                            ticket_category: ticketing_details[0]?.ticket_category || 'N/A',
                        };
                    });
                    setTicketData(mappedData);
                    setTotalRows(data.data.pagination?.totalCount || 0);
                } else {
                    throw new Error(data.message || 'Invalid data structure received from the server');
                }
            } catch (err) {
                setError(err.message);
                setTicketData([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            // cleanup
        };
    }, [paginationModel.page, paginationModel.pageSize, searchQuery]);

    // This effect will trigger the API call
    useEffect(() => {
        if (debouncedSearchTerm) {
            // Call your search API here
            // console.log('Searching API for:', debouncedSearchTerm);
            setPaginationModel((prev) => ({ ...prev, page: 0 }));
        }
    }, [debouncedSearchTerm]);

    const debouncedSetSearch = useMemo(
        () => debounce(setDebouncedSearchTerm, 300),
        []
    );

    const handleSearchChange = useCallback((event) => {
        const value = event.target.value;
        setSearchQuery(value);  // Update local state immediately for UI
        debouncedSetSearch(value);  // Debounce the update of the term that triggers the API call
    }, [debouncedSetSearch]);

    // Cleanup the debounced function on component unmount
    useEffect(() => {
        return () => {
            debouncedSetSearch.cancel();
        };
    }, [debouncedSetSearch]);

    const handlePaginationModelChange = useCallback((newModel) => {
        setPaginationModel(newModel);
    }, []);

    const handleSelectionModelChange = (newSelectionModel) => {
        // setSelectedRows(newSelectionModel);
    };

    const columns = useMemo(() => [
        {
            field: 'invoice_number',
            headerName: 'Invoice No.',
            flex: 0.8,
            minWidth: 130,
            renderCell: (params) => (
                <Box>
                    <Typography variant="body2" noWrap>{params.row.invoice_number}</Typography>
                    <Typography variant="caption" color="textSecondary" noWrap>{params.row.ticketing_invoice_number}</Typography>
                </Box>
            ),
        },
        {
            field: 'event_name',
            headerName: 'Event',
            flex: 1.2,
            minWidth: 200,
            renderCell: (params) => (
                <Box>
                    <Typography variant="body2" noWrap>{params.row.event_name}</Typography>
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {new Date(params.row.event_date).toLocaleDateString()}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'customer_name',
            headerName: 'Customer',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'ticketing_details',
            headerName: 'Ticket Details',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box>
                    <Typography variant="body2" noWrap>Qty: {params.row.ticketing_total_quantity}</Typography>
                    <Typography variant="body2" noWrap>
                        Total: {params.row.ticketing_total_amount && !isNaN(params.row.ticketing_total_amount)
                        ? `RM ${parseFloat(params.row.ticketing_total_amount).toFixed(2)}`
                        : 'N/A'}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'created_at',
            headerName: 'Purchase Date',
            flex: 1,
            minWidth: 180,
            valueFormatter: (params) => {
                const createdAt = params;
                return createdAt ? new Date(createdAt).toLocaleString() : 'N/A';
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%', width: '100%', paddingLeft: '8px' }}>
                    <BookingDetailsModal id={params.row.id} />
                    <BookingList id={params.row.id} />
                    <ChangeDateDialog
                        orderId={params.row.id}
                        currentBookingDate={params.row.bookingDate}
                    />
                    <GetBooking
                        orderId={params.row.id}
                        bookingDetails={params.row.bookingDetails}
                    />
                </Box>
            )
        }
    ], []);

    return (
        <Box sx={{ display:'flex' }}>
            <Box flex={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <OutlinedInput
                            placeholder="Search by Ticket Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            }
                            sx={{ maxWidth: 320, mb: 2 }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 24px' }}>
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                            }}
                        >
                            Current Tickets
                        </Typography>
                    </Box>

                    <Divider sx={{my:0}}/>

                    {loading ? (
                        // Show skeleton loader while loading is true
                        <Box>
                            <Box sx={{ px: 2, py: 1 }}>
                                <Skeleton variant="rectangular" height={52} />
                            </Box>
                            {[...Array(5)].map((_, index) => (
                                <SkeletonRow key={index} />
                            ))}
                        </Box>
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                    ) : (

                        <DataGrid
                            slots={{
                                toolbar: GridToolbar
                            }}
                            rows={ticketData || []}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={totalRows}
                            getRowId={(row) => row.id || Math.random()}
                            onRowSelectionModelChange={handleSelectionModelChange}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default TicketingOverviewCurrent;