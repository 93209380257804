import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const KEYCLOAK_URL = 'https://accounts.eventlah.com';
const REALM = 'eventlah';
const CLIENT_ID = 'ui-merchants-app';

// Define standard scopes
const DEFAULT_SCOPES = ['openid', 'profile', 'email', 'roles', 'offline_access'];

class AuthService {
    constructor() {
        this.navigate = null;
        this.refreshInterval = null;
        this.isRefreshing = false;
        this.refreshSubscribers = [];
    }

    // Method to set the navigation function
    setNavigate(navigateFunction) {
        this.navigate = navigateFunction;
    }

    // Method to handle redirect to login with loop prevention
    redirectToLogin() {
        const currentPath = window.location.pathname;
        if (currentPath === '/login') {
            return; // Prevent redirect loop
        }

        if (this.navigate) {
            this.navigate('/login', { replace: true });
        } else {
            window.location.replace('/login');
        }
    }

    isAuthenticated() {
        const accessToken = this.getAccessToken();
        const refreshToken = this.getRefreshToken();
        return !!(accessToken && refreshToken && !this.isTokenExpired());
    }

    async login(username, password) {
        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'password',
                    client_id: CLIENT_ID,
                    username,
                    password,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token, expires_in } = response.data;

            // Store tokens
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('token_expiry', new Date().getTime() + (expires_in * 1000));

            // Start silent refresh only if we have valid tokens
            if (this.isAuthenticated()) {
                this.startSilentRefresh();
            }

            return response.data;
        } catch (error) {
            console.error('Login error:', error.response?.data || error.message);
            throw new Error(error.response?.data?.error_description || 'Login failed');
        }
    }

    startSilentRefresh(intervalMs = 4 * 60 * 1000) {
        this.stopSilentRefresh();
        this.refreshInterval = setInterval(async () => {
            if (this.isAuthenticated() && !this.isRefreshing) {
                try {
                    await this.refreshToken();
                } catch (error) {
                    console.error('Silent refresh failed:', error);
                    this.stopSilentRefresh();
                    this.clearTokens();
                    this.redirectToLogin();
                }
            }
        }, intervalMs);
    }

    stopSilentRefresh() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    }

    async refreshToken() {
        const refresh_token = this.getRefreshToken();
        if (!refresh_token) {
            this.clearTokens();
            throw new Error('No refresh token available');
        }

        // Prevent multiple simultaneous refresh attempts
        if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
                this.refreshSubscribers.push({ resolve, reject });
            });
        }

        this.isRefreshing = true;

        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'refresh_token',
                    client_id: CLIENT_ID,
                    refresh_token: refresh_token,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token: new_refresh_token, expires_in } = response.data;

            // Update stored tokens
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', new_refresh_token);
            localStorage.setItem('token_expiry', new Date().getTime() + (expires_in * 1000));

            // Notify all subscribers of the successful refresh
            this.refreshSubscribers.forEach(subscriber => subscriber.resolve(response.data));
            this.refreshSubscribers = [];

            return response.data;
        } catch (error) {
            // Notify all subscribers of the failure
            this.refreshSubscribers.forEach(subscriber => subscriber.reject(error));
            this.refreshSubscribers = [];

            this.clearTokens();
            throw error;
        } finally {
            this.isRefreshing = false;
        }
    }

    async logout() {
        this.stopSilentRefresh();
        const refresh_token = this.getRefreshToken();

        try {
            if (refresh_token) {
                await axios.post(
                    `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/logout`,
                    new URLSearchParams({
                        client_id: CLIENT_ID,
                        refresh_token: refresh_token
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );
            }
        } catch (error) {
            console.error('Logout error:', error.response?.data || error.message);
        } finally {
            this.clearTokens();
            this.redirectToLogin();
        }
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    isTokenExpired() {
        const tokenExpiry = localStorage.getItem('token_expiry');
        if (!tokenExpiry) return true;

        // Add 30-second buffer to ensure we refresh before actual expiration
        return new Date().getTime() + 30000 > parseInt(tokenExpiry);
    }

    async ensureValidToken() {
        try {
            const accessToken = this.getAccessToken();
            const refreshToken = this.getRefreshToken();

            // If token exists and is not expired, return it immediately
            if (accessToken && !this.isTokenExpired()) {
                return accessToken;
            }

            // If token is expired but we have a refresh token, try to refresh
            if (refreshToken) {
                try {
                    const result = await this.refreshToken();
                    return result.access_token;
                } catch (error) {
                    console.error('Token refresh failed:', error);
                    this.clearTokens();
                    this.redirectToLogin();
                    return null;
                }
            }

            // If we get here, we need to redirect and clear tokens
            this.clearTokens();
            this.redirectToLogin();
            return null;
        } catch (error) {
            console.error('Auth validation error:', error);
            this.clearTokens();
            this.redirectToLogin();
            return null;
        }
    }

    clearTokens() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expiry');
        this.stopSilentRefresh();
    }

    async getUserInfo() {
        try {
            const token = await this.ensureValidToken();

            if (!token) {
                throw new Error('No valid token available');
            }

            const response = await axios.get(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/userinfo`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const decodedToken = jwtDecode(token);

            return {
                ...response.data,
                organization_roles: decodedToken.organization_roles || [],
                realm_roles: decodedToken.realm_access?.roles || [],
                resource_access: decodedToken.resource_access || {},
                organization_id: decodedToken.organization_id
            };
        } catch (error) {
            console.error('Error fetching user info:', error);
            if (error.response?.status === 401 || error.message === 'No valid token available') {
                this.clearTokens();
                this.redirectToLogin();
            }
            return null;
        }
    }
}

export const authService = new AuthService();

export const useAuthServiceNavigation = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        authService.setNavigate(navigate);
        return () => {
            // Clean up navigation on unmount
            authService.setNavigate(null);
        };
    }, [navigate]);

    return authService;
};