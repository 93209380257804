import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button, Snackbar,
    TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import AddPackageDialog from "./AddNewPackageDialogForm/AddNewPackageDialogForm";
import PackageList from "./PackageList";
import {fetchPackages, fetchPackagesByEventId} from "../../../redux/packagesSlice";
import {useDispatch, useSelector} from "react-redux";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SearchBar = ({ handleDialogOpen }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Box sx={{ display: 'flex' }}>
            <Button
                variant='cancel'
                sx={{
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    height: '34px',
                }}
            >
                Active Packages
            </Button>
            <Button
                variant='cancel'
                sx={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    color: 'var(--dark-50, #71717A)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    lineHeight: '20px',
                    background: 'var(--gray-30, #F7F7F7)',
                }}
            >
                Archived Packages
            </Button>
        </Box>
        <TextField
            variant="outlined"
            placeholder="Search for package"
            InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
            }}
            sx={{ mx: '48px', flexGrow: 1,  }}
        />
        <Box display='flex'>
            <Button variant="cancel" startIcon={<FilterListIcon />} sx={{ marginRight: 1, height: '34px' }}>
                Filters
            </Button>
            <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{ whiteSpace: 'nowrap', height: '34px' }}>
                Add Packages
            </Button>
        </Box>
    </Box>
);

const AddPackageToEvent = ({ eventId }) => {
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const fetchPackagesByEventId = async () => {
        setStatus('loading');
        try {
            const response = await axios.get(`${API_BASE_URL}/packages?event_id=${eventId}`);
            if (response.data.code === 200 && response.data.data) {
                setPackages(response.data.data.packages || []);
                setStatus('succeeded');
            } else {
                throw new Error(response.data.message || 'Failed to fetch packages');
            }
        } catch (err) {
            setError(err.message);
            setStatus('failed');
        }
    };

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = (success = false) => {
        setOpen(false);
        if (success) {
            fetchPackagesByEventId(eventId);
            setSnackbar({ open: true, message: 'Package added successfully', severity: 'success' });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <SearchBar handleDialogOpen={handleDialogOpen} />
            </Box>

            <PackageList handleDialogOpen={handleDialogOpen} eventId={eventId} onPackageCreated={fetchPackagesByEventId} />

            {/* Dialog for adding a package */}
            <AddPackageDialog eventId={eventId} open={open} handleClose={handleDialogClose} onPackageCreated={fetchPackagesByEventId} />
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddPackageToEvent;
