import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Paper, Skeleton, TextField, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import _ from 'lodash';
import BookingDetailsModal from "./actions/BookingDetailsModal";
import BookingList from "./actions/BookingList";
import ChangeDateDialog from "./actions/ChangeDate";
import GetBooking from "./actions/GetBooking";
import { fetchPaymentTicketingData } from './PaymentTicketingOverviewAPI';

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const PaymentTicketingOverviewPast = () => {
    const [organizationId, setOrganizationId] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ticketData, setTicketData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetchPaymentTicketingData(paginationModel, searchQuery, organizationId, 'past');
                console.log('Raw API Response:', response);

                if (response?.code === 200 && response?.data?.purchases) {
                    const purchases = response.data.purchases;
                    console.log('Purchases before mapping:', purchases);

                    const mappedData = purchases.map((purchase) => {
                        console.log('Purchase raw created_at:', purchase.created_at); // Debug log

                        // Find first ticketing detail with a non-null event
                        const ticketDetailWithEvent = purchase.ticketing_details?.find(detail => detail.event) || {};
                        const eventInfo = ticketDetailWithEvent.event || {};

                        const mappedItem = {
                            id: purchase.id,
                            created_at: purchase.created_at,  // Make sure this is being passed correctly
                            invoice_number: purchase.invoice_number?.trim() || 'N/A',
                            event_name: eventInfo.name || 'No event',
                            customer_name: purchase.customer ?
                                `${purchase.customer.first_name || ''} ${purchase.customer.last_name || ''}`.trim() :
                                'N/A',
                            ticketing_details: purchase.ticketing_details || [],
                            event_date: eventInfo.daily_events?.[0]?.event_date || 'No date',
                            agent_name: purchase.agent ?
                                `${purchase.agent.first_name || ''} ${purchase.agent.last_name || ''}`.trim() :
                                'N/A',
                            ticketing_total_amount: purchase.ticketing_total_amount,
                            ticketing_total_quantity: purchase.ticketing_total_quantity,
                            total_amount: purchase.total_amount
                        };

                        console.log('Mapped item created_at:', mappedItem.created_at); // Debug log
                        return mappedItem;
                    });

                    console.log('Final mapped data:', mappedData);
                    setTicketData(mappedData);
                    setTotalRows(response.data.pagination?.totalCount || 0);
                }
            } catch (err) {
                console.error('Fetch error:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [paginationModel.page, paginationModel.pageSize, searchQuery, organizationId]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            setPaginationModel((prev) => ({ ...prev, page: 0 }));
        }
    }, [debouncedSearchTerm]);

    const debouncedSetSearch = useMemo(
        () => _.debounce(setDebouncedSearchTerm, 300),
        []
    );

    const handleSearchChange = useCallback((event) => {
        const value = event.target.value;
        setSearchQuery(value);
        debouncedSetSearch(value);
    }, [debouncedSetSearch]);

    useEffect(() => {
        return () => {
            debouncedSetSearch.cancel();
        };
    }, [debouncedSetSearch]);

    const handlePaginationModelChange = useCallback((newModel) => {
        setPaginationModel(newModel);
    }, []);

    const columns = [
        {
            field: 'invoice_number',
            headerName: 'Invoice No.',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'event_name',
            headerName: 'Event Name',
            flex: 1,
            minWidth: 180
        },
        {
            field: 'customer_name',
            headerName: 'Customer Name',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'created_at',
            headerName: 'Purchase Date',
            flex: 1,
            minWidth: 180,
            valueFormatter: (params) => {
                // If params is the date string directly
                const dateValue = params.value || params;
                if (!dateValue) {
                    return 'N/A';
                }
                try {
                    const date = new Date(dateValue);
                    if (isNaN(date.getTime())) {
                        return 'N/A';
                    }
                    return date.toLocaleString('en-US', {
                        dateStyle: 'medium',
                        timeStyle: 'medium'
                    });
                } catch (error) {
                    console.error('Date formatting error:', error);
                    return 'N/A';
                }
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                    <BookingDetailsModal id={params.row.id} />
                    <BookingList id={params.row.id} />
                    <ChangeDateDialog
                        orderId={params.row.id}
                        currentBookingDate={params.row.event_date}
                    />
                    <GetBooking
                        orderId={params.row.id}
                        bookingDetails={params.row.ticketing_details}
                    />
                </Box>
            )
        }
    ];

    return (
        <Box sx={{ display:'flex' }}>
            <Box flex={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Ticket Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 24px' }}>
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                            }}
                        >
                            Past Tickets
                        </Typography>
                    </Box>

                    <Divider sx={{my:0}}/>

                    {loading ? (
                        <Box>
                            <Box sx={{ px: 2, py: 1 }}>
                                <Skeleton variant="rectangular" height={52} />
                            </Box>
                            {[...Array(5)].map((_, index) => (
                                <SkeletonRow key={index} />
                            ))}
                        </Box>
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                    ) : (
                        <DataGrid
                            slots={{
                                toolbar: GridToolbar
                            }}
                            rows={ticketData || []}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={totalRows}
                            paginationMode="server"
                            pageSizeOptions={[10, 25, 50]}
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                            sx={{
                                '& .MuiDataGrid-root': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: '1px solid #f0f0f0',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#fafafa',
                                    borderBottom: '1px solid #f0f0f0',
                                },
                                minHeight: 400
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default PaymentTicketingOverviewPast;