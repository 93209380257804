import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Divider,
    CircularProgress
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import AddFeature from './AddFeature';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const FeatureManagement = () => {
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [editingFeature, setEditingFeature] = useState(null);
    const [deleteFeature, setDeleteFeature] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalRows, setTotalRows] = useState(0);

    const fetchFeatures = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/features`, {
                params: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    search: searchQuery
                }
            });
            const fetchedFeatures = response.data.data.features;
            
            // If searching, display features as a flat list
            if (searchQuery) {
                const flatFeatures = fetchedFeatures.map(feature => ({
                    ...feature,
                    id: feature.id,
                    level: 0,
                    hasChildren: false,
                    isExpanded: false,
                    parent: feature
                }));
                setFeatures(flatFeatures);
            } else {
                // Process root features and their children
                const processedFeatures = [];
                fetchedFeatures.forEach(feature => {
                    // Add root feature
                    const hasChildren = feature.children && feature.children.length > 0;
                    processedFeatures.push({
                        ...feature,
                        children: undefined,
                        id: feature.id,
                        level: 0,
                        hasChildren,
                        isExpanded: expandedRows.has(feature.id),
                        parent: feature
                    });
                    
                    // Add children if parent is expanded
                    if (hasChildren && expandedRows.has(feature.id)) {
                        feature.children.forEach(child => {
                            processedFeatures.push({
                                ...child,
                                id: child.id,
                                level: 1,
                                hasChildren: false,
                                isExpanded: false,
                                parent: feature
                            });
                        });
                    }
                });
                setFeatures(processedFeatures);
            }
            
            setTotalRows(response.data.data.pagination.total);
        } catch (err) {
            setError('Failed to fetch features');
            console.error('Error fetching features:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleRowExpansion = (id) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(id)) {
            newExpandedRows.delete(id);
        } else {
            newExpandedRows.add(id);
        }
        setExpandedRows(newExpandedRows);
    };

    const handleAddFeature = () => {
        setEditingFeature(null);
        setOpenAddDialog(true);
    };

    const handleEditFeature = (feature) => {
        setEditingFeature(feature);
        setOpenAddDialog(true);
    };

    const handleDeleteFeature = (feature) => {
        setDeleteFeature(feature);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/features?id=${deleteFeature.id}`);
            setDeleteFeature(null);
            // Reset pagination to first page and trigger fetch
            setPaginationModel(prev => ({
                ...prev,
                page: 0
            }));
            fetchFeatures();
        } catch (err) {
            console.error('Error deleting feature:', err);
            setError('Failed to delete feature');
        }
    };

    const handleCancelDelete = () => {
        setDeleteFeature(null);
    };

    const handleDialogClose = () => {
        setOpenAddDialog(false);
        setEditingFeature(null);
    };

    const handleSuccess = () => {
        setOpenAddDialog(false);
        setEditingFeature(null);
        // Reset pagination to first page and trigger fetch
        setPaginationModel(prev => ({
            ...prev,
            page: 0
        }));
        fetchFeatures();
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel(prev => ({ ...prev, page: 0 }));
        setExpandedRows(new Set()); // Reset expanded rows when searching
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(prev => ({ ...prev, ...newModel }));
    };

    useEffect(() => {
        fetchFeatures();
    }, [paginationModel.page, paginationModel.pageSize, searchQuery, expandedRows]);

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name', 
            flex: 1.5,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        ml: params.row.level * 4,
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {params.row.hasChildren && (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRowExpansion(params.row.id);
                            }}
                            sx={{ my: 'auto' }}
                        >
                            {params.row.isExpanded ? 
                                <KeyboardArrowDownIcon /> : 
                                <KeyboardArrowRightIcon />
                            }
                        </IconButton>
                    )}
                    {!params.row.hasChildren && (
                        <Box sx={{ width: 26 }} /> // Spacer for alignment
                    )}
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                fontWeight: params.row.level === 0 ? 600 : 400,
                                color: params.row.level === 0 ? 'text.primary' : 'text.secondary'
                            }}
                        >
                            {params.row.name}
                        </Typography>
                    </Box>
                </Box>
            )
        },
        { 
            field: 'description', 
            headerName: 'Description', 
            flex: 1.5,
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'code',
            headerName: 'Code',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                    {params.row.code || '-'}
                </Box>
            ),
        },
        {
            field: 'is_populate_by_default',
            headerName: 'Default',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {params.row.is_populate_by_default ? 'Yes' : 'No'}
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton 
                        size="small" 
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditFeature(params.row);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFeature(params.row);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box>
                    <Typography variant="h4">Feature Management</Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        View, edit, and manage all your features in one place.
                    </Typography>
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                    <TextField
                        variant="outlined"
                        placeholder="Search features..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': {
                                    height: '34px',
                                    padding: '0 14px',
                                },
                                '& .MuiInputBase-input': {
                                    height: '34px',
                                    padding: '0 14px',
                                }
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleAddFeature}
                    >
                        Add Feature
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Paper
                sx={{
                    width: "100%",
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Features List
                </Typography>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={features}
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        checkboxSelection
                        disableSelectionOnClick
                        rowHeight={70}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[10, 25, 50]}
                        rowCount={totalRows}
                        paginationMode="server"
                        loading={loading}
                        disableColumnMenu
                    />
                </Box>
            </Paper>

            {openAddDialog && (
                <Dialog
                    open={openAddDialog}
                    onClose={handleDialogClose}
                    maxWidth="md"
                    fullWidth
                >
                    <AddFeature
                        onSuccess={handleSuccess}
                        onClose={handleDialogClose}
                        editFeature={editingFeature}
                    />
                </Dialog>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={Boolean(deleteFeature)}
                onClose={handleCancelDelete}
            >
                <DialogTitle>Delete Feature</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the feature "{deleteFeature?.name}"? This action cannot be undone.
                        {deleteFeature?.hasChildren && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                Warning: This feature has child features. Deleting it will also delete all its child features.
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FeatureManagement;
