import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Plus } from 'lucide-react';
import OnboardingSettings from './OnboardingSettings';
import CommissionSettings from './CommissionSettings';
import PayoutSettings from './PayoutSettings';
import MaterialSettings from './MaterialSettings';
import PermissionGate from 'components/shared/PermissionGate';
import { useOrganizationRoles } from 'components/shared/useOrganizationRoles';

const AgentSetup = () => {
    const [value, setValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const organizationRoles = useOrganizationRoles();
    const isOrgAdmin = organizationRoles?.includes('org_admin');

    const handleAddEmail = () => {
        if (validateEmail(email)) {
            setEmailList([...emailList, email]);
            setEmail('');
        }
    };

    const handleRemoveEmail = (index) => {
        const newList = [...emailList];
        newList.splice(index, 1);
        setEmailList(newList);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleAddAgents = () => {
        // TODO: Implement agent addition logic
        console.log('Adding agents with emails:', emailList);
        setIsModalOpen(false);
        setEmail('');
        setEmailList([]);
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { label: 'Onboarding Settings', featureCode: 'AGTS_READ', component: OnboardingSettings },
        { label: 'Commission Settings', featureCode: 'AGTS_READ1', component: CommissionSettings },
        { label: 'Payout Settings', featureCode: 'AGTS_READ2', component: PayoutSettings },
        { label: 'Material Settings', featureCode: 'AGTS_READ3', component: MaterialSettings }
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Agent Setup</Typography>
                        <Typography variant='h5'>Manage and configure your agent setup settings.</Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            mr: '32px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {isOrgAdmin ? (
                                <Button 
                                    variant="contained" 
                                    onClick={() => setIsModalOpen(true)}
                                    startIcon={<Plus />}
                                >
                                    Add Agent(s)
                                </Button>
                        ) : (
                            <PermissionGate featureCode='CRTAGT' fallback={null}>
                                <Button 
                                    variant="contained" 
                                    onClick={() => setIsModalOpen(true)}
                                    startIcon={<Plus />}
                                >
                                    Add Agent(s)
                                </Button>
                            </PermissionGate>
                        )}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs 
                        value={value} 
                        onChange={handleTabChange} 
                        aria-label='service tabs'
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {isOrgAdmin ? (
                        tabs.map((tab, index) => (
                            value === index && <tab.component key={index} />
                        ))
                    ) : (
                        tabs.map((tab, index) => (
                            value === index && (
                                <PermissionGate
                                    key={index}
                                    featureCode={tab.featureCode}
                                    fallback={null}
                                >
                                    <tab.component />
                                </PermissionGate>
                            )
                        ))
                    )}
                </Box>
            </Box>

            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Agent(s)</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Agent Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={email && !validateEmail(email)}
                            helperText={email && !validateEmail(email) ? 'Invalid email format' : ''}
                        />
                        <Button
                            variant="contained"
                            onClick={handleAddEmail}
                            disabled={!validateEmail(email)}
                        >
                            Add Email
                        </Button>
                    </Box>
                    
                    {emailList.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Emails to add:
                            </Typography>
                            {emailList.map((email, index) => (
                                <Box 
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 1,
                                        borderBottom: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                    <Typography>{email}</Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => handleRemoveEmail(index)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={handleAddAgents}
                        disabled={emailList.length === 0}
                    >
                        Add Agents
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AgentSetup;
