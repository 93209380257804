import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/fetchOrganizationPublicDetails',
    async (_, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            let userInfo;
            try {
                userInfo = await authService.getUserInfo();
                if (!userInfo?.organization_id) {
                    throw new Error('Organization ID not found in user info');
                }
            } catch (error) {
                console.error('Error getting user info:', error);
                throw new Error('Unable to retrieve user information');
            }

            const response = await axios.get(`${API_BASE_URL}/organization-public-details?organization_id=${userInfo.organization_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const saveOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/saveOrganizationPublicDetails',
    async (details, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const response = await axios.post(`${API_BASE_URL}/organization-public-details`, details, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/updateOrganizationPublicDetails',
    async ({ id, details }, { rejectWithValue }) => {
        if (!details || Object.keys(details).length === 0) {
            return rejectWithValue('Public details data is required');
        }

        try {
            const token = await authService.ensureValidToken();
            const response = await axios.patch(`${API_BASE_URL}/organization-public-details?id=${id}`, details, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const organizationPublicDetailsSlice = createSlice({
    name: 'organizationPublicDetails',
    initialState: {
        details: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.details = [action.payload];
            })
            .addCase(fetchOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organization public details';
            })
            .addCase(saveOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.details.push(action.payload);
            })
            .addCase(saveOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to save organization public details';
            })
            .addCase(updateOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.details.findIndex(detail => detail.id === action.payload.id);
                if (index !== -1) {
                    state.details[index] = action.payload;
                }
            })
            .addCase(updateOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update organization public details';
            });
    },
});

export default organizationPublicDetailsSlice.reducer;
