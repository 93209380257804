import { useState, useEffect } from 'react';
import { useKeycloak } from '../contexts/KeycloakContext';

// Updated role constants to match exactly with Keycloak roles
const ROLES = {
    // From realm_roles
    MERCHANT: 'default-roles-eventlah',
    AGENT: 'eventlah-agent',
    // From organization_roles
    ADMIN: 'eventlah-admin',
    // From realm_roles
    PAYPROVIDER: 'payment-provider'
};

export const useUserInfo = () => {
    const { userInfo } = useKeycloak();
    const [roles, setRoles] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isMerchant, setIsMerchant] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isPaymentProvider, setIsPaymentProvider] = useState(false);

    useEffect(() => {
        if (userInfo) {
            // Get roles from both realm_roles and organization_roles
            const realmRoles = userInfo.realm_roles || [];
            const organizationRoles = userInfo.organization_roles || [];
            const resourceRoles = Object.values(userInfo.resource_access || {})
                .flatMap(resource => resource.roles || []);
            
            const allRoles = [...new Set([...realmRoles, ...organizationRoles, ...resourceRoles])];
            
            // Debug log all roles
            console.log('Role sources:', {
                realm: realmRoles,
                organization: organizationRoles,
                resource: resourceRoles,
                all: allRoles
            });
            
            setRoles(allRoles);
            
            // Check organization_roles for admin
            setIsAdmin(organizationRoles.includes(ROLES.ADMIN));
            // Check realm_roles for merchant and agent
            setIsMerchant(realmRoles.includes(ROLES.MERCHANT));
            setIsAgent(realmRoles.includes(ROLES.AGENT));
            setIsPaymentProvider(realmRoles.includes(ROLES.PAYPROVIDER));

            // Debug log role checks
            console.log('Role checks:', {
                admin: realmRoles.includes(ROLES.ADMIN),
                merchant: realmRoles.includes(ROLES.MERCHANT),
                agent: realmRoles.includes(ROLES.AGENT),
                paymentProvider: realmRoles.includes(ROLES.PAYPROVIDER),
                ROLES,
                allRoles
            });
        } else {
            // Reset state when userInfo is null
            setRoles([]);
            setIsAdmin(false);
            setIsMerchant(false);
            setIsAgent(false);
            setIsPaymentProvider(false);
        }
    }, [userInfo]);

    return {
        roles,
        isAdmin,
        isMerchant,
        isAgent,
        isPaymentProvider,
        userInfo,
        hasRole: (role) => roles.includes(role)
    };
};
