import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useKeycloak } from '../contexts/KeycloakContext';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useKeycloak();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        // Store the current URL to redirect back after login
        sessionStorage.setItem('redirectUrl', window.location.pathname);
        return <Navigate to="/login" replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;