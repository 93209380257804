import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { authService } from '../../../../services/auth.service';
import {
    Box,
    Button,
    Divider,
    Typography,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem, Link
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import ResetPassword from "./ResetPassword";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const UserProfileSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo, updateUserInfo } = useKeycloak();
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState({
        oldEmail: '',
        email: '',
        firstName: '',
        lastName: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [loading, setLoading] = useState(false);

    const isAdmin = userInfo?.realm_roles?.includes('eventlah-admin');

    // Get the store name from the Redux store
    const storeName = useSelector(state => state.organizationProfiles.profiles[0]?.data?.storeName || 'Merchant Store Name');

    useEffect(() => {
        if (userInfo) {
            setUserData({
                oldEmail: userInfo.email,
                email: userInfo.email,
                firstName: userInfo.given_name || '',
                lastName: userInfo.family_name || '',
            });
        }
    }, [userInfo]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await authService.updateUserProfile({
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
            });

            // Update the context with new user info
            await updateUserInfo();
            setIsEditing(false);
            setSnackbar({
                open: true,
                message: 'Profile updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            setSnackbar({
                open: true,
                message: error.message || 'Failed to update user profile',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        // Reset to original data
        if (userInfo) {
            setUserData({
                email: userInfo.email || '',
                firstName: userInfo.given_name || '',
                lastName: userInfo.family_name || '',
            });
        }
    };

    const handleOpenResetDialog = () => {
        setOpenResetDialog(true);
    };

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const renderField = (label, field, required = false, options = null) => (
        <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {isEditing ? (
                    options ? (
                        <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                            <InputLabel id={`${field}-select-label`}>Select {label}</InputLabel>
                            <Select
                                labelId={`${field}-select-label`}
                                id={`${field}-select`}
                                name={field}
                                value={userData[field] || ''}
                                onChange={handleInputChange}
                                required={required}
                                label={`Select ${label}`}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            name={field}
                            value={userData[field] || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label={`Enter ${label}`}
                            required={required}
                            sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        />
                    )
                ) : (
                    <Typography variant='body1"'>{userData[field] || 'N/A'}</Typography>
                )}
            </Box>
            <Divider />
        </>
    );

    return(
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
            <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
                <Typography variant="body2">
                    Please note: Changing your email address will also update your login credentials. You will need to use your new email to sign in after saving these changes.
                </Typography>
            </Alert>
            <Box sx={{display:'flex', justifyContent: 'space-between', marginBottom: 2}}>
                <Typography variant="h6">
                    {isAdmin ? 'Admin Profile' : 'User Profile'}
                </Typography>
                {isEditing ? (
                    <Box>
                        <Button variant='cancel' onClick={handleCancel} sx={{height:'32px', marginRight: 1}}>
                            Cancel
                        </Button>
                        <Button variant='save' type="submit" sx={{height:'32px'}} disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                ) : (
                    <Button variant='cancel' onClick={handleEditClick} sx={{height:'32px'}}>
                        <EditNoteIcon/> Edit
                    </Button>
                )}
            </Box>

            <Box>
                <Typography variant="h5">
                    {isAdmin ? 'Admin Dashboard' : storeName}
                </Typography>
                <Divider />
                {renderField('Email', 'email', true)}
                {renderField('First Name', 'firstName', true)}
                {renderField('Last Name', 'lastName', true)}

                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle variant="subtitle2">
                        Password
                    </TextFieldTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ marginRight: 3, mb:1 }}>••••••••</Typography>
                        <Link
                            component="button"
                            onClick={handleOpenResetDialog}
                            sx={{
                                textDecoration: 'none',
                                color: 'orange',
                                fontSize: '14px',
                                '&:hover': {
                                    textDecoration: 'underline',
                                }
                            }}
                        >
                            <Typography variant="button">Reset Password</Typography>
                        </Link>
                    </Box>
                </Box>
                <Divider />
            </Box>

            <ResetPassword open={openResetDialog} onClose={handleCloseResetDialog} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <Typography variant="body2">{snackbar.message}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UserProfileSettings;