import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import ConfirmationEmail from './BrandingContents/ConfirmationEmail';
import TicketTab from './BrandingContents/TicketTab';
import InvoiceTab from './BrandingContents/InvoiceTab';
import ReceiptTab from './BrandingContents/ReceiptTab';
import CheckoutTab from './BrandingContents/CheckoutTab';
import PermissionGate from 'components/shared/PermissionGate';
import { useOrganizationRoles } from 'components/shared/useOrganizationRoles';

const Branding = () => {
    const [value, setValue] = useState(0);
    const organizationRoles = useOrganizationRoles();
    const isOrgAdmin = organizationRoles?.includes('org_admin');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { label: 'Ticket', featureCode: 'BRAN_TICK', component: TicketTab },
        { label: 'Email', featureCode: 'BRAN_CREA', component: ConfirmationEmail },
        { label: 'Invoice', featureCode: 'BRAN_CREA1', component: InvoiceTab },
        { label: 'Receipt', featureCode: 'BRAN_CREA2', component: ReceiptTab },
        { label: 'Checkout', featureCode: 'BRAN_CREA3', component: CheckoutTab }
    ];

    return (
        <Box
            display='flex'
            flexDirection='column'
            sx={{ width: '100%', padding: { xs: 1, md: 3 } }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'flex-start',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Branding</Typography>
                        <Typography
                            variant='h5'
                            sx={{ marginTop: { xs: 1, md: 2 } }}
                        >
                            Personalize Your Presence with Custom Templates
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            display: 'flex',
                            gap: '12px',
                            mt: { xs: 2, md: 0 },
                        }}
                    >
                        {/* Button or actions here */}
                    </Box>
                </Box>

                <Box
                    sx={{
                        height: '38px',
                        paddingTop: { xs: '16px', md: '32px' },
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label='service tabs'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>

                <Divider
                    sx={{ margin: { xs: '24px 0', md: '41px 0 32px 0' } }}
                />

                <Box>
                    {isOrgAdmin ? (
                        tabs.map((tab, index) => (
                            value === index && <tab.component key={index} />
                        ))
                    ) : (
                        tabs.map((tab, index) => (
                            value === index && (
                                <PermissionGate
                                    key={index}
                                    featureCode={tab.featureCode}
                                    fallback={null}
                                >
                                    <tab.component />
                                </PermissionGate>
                            )
                        ))
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Branding;
