import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrganizationProfiles } from '../redux/organizationProfilesSlice';
import { Link } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Chip,
    Button,
    drawerClasses,
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Receipt as ReceiptIcon,
    ExitToApp as ExitToAppIcon,
    ExpandLess,
    ExpandMore,
    AdminPanelSettings as AdminIcon,
    Storefront as StorefrontIcon,
    Assignment as AssignmentIcon,
    EventNote as EventNoteIcon,
    CalendarToday as CalendarTodayIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Payments as PaymentsIcon,
    AttachMoney as AttachMoneyIcon,
    Check as CheckIcon,
    RoomPreferences as RoomPreferencesIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
    Settings as SettingsIcon,
    MonetizationOn as MonetizationOnIcon,
} from '@mui/icons-material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PublicIcon from "@mui/icons-material/Public";
import { useOrganizationRoles } from "./shared/useOrganizationRoles";
import { useKeycloak } from '../contexts/KeycloakContext';
import { useUserInfo } from '../hooks/useUserInfo';

// Constants remain the same
const DRAWER_WIDTH = 250;
const ROLES = {
    MERCHANT: 'default-roles-eventlah',
    ADMIN: 'eventlah-admin',
    AGENT: 'eventlah-agent',
    PAYPROVIDER: 'payment-provider'
};

// Styled Components
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
    },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '20px',
    },
    minWidth: '40px',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[500],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '10px 16px',
            '& .MuiListItemIcon-root': {
                minWidth: 24,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                    ),
                },
            },
        },
    },
}));

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { logout, keycloak } = useKeycloak();
    const { userInfo, roles, isMerchant, isAdmin, isAgent, isPaymentProvider } = useUserInfo();
    const dispatch = useDispatch();

    // Fetch organization data on mount
    useEffect(() => {
        if (keycloak?.tokenParsed?.organization_id) {
            const organizationId = keycloak.tokenParsed.organization_id;
            dispatch(fetchOrganizationProfiles(organizationId));
        }
    }, [dispatch, keycloak]);
    
    // Debug logging
    console.log('Sidebar render:', {
        userInfo,
        roles,
        isMerchant,
        isAdmin,
        isAgent,
        isPaymentProvider
    });

    const storeName = useSelector(state =>
        state.organizationProfiles?.profiles[0]?.data?.name || userInfo?.organization?.[0] || 'Merchant Store Name'
    );

    // Build available roles array
    const availableRoles = [
        ...(isMerchant ? [ROLES.MERCHANT] : []),
        ...(isAdmin ? [ROLES.ADMIN] : []),
        ...(isAgent ? [ROLES.AGENT] : []),
        ...(isPaymentProvider ? [ROLES.PAYPROVIDER] : [])
    ];

    console.log('Available roles:', availableRoles);

    // Default to merchant role if available, otherwise use first available role
    const [currentRole, setCurrentRole] = useState(() => {
        const defaultRole = isMerchant ? ROLES.MERCHANT : availableRoles[0];
        console.log('Setting default role:', defaultRole);
        return defaultRole;
    });

    // Update current role if it becomes invalid
    useEffect(() => {
        if (availableRoles.length > 0 && !availableRoles.includes(currentRole)) {
            const newRole = isMerchant ? ROLES.MERCHANT : availableRoles[0];
            console.log('Updating invalid role from', currentRole, 'to', newRole);
            setCurrentRole(newRole);
        }
    }, [availableRoles, currentRole, isMerchant]);

    const handleLogout = () => {
        logout();
    };

    const handleRoleChange = (newRole) => {
        console.log('Role change:', { from: currentRole, to: newRole });
        setCurrentRole(newRole);
    };

    const renderView = () => {
        if (!userInfo || availableRoles.length === 0) {
            console.log('Cannot render view:', { hasUserInfo: !!userInfo, availableRoles });
            return null;
        }

        console.log('Rendering view for role:', currentRole);

        switch (currentRole) {
            case ROLES.MERCHANT:
                return (
                    <MerchantView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                        organizationRoles={roles}
                    />
                );
            case ROLES.ADMIN:
                return (
                    <AdminView
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            case ROLES.AGENT:
                return (
                    <AgentView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            case ROLES.PAYPROVIDER:
                return (
                    <PaymentView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            default:
                console.log('No matching view for role:', currentRole);
                return null;
        }
    };

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: isOpen ? DRAWER_WIDTH : 0,
                flexShrink: 0,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                    backgroundColor: 'background.paper',
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {renderView()}
                <ListItem button onClick={handleLogout}>
                    <CustomListItemIcon>
                        <ExitToAppIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

// Helper Components
const LogoPlaceholder = ({ width = 32, height = 32, logoUrl }) => {
    if (logoUrl) {
        return (
            <div className="flex items-center justify-center" style={{ width, height }}>
                <img 
                    src={logoUrl}
                    alt="Organization logo"
                    style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px'
                    }}
                />
            </div>
        );
    }
    
    return (
        <div className="flex items-center justify-center" style={{ width, height }}>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full rounded-md"
            >
                <rect width={width} height={height} fill="#f0f0f0" className="rounded-md" />
                <text
                    x="50%"
                    y="50%"
                    fontSize={width * 0.25}
                    fill="#888"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontFamily="system-ui"
                >
                    LOGO
                </text>
            </svg>
        </div>
    );
};

const getRoleDisplay = (role) => {
    switch (role) {
        case ROLES.MERCHANT:
            return 'Merchant';
        case ROLES.ADMIN:
            return 'Admin';
        case ROLES.AGENT:
            return 'Agent';
        case ROLES.PAYPROVIDER:
            return 'Payment Provider';
        default:
            return role;
    }
};

// Navigation Config
const MERCHANT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
    { text: 'Landing Pages', icon: <PublicIcon />, link: 'https://pages.eventlah.com' },
    // {
    //     text: 'Events',
    //     icon: <EventNoteIcon />,
    //     link: '/events',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Subscriptions',
    //     icon: <AssignmentIcon />,
    //     link: '/subscriptions',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Transactions',
    //     icon: <ReceiptIcon />,
    //     link: '/transactions',
    //     requiredRoles: ['merchants']
    // },
    // {
    //     text: 'Settings',
    //     icon: <SettingsIcon />,
    //     link: '/settings',
    //     requiredRoles: ['merchants']
    // }
    // { text: 'Revenue Overview', icon: <MonetizationOnIcon />, link: '/revenue' },
    { text: 'Transactions', icon: <AccountBalanceWalletIcon />, link: '/settings/transaction-history' },
    // { text: 'Products', icon: <StorefrontIcon />, link: '/settings/product-catalog', requiredRoles: ['org_admin']  },
    // { text: 'Orders', icon: <AssignmentIcon />, link: '/order-overview' },
    { text: 'Ticketing', icon: <EventNoteIcon />, link: '/ticketing-overview' },
    // { text: 'Bookings', icon: <RoomPreferencesIcon />, link: '/booking-overview' },
    // { text: 'Appointments', icon: <CalendarTodayIcon />, link: '/settings/appointments' },
    { text: 'Agents', icon: <PeopleIcon />, link: '/agents-overview' },
    { text: 'Billings', icon: <ReceiptIcon />, link: '/settings/account-status#billing' },
    { text: 'Customers', icon: <PeopleIcon />, link: '/settings/customer-records' }
];

const AGENT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/agent-dashboard' },
    { text: 'Profile', icon: <PersonAddAlt1Icon />, link: '/agent-profile' },
    { text: 'Commissions', icon: <AttachMoneyIcon />, link: '/agent-commissions' },
    { text: 'Payouts', icon: <PaymentsIcon />, link: '/agent-payouts' },
    { text: 'Materials', icon: <AssignmentIcon />, link: '/agent-materials' }
];

const ADMIN_SUB_MENU_ITEMS = [
    { text: 'Dashboard', link: '/admin/dashboard' },
    { text: 'Merchants', link: '/admin/merchants' },
    { text: 'Agents', link: '/admin/agents' },
    { text: 'Transactions', link: '/admin/transactions' },
    { text: 'Settings', link: '/admin/settings' },
];

// Component Definition
const AccountHeader = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRoleSelect = (role) => {
        onRoleChange(role);
        handleClose();
    };

    const renderAccountStatus = (status) => {
        let color;
        let icon;
        let label;

        switch (status) {
            case 'verified':
                color = 'success';
                icon = <CheckCircleIcon />;
                label = 'Verified';
                break;
            case 'pending':
                color = 'warning';
                icon = <HelpOutlineIcon />;
                label = 'Pending';
                break;
            case 'suspended':
                color = 'error';
                icon = <HelpOutlineIcon />;
                label = 'Suspended';
                break;
            default:
                color = 'default';
                icon = <HelpOutlineIcon />;
                label = 'Unknown';
        }

        return (
            <Chip
                icon={icon}
                label={label}
                color={color}
                size="small"
                sx={{ fontSize: 10, fontWeight: 'bold' }}
            />
        );
    };

    return (
        <ListItem>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                mt: 1, // Reduced from 2
            }}>
                <Box sx={{
                    width: 40, // Reduced from 50
                    height: 40, // Reduced from 50
                    borderRadius: '4px', // Reduced from 6px
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    mr: 1.5, // Reduced from 2
                }}>
                    <LogoPlaceholder 
                        width={40} 
                        height={40} 
                        logoUrl={useSelector(state => state.organizationProfiles?.profiles[0]?.data?.logo)}
                    />
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{storeName}</Typography> {/* Reduced from 16px */}
                    </Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px' }}> {/* Reduced from 14px */}
                        Role: {getRoleDisplay(currentRole)}
                    </Typography>
                    {renderAccountStatus('verified')}
                </Box>

                {availableRoles.length > 1 && (
                    <Box sx={{ my: "auto" }}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.2s ease-in-out',
                                padding: '4px', // Reduced padding
                            }}
                            aria-controls={open ? 'role-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <KeyboardArrowDownIcon fontSize="small" /> {/* Added fontSize="small" */}
                        </IconButton>
                    </Box>
                )}
            </Box>

            <StyledMenu
                id="role-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'role-button' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={2}
            >
                {availableRoles.map((role) => (
                    <StyledMenuItem
                        key={role}
                        onClick={() => handleRoleSelect(role)}
                        selected={currentRole === role}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: 1.5, // Reduced from 2
                        }}
                    >
                        <Box sx={{
                            width: 32, // Reduced from 40
                            height: 32, // Reduced from 40
                            borderRadius: '4px', // Reduced from 6px
                            backgroundColor: '#f0f0f0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            mr: 1.5, // Reduced from 2
                        }}>
                            <LogoPlaceholder 
                                width={32} 
                                height={32}
                                logoUrl={useSelector(state => state.organizationProfiles?.profiles[0]?.data?.logo)}
                            />
                        </Box>

                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>{storeName}</Typography> {/* Reduced from 14px */}
                            <Typography sx={{ fontWeight: '500', fontSize: '10px', mt: '2px' }}> {/* Reduced from 12px */}
                                Role: {getRoleDisplay(role)}
                            </Typography>
                        </Box>

                        {currentRole === role && (
                            <CustomListItemIcon sx={{ minWidth: 'auto', marginLeft: 0.5 }}> {/* Reduced marginLeft */}
                                <CheckIcon color="primary" fontSize="small" />
                            </CustomListItemIcon>
                        )}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </ListItem>
    );
};

const NavigationItem = ({ text, icon, link }) => {
    if (text === 'Landing Pages') {
        return (
            <ListItem
                button
                component="a"
                href={link}
            >
                <CustomListItemIcon>{icon}</CustomListItemIcon>
                <CustomListItemText primary={text} />
            </ListItem>
        );
    }
    return (
        <ListItem
            button
            component={Link}
            to={link}
        >
            <CustomListItemIcon>{icon}</CustomListItemIcon>
            <CustomListItemText primary={text} />
        </ListItem>
    );
};

const MerchantView = ({ storeName, currentRole, availableRoles, onRoleChange, organizationRoles }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    // Filter menu items based on required roles
    const filteredMenuItems = MERCHANT_MENU_ITEMS.filter(item => {
        if (!item.requiredRoles) return true;
        return item.requiredRoles.some(role => organizationRoles.includes(role));
    });

    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {filteredMenuItems.map((item, index) => (
                    <NavigationItem
                        key={index}
                        text={item.text}
                        icon={item.icon}
                        link={item.link}
                    />
                ))}
            </List>
        </>
    );
};

const AgentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {AGENT_MENU_ITEMS.map((item, index) => (
                    <NavigationItem
                        key={index}
                        text={item.text}
                        icon={item.icon}
                        link={item.link}
                    />
                ))}
            </List>
        </>
    );
};

const AdminView = ({ currentRole, availableRoles, onRoleChange }) => {
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);

    const handleAdminMenuClick = () => {
        setAdminMenuOpen(!adminMenuOpen);
    };

    return (
        <>
            <AccountHeader
                storeName="Admin Panel"
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                <ListItem button onClick={handleAdminMenuClick}>
                    <CustomListItemIcon>
                        <AdminIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Admin" />
                    {adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {ADMIN_SUB_MENU_ITEMS.map((item, index) => (
                            <ListItem
                                key={index}
                                button
                                component={Link}
                                to={item.link}
                                sx={{ pl: 4 }}
                            >
                                <CustomListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </List>
        </>
    );
};

const PaymentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    return (
        <>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                <ListItem button component={Link} to="/payment/dashboard">
                    <CustomListItemIcon>
                        <DashboardIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Dashboard" />
                </ListItem>
            </List>
        </>
    );
};

export default Sidebar;
