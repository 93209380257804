import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Paper, Typography, Stepper, Step, StepLabel, CircularProgress, Alert } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FirstStepForm from "./FirstStepForm";
import { useFormContext } from '../context/FormContext';
import { setCurrentStep } from "../../redux/accountOnboardingSlice";
import { useDispatch } from "react-redux";
import { useKeycloak } from '../../contexts/KeycloakContext';
import { authService } from '../../services/auth.service';
import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const malaysiaStates = [
    'Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan',
    'Pahang', 'Penang', 'Perak', 'Perlis', 'Sabah',
    'Sarawak', 'Selangor', 'Terengganu', 'Kuala Lumpur'
];

class FirstStepErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('FirstStep Error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box sx={{ p: 3 }}>
                    <Alert severity="error">
                        Something went wrong. Please try refreshing the page.
                        {this.state.error && (
                            <Typography variant="caption" display="block">
                                Error: {this.state.error.message}
                            </Typography>
                        )}
                    </Alert>
                </Box>
            );
        }

        return this.props.children;
    }
}

const FirstStep = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [organizationData, setOrganizationData] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { formData, updateFormData } = useFormContext();
    const { isAuthenticated, userInfo, updateUserInfo } = useKeycloak();
    const organizationId = useMemo(() => userInfo?.organization_id, [userInfo]);

    const updateFormFields = useCallback((orgData) => {
        if (!orgData) return;
        
        const validState = malaysiaStates.includes(orgData.company_state) 
            ? orgData.company_state 
            : '';
        
        const updates = {
            companyName: orgData.name,
            registrationNo: orgData.registration_number,
            addressLine1: orgData.company_address_1,
            addressLine2: orgData.company_address_2,
            city: orgData.company_city,
            postcode: orgData.company_postcode,
            state: validState,
            country: orgData.company_country === 'USA' ? 'Malaysia' : orgData.company_country
        };

        Object.entries(updates).forEach(([key, value]) => {
            if (value !== undefined) {
                updateFormData(key, value || '');
            }
        });
    }, [updateFormData]);

    const fetchOrganizationData = useCallback(async () => {
        if (!organizationId || !isAuthenticated || !authService.getAccessToken()) {
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${BASE_API_URL}/organizations?id=${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`
                }
            });
            
            if (!response.data?.data) {
                throw new Error('Invalid response format');
            }

            const orgData = response.data.data;
            setOrganizationData(orgData);
            updateFormFields(orgData);
        } catch (error) {
            console.error('Failed to fetch organization data:', error);
            setError('Failed to load organization data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [organizationId, isAuthenticated, updateFormFields]);

    useEffect(() => {
        let isMounted = true;
        
        const initializeData = async () => {
            if (!isMounted) return;
            
            if (organizationId && !organizationData.id && isAuthenticated) {
                await fetchOrganizationData();
            } else {
                setLoading(false);
            }
        };

        initializeData();

        return () => {
            isMounted = false;
        };
    }, [organizationId, isAuthenticated, fetchOrganizationData, organizationData.id]);

    const handleFormSuccess = useCallback(async (updatedData) => {
        try {
            setError(null);
            
            // Format the data to match API expectations
            const apiData = {
                name: updatedData.companyName,
                registration_number: updatedData.registrationNo,
                company_address_1: updatedData.addressLine1,
                company_address_2: updatedData.addressLine2,
                company_city: updatedData.city,
                company_postcode: updatedData.postcode,
                company_state: updatedData.state,
                company_country: updatedData.country,
                onboarding_progress: 1
            };

            const config = {
                headers: {
                    'Authorization': `Bearer ${authService.getAccessToken()}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = organizationId
                ? await axios.put(`${BASE_API_URL}/organizations?id=${organizationId}`, apiData, config)
                : await axios.post(`${BASE_API_URL}/organizations`, apiData, config);

            if (!response.data?.data) {
                throw new Error('Invalid response from server');
            }

            const { organization, onboarding } = response.data.data;

            // Update form fields with organization data
            updateFormFields(organization);
            
            // Update Redux store with onboarding data and proceed to next step
            if (onboarding) {
                await dispatch(setCurrentStep(onboarding.current_step));
                setActiveStep((prevStep) => prevStep + 1);
                navigate('/second');
            }

            // Update user info to get new organization_id
            if (!organizationId) {
                await updateUserInfo();
            }
        } catch (error) {
            console.error('Failed to update organization:', error);
            let errorMessage = 'Failed to save organization data. Please try again.';

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = 'Invalid organization data. Please check your input and try again.';
                        if (error.response.data?.error?.details) {
                            errorMessage += ' ' + error.response.data.error.details
                                .map(d => `${d.field}: ${d.message}`)
                                .join(', ');
                        }
                        break;
                    case 401:
                        errorMessage = 'Your session has expired. Please log in again.';
                        break;
                    case 403:
                        errorMessage = 'You do not have permission to update this organization.';
                        break;
                    case 404:
                        errorMessage = 'Organization not found. Please refresh the page and try again.';
                        break;
                    default:
                        errorMessage = error.response.data?.description || errorMessage;
                }
            } else if (error.request) {
                errorMessage = 'Network error. Please check your connection and try again.';
            }

            setError(errorMessage);
            throw error;
        }
    }, [organizationId, updateFormFields, dispatch, navigate, updateUserInfo]);

    if (!isAuthenticated) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                <Typography>Please log in to continue.</Typography>
            </Box>
        );
    }

    return (
        <FirstStepErrorBoundary>
            <Box sx={{ width: '100%', p: { xs: 2, md: 3 } }}>
                <Paper elevation={3} sx={{ p: { xs: 2, md: 3 } }}>
                    <Typography variant="h4" gutterBottom>
                        Organization Details
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
                    ) : (
                        <FirstStepForm
                            organizationData={organizationData}
                            setOrganizationData={setOrganizationData}
                            requiredFields={['companyName', 'registrationNo', 'addressLine1', 'city', 'postcode', 'state']}
                            onSave={handleFormSuccess}
                        />
                    )}
                </Paper>
            </Box>
        </FirstStepErrorBoundary>
    );
};

export default React.memo(FirstStep);
