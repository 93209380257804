import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Container, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '../../theme/theme-provider';
import { createTheme } from '../../theme/create-theme';
import { registerAgent } from '../../api/auth';

const AgentRegistrations = () => {
  const theme = createTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    agentId: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.agentId) newErrors.agentId = 'Agent ID is required';
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await registerAgent({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          companyName: formData.companyName,
          agentId: formData.agentId
        });

        if (!response.error) {
          navigate('/email-verification', { state: { email: formData.email } });
        } else {
          setErrors({ submit: response.error || 'Registration failed' });
        }
      } catch (error) {
        setErrors({ submit: error.message || 'Registration failed' });
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100vh', width: '100vw', display: 'flex', bgcolor: 'background.default' }}>
        <Container maxWidth="100%" sx={{ padding: 0, margin: 0 }}>
          <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
            <Box sx={{ p: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src="/fantet-logo.png" alt="Fantet Logo" style={{ height: '50px' }} />
              </Box>
              <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 4 }}>
                Agent Registration
              </Typography>
              
              {errors.submit && <Alert severity="error" sx={{ mb: 4 }}>{errors.submit}</Alert>}
              
              <form onSubmit={handleSubmit} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                <TextField
                  fullWidth
                  label="Company Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Agent ID"
                  name="agentId"
                  value={formData.agentId}
                  onChange={handleChange}
                  error={!!errors.agentId}
                  helperText={errors.agentId}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  sx={{ mb: 2 }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ bgcolor: '#ffed00', color: 'black', '&:hover': { bgcolor: '#ffed00' }, mb: 2 }}
                >
                  Register Agent
                </Button>

                <Box sx={{ textAlign: 'center' }}>
                  <Typography color="textSecondary">
                    Already have an account?{' '}
                    <Link to="/login" style={{ color: '#ffb800', textDecoration: 'none' }}>
                      Agent Login
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Box>

            <Box sx={{
              display: { xs: 'none', md: 'flex' },
              bgcolor: '#f5f5f5',
              backgroundImage: 'url("/bg.png")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              position: 'relative'
            }}>
              {/* Existing image section content */}
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AgentRegistrations;
