import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Skeleton,
    Chip,
    Grid,
    useTheme,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import { Label } from '../../material-kit/label';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import keycloak from '../../../keycloak';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventIcon from '@mui/icons-material/Event';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AnalyticsWidgetSummary from '../../shared/AnalyticsWidgetSummary';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CancelIcon from '@mui/icons-material/Cancel';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SearchIcon from '@mui/icons-material/Search';
import BookingDetailsModal from '../../overview/TicketingOverview/actions/BookingDetailsModal';
import BookingList from '../../overview/TicketingOverview/actions/BookingList';
import ChangeDateDialog from '../../overview/TicketingOverview/actions/ChangeDate';
import GetBooking from '../../overview/TicketingOverview/actions/GetBooking';
import PurchaseDetailsModal from '../../overview/Purchases/PurchaseDetailsModal';
import PermissionGate from 'components/shared/PermissionGate';

const TransactionHistory = () => {
    const theme = useTheme();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [organizationId, setOrganizationId] = useState(1);
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [error, setError] = useState(null);
    // const transactionsData = useSelector((state) => state.transactions.transactions);
    const [transactionsData, setTransactionsData] = useState([]);
    const { pagination, status } = useSelector(state => state.transactions);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] = useState(false);
    const [monthlyTransactions, setMonthlyTransactions] = useState(0);
    const [todayTotalRevenue, setTodayTotalRevenue] = useState(0);

    const fetchTransactionsData = async () => {
        setIsInitialLoad(true);
        setError(null);
        try {
            const response = await axios.get(`${API_BASE_URL}/purchases`, {
                params: {
                    page: paginationModel.page + 1,
                    pageSize: paginationModel.pageSize,
                    search: searchQuery,
                    organization_id: organizationId,
                    timeframe: 'current',
                },
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });

            if (response.data.code === 200 && response.data.data && Array.isArray(response.data.data.purchases)) {
                const mappedData = response.data.data.purchases.map(purchase => ({
                    id: purchase.id,
                    created_at: purchase.created_at,
                    invoice_number: purchase.invoice_number.trim(),
                    total_amount: purchase.total_amount,
                    status_id: purchase.status_id,
                    customer_name: `${purchase.customer?.first_name || ''} ${
                        purchase.customer?.last_name || ''
                    }`.trim(),
                    agent_name: purchase.agent
                        ? `${purchase.agent.first_name || ''} ${purchase.agent.last_name || ''}`.trim()
                        : 'N/A',
                    purchase_types: purchase.types.map(type => type.type_id).join(', '),
                    sales_channel: purchase.agent ? 'Agent' : 'Direct',
                    net_sales: purchase.net_sales || purchase.total_amount,
                }));
                setTransactionsData(mappedData);
                setTotalCount(response.data.data.pagination.totalCount);
            } else {
                throw new Error(response.data.message || 'Invalid data structure received from the server');
            }
        } catch (err) {
            setError('Failed to fetch transactions data: ' + (err.message || 'Unknown error'));
            console.error('Error fetching transactions data:', err);
            setTransactionsData([]);
        } finally {
            setIsInitialLoad(false);
        }
    };

    useEffect(() => {
        fetchTransactionsData();
    }, [paginationModel, searchQuery, organizationId]);

    useEffect(() => {
        console.log('Raw transactionsData:', transactionsData);
    }, [transactionsData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const transactionsResponse = await axios.get(`${API_BASE_URL}/purchases?purchase_type=4&status_id=3`);
                setMonthlyTransactions(transactionsResponse.data.data.total || 0);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchTotalRevenue = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/purchases`, {
                    params: {
                        today_total_revenue: true,
                        purchase_type: 4,
                        status_id: 3,
                    },
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                    },
                });
                setTodayTotalRevenue(response.data.data.todayTotalRevenue || 0);
            } catch (error) {
                console.error('Error fetching total revenue:', error);
            }
        };
        fetchTotalRevenue();
    }, []);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const handleConfirm = () => {
        setOpen(false);
        setOpenPaymentSuccessDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{ padding: '0 32px 24px 32px' }}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant='rectangular' width={60} height={40} sx={{ mr: 5, borderRadius: '4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant='text' width='100%' />
                        <Skeleton variant='text' width='100%' />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const rows = useMemo(() => {
        // console.log('Transforming transactionsData:', transactionsData);
        return (
            transactionsData?.map(transaction => ({
                ...transaction,
            })) || []
        );
    }, [transactionsData]);

    console.log('Transformed rows:', rows);

    const typeConfig = {
        1: { label: 'Ticket', color: 'primary' },
        2: { label: 'Booking', color: 'success' },
        3: { label: 'Product', color: 'warning' },
        4: { label: 'Mixed', color: 'secondary' },
    };

    const statusConfig = {
        1: { label: 'Processing', color: 'info' },
        4: { label: 'Processing', color: 'info' },
        // 1: { label: 'Pending', color: 'warning' },
        // 2: { label: 'Processing', color: 'info' },
        // 3: { label: 'Completed', color: 'success' },
        // 4: { label: 'Cancelled', color: 'error' },
    };

    const renderLabels = React.useCallback((items, configMap) => {
        if (!items || items.length === 0) {
            return (
                <Typography variant='caption' color='text.secondary'>
                    No data
                </Typography>
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 0.5,
                    height: '100%',
                    width: '100%',
                }}
            >
                {items.map(item => {
                    const config = configMap[item] || { label: `Unknown (${item})`, color: 'default' };
                    return (
                        <Label
                            key={item}
                            variant='soft'
                            color={config.color}
                            sx={{
                                textTransform: 'capitalize',
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '0.75rem', // Explicitly set font size
                                py: 0.5, // Add some vertical padding
                            }}
                        >
                            {config.label}
                        </Label>
                    );
                })}
            </Box>
        );
    }, []);

    const renderPurchaseTypes = React.useCallback(
        params => {
            const types = params.value ? params.value.split(', ').map(Number) : [];
            // return renderLabels(types, typeConfig);
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {renderLabels(types, typeConfig)}
                </Box>
            );
        },
        [renderLabels]
    );

    const renderStatus = React.useCallback(
        params => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {renderLabels([params.value], statusConfig)}
                </Box>
            );
            // return renderLabels([params.value], statusConfig);
        },
        [renderLabels]
    );

    const columns = [
        {
            field: 'invoice_number',
            headerName: 'Invoice Number',
            flex: 0.5,
            minWidth: 150,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'status_id',
            headerName: 'Status',
            flex: 0.3,
            minWidth: 120,
            renderCell: renderStatus,
        },
        {
            field: 'created_at',
            headerName: 'Purchase Date',
            flex: 0.3,
            minWidth: 100,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>{new Date(params.value).toLocaleDateString()}</Typography>
                </Box>
            ),
        },
        {
            field: 'customer_name',
            headerName: 'Customer Name',
            flex: 1,
            minWidth: 100,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'sales_channel',
            headerName: 'Sales Channel',
            flex: 0.5,
            minWidth: 120,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>
                        {params.row.sales_channel === 'Agent' ? (
                            <Box component='span' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                Agent: {params.row.agent_name}
                            </Box>
                        ) : (
                            'Direct'
                        )}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'total_amount',
            headerName: 'Total Amount',
            flex: 0.7,
            minWidth: 120,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>RM{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'net_sales',
            headerName: 'Net Sales',
            flex: 0.7,
            minWidth: 120,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography variant='body2'>RM{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 180,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        paddingLeft: '8px',
                    }}
                >
                    <PurchaseDetailsModal id={params.row.id} />
                </Box>
            ),
        },
    ];

    return (
        <PermissionGate featureCode='READ2' fallback={null}>
            <Box>
                <Box flex={1}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                            width: '100%',
                        }}
                    >
                        <Box>
                            <Typography variant='h4'>Transaction History</Typography>
                            <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                                View your transaction history and details.
                            </Typography>
                        </Box>
                        {/*<Box sx={{ ml: 'auto', mr:'32px', display: 'flex', alignItems: 'center', gap:1 }}>*/}
                        {/*    <Button variant='save' startIcon={<AddIcon />}>*/}
                        {/*        Create payment*/}
                        {/*    </Button>*/}
                        {/*    <Button variant='cancel' onClick={() => setOpen(true)}>*/}
                        {/*        Verify this Order*/}
                        {/*    </Button>*/}
                        {/*</Box>*/}
                    </Box>

                    {/* Analytics Graphs */}
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MONT1' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Monthly Transactions'
                                    percent={3.2}
                                    total={monthlyTransactions}
                                    icon={<CreditCardIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MONT1' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title="Today's Revenue"
                                    percent={4.5}
                                    total={todayTotalRevenue}
                                    color='secondary'
                                    icon={<AttachMoneyIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MONT1' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Average Order Value'
                                    percent={1.8}
                                    total={542}
                                    color='warning'
                                    icon={<ReceiptIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [400, 250, 480, 320, 500, 380, 450, 542],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='FAIL' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Failed Transactions'
                                    percent={-0.5}
                                    total={124}
                                    color='error'
                                    icon={<CancelIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [220, 280, 180, 250, 160, 200, 140, 124],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='flex-end' alignItems='center' mb={2}>
                        <Box />
                        <Box display='flex' gap='12px'>
                            {/*OLD*/}
                            {/*<TextField*/}
                            {/*    variant="outlined"*/}
                            {/*    placeholder="Search by Description"*/}
                            {/*    value={searchQuery}*/}
                            {/*    onChange={handleSearchChange}*/}
                            {/*    size="small"*/}
                            {/*    sx={{*/}
                            {/*        '& .MuiOutlinedInput-root': {*/}
                            {/*            height: '34px',*/}
                            {/*            '& input': {*/}
                            {/*                height: '34px',*/}
                            {/*                padding: '0 14px',*/}
                            {/*            },*/}
                            {/*            '& .MuiInputBase-input': {*/}
                            {/*                height: '34px',*/}
                            {/*                padding: '0 14px',*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <OutlinedInput
                                fullWidth
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                placeholder='Search transactions...'
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <SearchIcon sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                }
                                sx={{ maxWidth: 320, mb: 2 }}
                            />
                        </Box>
                    </Box>

                    <Paper
                        sx={{
                            width: '100%',
                            borderRadius: '16px',
                            marginTop: '24px',
                            border: '1px solid var(--Gray-200, #EAECF0)',
                            background: 'var(--White, #FFF)',
                            boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Gray-900, #101828)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '28px',
                                padding: '20px 24px',
                            }}
                        >
                            All Transactions
                        </Typography>

                        <Divider sx={{ my: 0 }} />

                        {isInitialLoad || status === 'loading' ? (
                            renderSkeletons()
                        ) : (
                            // In your component
                            // <StyledDataGrid
                            //     rows={rows}
                            //     columns={columns}
                            //     paginationModel={paginationModel}
                            //     onPaginationModelChange={handlePaginationModelChange}
                            //     rowCount={totalCount}
                            //     loading={status === 'loading'}
                            // />
                            <DataGrid
                                rows={rows || []}
                                columns={columns}
                                paginationModel={paginationModel}
                                onPaginationModelChange={handlePaginationModelChange}
                                rowCount={totalCount}
                                loading={status === 'loading'}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                            />
                        )}
                    </Paper>
                </Box>

                {/* Dialog for Verification */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Verify Order</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to verify this order?</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ mb: 1 }}>
                        <Button variant='save' onClick={handleConfirm}>
                            Confirm
                        </Button>
                        <Button variant='cancel' onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Payment Success Dialog */}
                <Dialog
                    open={openPaymentSuccessDialog}
                    onClose={() => setOpenPaymentSuccessDialog(false)}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogContent
                        sx={{
                            textAlign: 'center',
                            padding: { xs: '24px', sm: '32px' },
                        }}
                    >
                        <CheckCircleIcon color='success' sx={{ fontSize: { xs: 40, sm: 60 } }} />
                        <Typography
                            variant='h5'
                            fontWeight='bold'
                            gutterBottom
                            sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                        >
                            Order Successfully Verified!
                        </Typography>
                        <Typography variant='body2' color='textSecondary' gutterBottom>
                            The order has been successfully verified. A confirmation email has been sent to
                            admin@mycompany.com.
                        </Typography>
                        <Typography variant='body2' fontWeight='bold' gutterBottom>
                            Order Number
                        </Typography>
                        <Typography variant='h6' gutterBottom>
                            12345
                        </Typography>
                        <Button
                            variant='save'
                            onClick={() => setOpenPaymentSuccessDialog(false)}
                            sx={{
                                marginTop: '16px',
                                width: { xs: '100%', sm: 'auto' },
                            }}
                        >
                            Return to Orders
                        </Button>
                    </DialogContent>
                </Dialog>
            </Box>
        </PermissionGate>
    );
};

export default TransactionHistory;
