import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers
const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch user role features
export const fetchUserPermissions = createAsyncThunk(
    'permissions/fetchUserPermissions',
    async (userId, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/user-roles`, {
                headers,
                params: { user_id: userId }
            });
            return response.data.data.userRoles;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Slice for Permissions
const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: {
        features: [],
        loading: false,
        error: null,
        lastFetched: null
    },
    reducers: {
        clearPermissions: (state) => {
            state.features = [];
            state.lastFetched = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserPermissions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserPermissions.fulfilled, (state, action) => {
                state.loading = false;
                // Extract and flatten features from all roles
                const features = action.payload.reduce((acc, role) => {
                    const roleFeatures = role.role.roleFeatures.map(rf => ({
                        code: rf.feature.code,
                        name: rf.feature.name,
                        canRead: rf.can_read,
                        canWrite: rf.can_write,
                        canDelete: rf.can_delete,
                        isActive: rf.is_active
                    }));
                    return [...acc, ...roleFeatures];
                }, []);
                
                // Deduplicate features by code, keeping the most permissive version
                const uniqueFeatures = Object.values(features.reduce((acc, feature) => {
                    if (!acc[feature.code] || 
                        (acc[feature.code].canRead && !feature.canRead) ||
                        (acc[feature.code].canWrite && !feature.canWrite) ||
                        (acc[feature.code].canDelete && !feature.canDelete)) {
                        acc[feature.code] = feature;
                    }
                    return acc;
                }, {}));

                state.features = uniqueFeatures;
                state.lastFetched = new Date().toISOString();
            })
            .addCase(fetchUserPermissions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { clearPermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
