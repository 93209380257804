import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    TextField,
    Tabs,
    Tab,
    Paper,
    List,
    ListItem,
    ListItemText,
    Collapse,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

const FeatureTabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`feature-tabpanel-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: 2 }}>
                {children}
            </Box>
        )}
    </div>
);

const FeatureList = ({ features, localPlanData, handleFeatureChange, handleLimitChange, handleLabelChange }) => {
    // Initialize openItems with all items expanded
    const [openItems, setOpenItems] = useState({});

    // Set all items to open when features change
    useEffect(() => {
        const initialOpenState = features.reduce((acc, feature) => {
            acc[feature.id] = true;
            if (feature.children) {
                feature.children.forEach(child => {
                    acc[child.id] = true;
                });
            }
            return acc;
        }, {});
        setOpenItems(initialOpenState);
    }, [features]);

    const toggleItem = (id) => {
        setOpenItems(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const renderFeatureItem = (feature, depth = 0) => {
        const hasChildren = Array.isArray(feature?.children) && feature.children.length > 0;
        const isFeatureSelected = localPlanData.planFeatures?.some(pf => pf.feature_id === feature.id);
        const isOpen = openItems[feature.id];

        return (
            <React.Fragment key={feature.id}>
                <ListItem
                    sx={{
                        pl: depth * 2,
                        py: 0.5,
                        '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.02)'
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isFeatureSelected}
                                    onChange={(e) => handleFeatureChange(feature.id, e.target.checked)}
                                    size="small"
                                />
                            }
                            label={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: hasChildren ? 500 : 400,
                                        color: isFeatureSelected ? 'text.primary' : 'text.secondary'
                                    }}
                                >
                                    {feature.name}
                                </Typography>
                            }
                            sx={{ flex: 1 }}
                        />

                        {!hasChildren && isFeatureSelected && (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    label="Limit"
                                    type="number"
                                    size="small"
                                    value={localPlanData.planFeatures.find(pf => pf.feature_id === feature.id)?.max_limit || ''}
                                    onChange={(e) => handleLimitChange(feature.id, e.target.value)}
                                    sx={{ width: '90px' }}
                                />
                                <TextField
                                    label="Label"
                                    size="small"
                                    value={localPlanData.planFeatures.find(pf => pf.feature_id === feature.id)?.label || ''}
                                    onChange={(e) => handleLabelChange(feature.id, e.target.value)}
                                    sx={{ width: '120px' }}
                                />
                            </Box>
                        )}

                        {hasChildren && (
                            <Box
                                onClick={() => toggleItem(feature.id)}
                                sx={{ cursor: 'pointer' }}
                            >
                                {isOpen ? <ExpandLess /> : <ExpandMore />}
                            </Box>
                        )}
                    </Box>
                </ListItem>

                {hasChildren && (
                    <Collapse in={isOpen} timeout="auto">
                        {feature.children.map(child => renderFeatureItem(child, depth + 1))}
                    </Collapse>
                )}
            </React.Fragment>
        );
    };

    return (
        <List dense>
            {features.map(feature => renderFeatureItem(feature))}
        </List>
    );
};

const FeatureHierarchy = ({ features, localPlanData, handleFeatureChange, handleLimitChange, handleLabelChange }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    // Group features by category
    const featureCategories = features.reduce((acc, feature) => {
        if (!feature.parent_id) {
            const category = feature.name.split(' ')[0].toLowerCase();
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(feature);
        }
        return acc;
    }, {});

    const categories = Object.keys(featureCategories);

    return (
        <Paper
            elevation={0}
            sx={{
                mt: 2,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                overflow: 'hidden'
            }}
        >
            <Box sx={{
                display: 'flex',
                bgcolor: 'background.paper',
                minHeight: 400
            }}>
                <Box sx={{
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    bgcolor: theme => theme.palette.grey[50],
                }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={(e, newValue) => setSelectedTab(newValue)}
                        sx={{
                            minWidth: 160,
                            maxWidth: 200,
                            '& .MuiTabs-indicator': {
                                left: 0,
                                width: 3,
                            },
                            '& .MuiTab-root': {
                                minHeight: 48,
                                maxHeight: 48,
                                padding: 2,
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                textAlign: 'left',
                                textTransform: 'capitalize',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                opacity: 0.7,
                                transition: 'all 0.2s ease-in-out',
                                '&.Mui-selected': {
                                    opacity: 1,
                                    fontWeight: 600,
                                    backgroundColor: 'background.paper',
                                    color: 'primary.main',
                                },
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                    opacity: 1,
                                },
                                '&:last-child': {
                                    borderBottom: 'none'
                                }
                            },
                        }}
                        TabIndicatorProps={{
                            sx: {
                                borderRadius: '0 4px 4px 0'
                            }
                        }}
                    >
                        {categories.map((category, index) => (
                            <Tab
                                key={category}
                                label={category.charAt(0).toUpperCase() + category.slice(1)}
                                id={`feature-tab-${index}`}
                                disableRipple
                            />
                        ))}
                    </Tabs>
                </Box>

                <Box sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    overflow: 'auto'
                }}>
                    {categories.map((category, index) => (
                        <FeatureTabPanel value={selectedTab} index={index} key={category}>
                            <Box sx={{ px: 3, py: 2 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 3,
                                        fontWeight: 600,
                                        fontSize: '1.125rem',
                                        color: 'text.primary'
                                    }}
                                >
                                    {category.charAt(0).toUpperCase() + category.slice(1)} Features
                                </Typography>
                                <FeatureList
                                    features={featureCategories[category]}
                                    localPlanData={localPlanData}
                                    handleFeatureChange={handleFeatureChange}
                                    handleLimitChange={handleLimitChange}
                                    handleLabelChange={handleLabelChange}
                                />
                            </Box>
                        </FeatureTabPanel>
                    ))}
                </Box>
            </Box>
        </Paper>
    );
};

export default FeatureHierarchy;