import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    TextField,
    Switch,
    FormControlLabel,
    Divider,
    CircularProgress,
    Alert,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SUPER_ADMIN_ORG_ID = 1;

const CATEGORIES = [
    { value: 'payment', label: 'Payment' },
    { value: 'subscription', label: 'Subscription' },
    { value: 'account', label: 'Account' },
    { value: 'security', label: 'Security' },
    { value: 'system', label: 'System' },
];

const CHANNELS = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
    { value: 'push', label: 'Push Notification' },
    { value: 'in_app', label: 'In-App Notification' },
];

const EVENT_TYPES = {
    payment: [
        { value: 'payment_failed', label: 'Payment Failed' },
        { value: 'payment_success', label: 'Payment Successful' },
        { value: 'refund_initiated', label: 'Refund Initiated' },
        { value: 'refund_completed', label: 'Refund Completed' },
    ],
    subscription: [
        { value: 'subscription_created', label: 'Subscription Created' },
        { value: 'subscription_cancelled', label: 'Subscription Cancelled' },
        { value: 'subscription_renewed', label: 'Subscription Renewed' },
        { value: 'subscription_expiring', label: 'Subscription Expiring' },
    ],
    // Add more event types for other categories
};

const AdminNotificationSettings = () => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formData, setFormData] = useState({
        category: '',
        event_type: '',
        subject_template: '',
        body_template: '',
        channels: ['email'],
        is_active: true,
        cooldown_period: 0,
        throttle_limit: 0,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/admin-notification-settings`, {
                params: { organization_id: SUPER_ADMIN_ORG_ID }
            });
            
            if (response.data.data) {
                const settings = response.data.data;
                // Transform data to match the grid format
                const transformedData = settings.map(setting => ({
                    id: setting.id,
                    category: setting.template?.category || '',
                    event_type: setting.template?.event_type || '',
                    subject_template: setting.template?.subject_template || '',
                    body_template: setting.template?.body_template || '',
                    channels: setting.channels || [],
                    is_active: setting.template?.is_active || false,
                    cooldown_period: setting.cooldown_period || 0,
                    throttle_limit: setting.throttle_limit || 0,
                    template_id: setting.template_id,
                }));
                
                setTemplates(transformedData);
            }
        } catch (error) {
            setError('Failed to fetch notification settings');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            setError(null);

            const payload = {
                organization_id: SUPER_ADMIN_ORG_ID,
                template_data: {
                    category: formData.category,
                    event_type: formData.event_type,
                    subject_template: formData.subject_template,
                    body_template: formData.body_template,
                    is_active: formData.is_active
                },
                settings_data: {
                    channels: formData.channels,
                    cooldown_period: parseInt(formData.cooldown_period, 10),
                    throttle_limit: parseInt(formData.throttle_limit, 10)
                }
            };

            if (selectedTemplate?.template_id) {
                await axios.put(`${API_BASE_URL}/admin-notification-settings`, {
                    ...payload,
                    template_id: selectedTemplate.template_id
                });
            } else {
                await axios.post(`${API_BASE_URL}/admin-notification-settings`, payload);
            }

            setSuccess(true);
            handleCloseDialog();
            fetchData();
        } catch (error) {
            console.error('Error saving:', error.response?.data || error);
            if (error.response?.data?.code === 409) {
                setError('A notification template with the same category and event type already exists.');
            } else if (error.response?.data?.error?.details) {
                setError(error.response.data.error.details.join(', '));
            } else {
                setError(error.response?.data?.description || 'Failed to save notification settings');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            await axios.delete(`${API_BASE_URL}/admin/notification-settings/${id}`);
            fetchData();
        } catch (error) {
            setError('Failed to delete template');
            console.error('Error deleting:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (template = null, view = false) => {
        setSelectedTemplate(template);
        setViewMode(view);
        if (template) {
            setFormData({
                ...template,
                channels: template.channels || ['email'],
                cooldown_period: template.cooldown_period || 0,
                throttle_limit: template.throttle_limit || 0,
                is_active: template.is_active ?? true
            });
        } else {
            setFormData({
                category: '',
                event_type: '',
                subject_template: '',
                body_template: '',
                channels: ['email'],
                is_active: true,
                cooldown_period: 0,
                throttle_limit: 0,
            });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedTemplate(null);
        setViewMode(false);
    };

    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            value: 'category',
            // valueGetter: (params) => {
            //     if (!params) return '';
            //     return CATEGORIES.find(c => c.value === params.category)?.label || params.category || '';
            // },
        },
        {
            field: 'event_type',
            headerName: 'Event Type',
            flex: 1,
            value: 'event_type',
            // valueGetter: (params) => {
            //     if (!params) return '';
            //     const events = EVENT_TYPES[params.category] || [];
            //     return events.find(e => e.value === params.event_type)?.label || params.event_type || '';
            // },
        },
        {
            field: 'channels',
            headerName: 'Channels',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                    {(params.row.channels || []).map(channel => (
                        <Typography key={channel} variant="body2">
                            {CHANNELS.find(c => c.value === channel)?.label}
                        </Typography>
                    ))}
                </Box>
            ),
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                <Typography
                    variant="body2"
                    sx={{
                        color: params.row.is_active ? 'success.main' : 'text.secondary',
                    }}
                >
                    {params.row.is_active ? 'Active' : 'Inactive'}
                </Typography>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="View">
                        <IconButton size="small" onClick={() => handleOpenDialog(params.row, true)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton size="small" onClick={() => handleOpenDialog(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton size="small" onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Box>
                    <Typography variant="h4">Notification Settings</Typography>
                    <Typography variant="h5">Manage your notification templates and settings.</Typography>
                </Box>
                <Button
                    variant="save"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                >
                    Add Template
                </Button>
            </Box>

            <Divider />

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "16px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    All Templates
                </Typography>

                <Divider sx={{my:0}}/>

                {error && (
                    <Alert severity="error" sx={{ m: 3 }}>{error}</Alert>
                )}

                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={templates}
                    columns={columns}
                    rowHeight={70}
                    autoHeight
                    disableRowSelectionOnClick
                    loading={loading}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            py: 2
                        }
                    }}
                />
            </Paper>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {viewMode ? 'View Template' : (selectedTemplate ? 'Edit Template' : 'New Template')}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} sx={{ mt: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={formData.category}
                                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                label="Category"
                                disabled={viewMode}
                            >
                                {CATEGORIES.map(category => (
                                    <MenuItem key={category.value} value={category.value}>
                                        {category.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {formData.category && (
                            <FormControl fullWidth>
                                <InputLabel>Event Type</InputLabel>
                                <Select
                                    value={formData.event_type}
                                    onChange={(e) => setFormData({ ...formData, event_type: e.target.value })}
                                    label="Event Type"
                                    disabled={viewMode}
                                >
                                    {EVENT_TYPES[formData.category]?.map(event => (
                                        <MenuItem key={event.value} value={event.value}>
                                            {event.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <TextField
                            label="Subject Template"
                            value={formData.subject_template}
                            onChange={(e) => setFormData({ ...formData, subject_template: e.target.value })}
                            fullWidth
                            multiline
                            rows={2}
                            helperText="Use {{variable}} for dynamic content"
                            disabled={viewMode}
                        />

                        <TextField
                            label="Body Template"
                            value={formData.body_template}
                            onChange={(e) => setFormData({ ...formData, body_template: e.target.value })}
                            fullWidth
                            multiline
                            rows={4}
                            helperText="Use {{variable}} for dynamic content"
                            disabled={viewMode}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Notification Channels</InputLabel>
                            <Select
                                multiple
                                value={formData.channels}
                                onChange={(e) => setFormData({ ...formData, channels: e.target.value })}
                                label="Notification Channels"
                                disabled={viewMode}
                            >
                                {CHANNELS.map(channel => (
                                    <MenuItem key={channel.value} value={channel.value}>
                                        {channel.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            label="Cooldown Period (minutes)"
                            type="number"
                            value={formData.cooldown_period}
                            onChange={(e) => setFormData({ ...formData, cooldown_period: e.target.value })}
                            fullWidth
                            helperText="Minimum time between notifications"
                            disabled={viewMode}
                        />

                        <TextField
                            label="Throttle Limit (per hour)"
                            type="number"
                            value={formData.throttle_limit}
                            onChange={(e) => setFormData({ ...formData, throttle_limit: e.target.value })}
                            fullWidth
                            helperText="Maximum notifications per hour (0 for unlimited)"
                            disabled={viewMode}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.is_active}
                                    onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                                    disabled={viewMode}
                                />
                            }
                            label="Active"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        {viewMode ? 'Close' : 'Cancel'}
                    </Button>
                    {!viewMode && (
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            disabled={loading}
                        >
                            {selectedTemplate ? 'Update' : 'Create'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminNotificationSettings;
