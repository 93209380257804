import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Tooltip,
    CircularProgress,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormGroup,
    FormLabel,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@mui/icons-material';
import axios from '../../../utils/axios';

const TIER_OPTIONS = [
    { value: 'starter', label: 'Starter' },
    { value: 'basic', label: 'Basic' },
    { value: 'professional', label: 'Professional' },
    { value: 'enterprise', label: 'Enterprise' }
];

const EditTierDialog = ({ open, onClose, tier, onSave }) => {
    const [formData, setFormData] = useState({
        name: '',
        minUsage: 0,
        maxUsage: 0,
        price: 0,
        features: {
            ticketing: false,
            booking: false,
            products: false
        }
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (tier) {
            setFormData({
                name: tier.name || '',
                minUsage: tier.min_usage || 0,
                maxUsage: tier.max_usage || 0,
                price: tier.price || 0,
                features: {
                    ticketing: tier.features?.ticketing || false,
                    booking: tier.features?.booking || false,
                    products: tier.features?.products || false
                }
            });
        }
    }, [tier]);

    const handleFeatureChange = (feature) => (event) => {
        setFormData(prev => ({
            ...prev,
            features: {
                ...prev.features,
                [feature]: event.target.checked
            }
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);
            await onSave(formData);
            onClose();
        } catch (err) {
            setError(err.message || 'Failed to update tier');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {tier ? 'Edit Tier' : 'Create New Tier'}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <FormControl fullWidth>
                        <InputLabel>Tier Name</InputLabel>
                        <Select
                            value={formData.name}
                            label="Tier Name"
                            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        >
                            {TIER_OPTIONS.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Features</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.features.ticketing}
                                        onChange={handleFeatureChange('ticketing')}
                                    />
                                }
                                label="Ticketing"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.features.booking}
                                        onChange={handleFeatureChange('booking')}
                                    />
                                }
                                label="Booking"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.features.products}
                                        onChange={handleFeatureChange('products')}
                                    />
                                }
                                label="Products"
                            />
                        </FormGroup>
                    </FormControl>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Minimum Usage"
                            type="number"
                            value={formData.minUsage}
                            onChange={(e) => setFormData(prev => ({ ...prev, minUsage: parseInt(e.target.value) }))}
                            fullWidth
                        />
                        <TextField
                            label="Maximum Usage"
                            type="number"
                            value={formData.maxUsage}
                            onChange={(e) => setFormData(prev => ({ ...prev, maxUsage: parseInt(e.target.value) }))}
                            fullWidth
                        />
                    </Box>

                    <TextField
                        label="Price"
                        type="number"
                        value={formData.price}
                        onChange={(e) => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AdminTierUsage = () => {
    const [tiers, setTiers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editTier, setEditTier] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [selectionModel, setSelectionModel] = useState([]);

    const sampleTiers = [
        {
            id: 1,
            name: 'starter',
            min_usage: 0,
            max_usage: 100,
            price: 0,
            features: {
                ticketing: true,
                booking: false,
                products: false
            }
        },
        {
            id: 2,
            name: 'basic',
            min_usage: 101,
            max_usage: 500,
            price: 49.99,
            features: {
                ticketing: true,
                booking: true,
                products: false
            }
        },
        {
            id: 3,
            name: 'professional',
            min_usage: 501,
            max_usage: 2000,
            price: 99.99,
            features: {
                ticketing: true,
                booking: true,
                products: true
            }
        },
        {
            id: 4,
            name: 'enterprise',
            min_usage: 2001,
            max_usage: 999999,
            price: 199.99,
            features: {
                ticketing: true,
                booking: true,
                products: true
            }
        }
    ];

    const fetchTiers = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.get('/admin/tiers');
            setTiers(response.data?.data?.tiers || sampleTiers);
        } catch (err) {
            console.error('Error fetching tiers:', err);
            setTiers(sampleTiers);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTiers();
    }, []);

    const handleSaveTier = async (tierData) => {
        try {
            if (editTier) {
                await axios.put(`/admin/tiers/${editTier.id}`, tierData);
            } else {
                await axios.post('/admin/tiers', tierData);
            }
            await fetchTiers();
            setDialogOpen(false);
            setEditTier(null);
        } catch (err) {
            throw new Error('Failed to save tier');
        }
    };

    const handleEditClick = (tier) => {
        setEditTier(tier);
        setDialogOpen(true);
    };

    const handleCreateNew = () => {
        setEditTier(null);
        setDialogOpen(true);
    };

    const handleDeleteSelected = async () => {
        try {
            setLoading(true);
            await Promise.all(
                selectionModel.map(id => axios.delete(`/admin/tiers/${id}`))
            );
            await fetchTiers();
            setSelectionModel([]);
        } catch (err) {
            setError('Failed to delete selected tiers');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5" component="h1">
                    Tier Usage Management
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {selectionModel.length > 0 && (
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={handleDeleteSelected}
                        >
                            Delete Selected
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleCreateNew}
                    >
                        Create New Tier
                    </Button>
                </Box>
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            )}

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tier Name</TableCell>
                                <TableCell align="right">Min Usage</TableCell>
                                <TableCell align="right">Max Usage</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="center">Features</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : tiers.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No tiers found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                tiers.map((tier) => (
                                    <TableRow key={tier.id}>
                                        <TableCell>
                                            {TIER_OPTIONS.find(option => option.value === tier.name)?.label || tier.name}
                                        </TableCell>
                                        <TableCell align="right">{tier.min_usage}</TableCell>
                                        <TableCell align="right">{tier.max_usage}</TableCell>
                                        <TableCell align="right">${tier.price}</TableCell>
                                        <TableCell align="center">
                                            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                                {tier.features?.ticketing && (
                                                    <Chip label="Ticketing" size="small" color="primary" />
                                                )}
                                                {tier.features?.booking && (
                                                    <Chip label="Booking" size="small" color="primary" />
                                                )}
                                                {tier.features?.products && (
                                                    <Chip label="Products" size="small" color="primary" />
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Edit Tier">
                                                <IconButton
                                                    onClick={() => handleEditClick(tier)}
                                                    size="small"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <EditTierDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setEditTier(null);
                }}
                tier={editTier}
                onSave={handleSaveTier}
            />
        </Box>
    );
};

export default AdminTierUsage;
