import DOMPurify from 'dompurify';

const HtmlContentRenderer = ({ content, containerStyles = {} }) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content, {
        ADD_TAGS: ['iframe'], // Allow iframe tags if needed for your content
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'style', 'src', 'title'], // Allow necessary iframe attributes
    });

    return (
        <div
            style={containerStyles}
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />
    );
};

export default HtmlContentRenderer;