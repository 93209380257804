import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Skeleton,
    Grid,
    InputAdornment,
    OutlinedInput,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    Inventory as InventoryIcon,
    AttachMoney as AttachMoneyIcon,
    RemoveShoppingCart as RemoveShoppingCartIcon,
    Category as CategoryIcon,
} from '@mui/icons-material';
import { CameraAlt as CameraIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { fetchProducts, deleteProduct } from '../../../../redux/productSlice';
import ProductDetailsDialog from '../BookingSetup/ProductDetailsDialog';
import AnalyticsWidgetSummary from '../../../shared/AnalyticsWidgetSummary';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import PermissionGate from '../../../shared/PermissionGate';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';

const ProductPlaceholder = () => (
    <Box
        sx={{
            width: '60px',
            height: '45px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            border: '1px dashed #bdbdbd',
            borderRadius: '4px',
            gap: 0.5,
        }}
    >
        <CameraIcon sx={{ color: '#bdbdbd', fontSize: '16px' }} />
        <Typography variant='caption' sx={{ color: '#757575', fontSize: '8px' }}>
            No image
        </Typography>
    </Box>
);

const ProductCatalog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products, status, error, totalCount } = useSelector(state => state.products);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        productId: null,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedProductsDialogOpen, setSelectedProductsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [totalProductCategoriesCount, setTotalProductCategoriesCount] = useState(0);
    const [averagePrice, setAveragePrice] = useState(0);
    const [outOfStockCount, setOutOfStockCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await dispatch(
                    fetchProducts({
                        page: paginationModel.page,
                        pageSize: paginationModel.pageSize,
                        searchQuery,
                    })
                ).unwrap();
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch products: ' + error.message,
                    severity: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch, paginationModel, searchQuery]);

    useEffect(() => {
        const fetchTotalProductCount = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/products?count=true`);
                setTotalProductCount(response.data.data.count);
            } catch (error) {
                console.error('Error fetching total product count:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch total product count: ' + error.message,
                    severity: 'error',
                });
            }
        };
        fetchTotalProductCount();
    }, []); // Empty dependency array means this effect runs once on component mount

    useEffect(() => {
        const fetchTotalProductCategoriesCount = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/product-categories?count=true`);
                setTotalProductCategoriesCount(response.data.data.count);
            } catch (error) {
                console.error('Error fetching total product categories count:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch total product categories count: ' + error.message,
                    severity: 'error',
                });
            }
        };
        fetchTotalProductCategoriesCount();
    }, []);

    useEffect(() => {
        const fetchAveragePrice = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/products?average_prices=true`);
                setAveragePrice(response.data.data.averagePrice);
            } catch (error) {
                console.error('Error fetching average price:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch average price: ' + error.message,
                    severity: 'error',
                });
            }
        };
        fetchAveragePrice();
    }, []);

    useEffect(() => {
        const fetchOutOfStockCount = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/products?outOfStock=true`);
                setOutOfStockCount(response.data.data.count);
            } catch (error) {
                console.error('Error fetching out of stock product count:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to fetch out of stock product count: ' + error.message,
                    severity: 'error',
                });
            }
        };
        fetchOutOfStockCount(); // Add this line
    }, []);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const handleAddClick = () => {
        navigate('/settings/product-catalog/add-product');
    };

    // const handleView = (id) => {
    //     navigate(`/settings/product-catalog/${id}/details`);
    // };
    const handleView = id => {
        const product = products.find(p => p.id === id);
        setSelectedProduct(product);
        setDetailsDialogOpen(true);
    };

    const handleEdit = product => {
        navigate('/settings/product-catalog/add-product', {
            state: { product },
        });
    };

    const handleDeleteClick = id => {
        setConfirmDialog({ open: true, productId: id });
    };

    const handleConfirmDelete = async () => {
        try {
            await dispatch(deleteProduct(confirmDialog.productId)).unwrap();
            setSnackbar({
                open: true,
                message: 'Product deleted successfully',
                severity: 'success',
            });
            dispatch(fetchProducts({ ...paginationModel, searchQuery }));
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to delete product: ' + error.message,
                severity: 'error',
            });
        } finally {
            setConfirmDialog({ open: false, productId: null });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const renderSkeletons = () => (
        <Box sx={{ padding: '0 32px 24px 32px' }}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant='rectangular' width={60} height={40} sx={{ mr: 5, borderRadius: '4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant='text' width='100%' />
                        <Skeleton variant='text' width='100%' />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const columns = [
        {
            field: 'image',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            renderCell: params => {
                const hasValidImage =
                    params.row.product_images &&
                    params.row.product_images.length > 0 &&
                    params.row.product_images[0].image_url;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {hasValidImage ? (
                            <Box
                                component='img'
                                src={
                                    params.row.product_images &&
                                    params.row.product_images.length > 0 &&
                                    params.row.product_images[0].image_url
                                        ? `${IMAGE_URL}${params.row.product_images[0].image_url}`
                                        : ProductPlaceholder()
                                }
                                alt='Product'
                                onError={e => {
                                    e.target.style.display = 'none';
                                    e.target.parentElement.appendChild(ProductPlaceholder());
                                }}
                                sx={{
                                    width: '60px',
                                    height: '45px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            <ProductPlaceholder />
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2' noWrap>
                        {params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'price',
            headerName: 'Pricing',
            flex: 0.5,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>RM{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <PermissionGate featureCode='RDPRD' fallback={null}>
                        <IconButton aria-label='view' onClick={() => handleView(params.row.id)}>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </PermissionGate>
                    <PermissionGate featureCode='UPDPRD' fallback={null}>
                        <IconButton aria-label='edit' onClick={() => handleEdit(params.row)}>
                            <EditOutlinedIcon />
                        </IconButton>
                    </PermissionGate>
                    <PermissionGate featureCode='DELPRD' fallback={null}>
                        <IconButton aria-label='delete' onClick={() => handleDeleteClick(params.row.id)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </PermissionGate>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Product Catalog</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            Manage your products, categories, and inventory.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            ml: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <PermissionGate featureCode='CRTPRDOVW' fallback={null}>
                            <Button color='primary' variant='save' onClick={handleAddClick} startIcon={<AddIcon />}>
                                Add New Product
                            </Button>
                        </PermissionGate>
                    </Box>
                </Box>

                {/*analytic graphs*/}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='PROD_READ' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Total Products'
                                percent={2.8}
                                total={totalProductCount}
                                icon={
                                    <InventoryIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: '#ffeb3b',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [280, 250, 310, 240, 320, 290, 300, 324],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='PROD_READ1' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Average Price'
                                percent={1.2}
                                total={averagePrice}
                                color='secondary'
                                icon={
                                    <AttachMoneyIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'secondary.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [120, 180, 140, 160, 130, 170, 135, 149],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='PROD_READ2' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Out of Stock'
                                percent={-2.3}
                                total={outOfStockCount}
                                color='warning'
                                icon={
                                    <RemoveShoppingCartIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'warning.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [45, 38, 42, 35, 32, 30, 25, 28],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='PROD_READ3' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Categories'
                                percent={4.5}
                                total={totalProductCategoriesCount}
                                color='error'
                                icon={
                                    <CategoryIcon
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: 'error.main',
                                        }}
                                    />
                                }
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [8, 7, 9, 8, 10, 11, 11, 12],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                </Grid>

                <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                    <Box />
                    <Box display='flex' gap='12px'>
                        <OutlinedInput
                            placeholder='Search by Name'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            startAdornment={
                                <InputAdornment position='start'>
                                    <SearchIcon sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            }
                            size='small'
                            sx={{ maxWidth: 320, mb: 2 }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: '100%',
                        borderRadius: '16px',
                        marginTop: '24px',
                        border: '1px solid var(--Gray-200, #EAECF0)',
                        background: 'var(--White, #FFF)',
                        boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '20px 24px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Gray-900, #101828)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '28px',
                            }}
                        >
                            All Products
                        </Typography>
                        {/*{selectedRows.length > 0 && (*/}
                        {/*    <Button*/}
                        {/*        variant="save"*/}
                        {/*        onClick={handleMultiSelectAction}*/}
                        {/*    >*/}
                        {/*        Create Package*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </Box>

                    <Divider sx={{ my: 0 }} />

                    {status === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            rows={products || []}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={totalCount}
                            loading={status === 'loading'}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                        />
                    )}
                </Paper>
            </Box>

            <Dialog open={confirmDialog.open} onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} variant='cancel'>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='save' autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/*<SelectedProductsDialog*/}
            {/*    open={selectedProductsDialogOpen}*/}
            {/*    onClose={handleCloseSelectedProductsDialog}*/}
            {/*    selectedProducts={products.filter(product => selectedRows.includes(product.id))}*/}
            {/*/>*/}

            <ProductDetailsDialog
                open={detailsDialogOpen}
                onClose={() => setDetailsDialogOpen(false)}
                product={selectedProduct}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductCatalog;
