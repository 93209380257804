import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon, NotificationsLogo, SettingsLogo } from './HeaderSvgImages';
import DropdownMenu from './HeaderProfile';
import { useKeycloak } from '../../contexts/KeycloakContext';

const Header = ({ toggleSidebar }) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useKeycloak();

    const handleBoxClick = path => {
        navigate(path);
    };

    if (!isAuthenticated) {
        return null;
    }

    return (
        <AppBar position='fixed' sx={{ zIndex: theme => theme.zIndex.drawer + 1, width: '100%' }}>
            <Toolbar sx={{ padding: { xs: '0 8px', sm: '0 16px' } }}>
                <IconButton
                    aria-label='open drawer'
                    edge='start'
                    onClick={toggleSidebar}
                    sx={{
                        mr: { xs: 0, sm: 2 },
                        ml: { xs: 1, sm: 0 },
                        color: '#f1f1f1',
                    }}
                >
                    <MenuIcon sx={{ color: '#f1f1f1' }} />
                </IconButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component='img'
                        src='/fantet-logo.png'
                        alt='Company Logo'
                        sx={{
                            height: 40,
                            maxHeight: { xs: 30, sm: 40 },
                            cursor: 'pointer',
                            objectFit: 'contain',
                        }}
                        onClick={() => handleBoxClick('/')}
                    />
                </Box>
                <Box sx={{ flexGrow: 1 }} /> {/* Push content to the right */}
                <Box sx={{ display: 'flex', gap: { xs: '18px', sm: '10px' }, alignItems: 'center' }}>
                    <IconButton sx={{ padding: 0 }}>
                        <MagnifyingGlassIcon
                            sx={{
                                fontSize: { xs: 20, sm: 24 },
                            }}
                        />
                    </IconButton>
                    <IconButton onClick={() => handleBoxClick('/settings')} sx={{ padding: 0, cursor: 'pointer' }}>
                        <SettingsLogo
                            sx={{
                                fontSize: { xs: 20, sm: 24 },
                            }}
                        />
                    </IconButton>
                    <IconButton sx={{ padding: 0 }}>
                        <NotificationsLogo
                            sx={{
                                fontSize: { xs: 20, sm: 24 },
                            }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        justifyContent: 'center',
                        width: { xs: 'auto', sm: '190px' },
                    }}
                >
                    <DropdownMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
