import React from 'react';
import { useState, useEffect } from 'react';
import {
    Box, Typography, Divider, RadioGroup, FormControlLabel, Radio, Button, TextField,
    Checkbox, Link, Grid, Paper, Select, MenuItem, Switch, CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemAvatar, Avatar
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import AddIcon from "@mui/icons-material/Add";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GIcon from '@mui/icons-material/Google';
import EditIcon from '@mui/icons-material/Edit';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../../../redux/productSlice';
import keycloak from "../../../../keycloak";

const IconButtonWithLabel = ({ icon, label, backgroundColor = 'orange', textColor = 'white' }) => {
    return (
        <Button
            sx={{
                backgroundColor: backgroundColor,
                color: textColor,
                minWidth: '20px',
                minHeight: 40,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {icon}
            {label}
        </Button>
    );
};

const ProductSelectionDialog = ({ open, onClose, products, onSelectProduct }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Select a Product</DialogTitle>
            <DialogContent>
                <List>
                    {products.map((product) => (
                        <ListItem
                            button
                            key={product.id}
                            onClick={() => onSelectProduct(product)}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)', // Add hover effect
                                },
                                display: 'flex',
                                alignItems: 'center',
                                padding: '16px',
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    alt={product.name}
                                    src={"/2.jpeg"}
                                    sx={{ width: 90, height: 60, marginRight: 2, borderRadius:'4px' }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1" component="div">
                                        {product.name}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="body2" color="text.secondary">
                                        RM {product.price}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='cancel'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CreateSubscription = () => {
    const [resetBillingCycle, setResetBillingCycle] = useState(false);
    const [prorateChanges, setProrateChanges] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const productStatus = useSelector((state) => state.products.status);

    useEffect(() => {
        if (productStatus === 'idle') {
            dispatch(fetchProducts(keycloak.token));
        }
    }, [productStatus, dispatch]);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSelectProduct = (product) => {
        setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
        handleCloseDialog();
    };

    const handleQuantityChange = (index, newQuantity) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts[index].quantity = newQuantity;
        setSelectedProducts(updatedProducts);
    };

    const calculateTotal = (product) => {
        return product.price * product.quantity;
    };

    if (productStatus === 'loading') {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant='h1'>Create Subscriptions</Typography>
            <Divider/>
            {/*Leftside sub setting*/}
            <Box sx={{ display: 'flex', gap: 5 }}>
                <Box sx={{ flex: 1.2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Subscription settings
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                        These settings apply to the entire subscription and span across all phases.
                    </Typography>

                    <Box>
                        {/* New component based on the image */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Duration
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                    Aug 24, 2024 →
                                </Typography>
                                <Button
                                    variant="cancel"
                                    startIcon={<CalendarTodayIcon />}
                                >
                                    Forever
                                </Button>
                            </Box>

                            {/*pricing*/}
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Pricing
                            </Typography>
                            <Divider/>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        PRODUCT
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" color="text.secondary">
                                        QTY
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" color="text.secondary">
                                        TOTAL
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider/>
                            {selectedProducts.map((product, index) => (
                                <Grid container spacing={2} sx={{ mb: 2 }} key={product.id}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <Typography variant="body2">
                                                {product.name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            value={product.quantity}
                                            onChange={(e) => handleQuantityChange(index, parseInt(e.target.value) || 0)}
                                            sx={{width:'60px'}}
                                            type="number"
                                            inputProps={{ min: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2">RM{calculateTotal(product).toFixed(2)} MYR</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            <Divider/>

                            {/*hyperlinks*/}
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my:4 }}>
                                <Link href="#" sx={{ color: 'orange', fontWeight:'500' }} onClick={handleOpenDialog}>
                                    Add product
                                </Link>
                                <Link href="#" sx={{ color: 'orange', fontWeight:'500' }}>
                                    Add tax manually
                                </Link>
                                <Link href="#" sx={{ color: 'orange', fontWeight:'500' }}>
                                    Add coupon
                                </Link>
                            </Box>

                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Billing cycle details
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={resetBillingCycle}
                                        onChange={(e) => setResetBillingCycle(e.target.checked)}
                                    />
                                }
                                label={
                                    <Box>
                                        <Typography variant="body2">
                                            Reset billing cycle
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            This will generate an invoice immediately
                                        </Typography>
                                    </Box>
                                }
                            />

                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2, mt: 2 }}>
                                Proration behavior
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={prorateChanges}
                                        onChange={(e) => setProrateChanges(e.target.checked)}
                                    />
                                }
                                label="Prorate changes"
                            />

                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2, mt: 2 }}>
                                Free trial days
                            </Typography>
                            <Button
                                variant="cancel"
                                startIcon={<AddIcon />}
                            >
                                Add trial days
                            </Button>
                        </Box>
                    </Box>

                    <Box>
                        <Typography sx={{fontWeight:'800', fontSize:'16px'}}>
                            Payment
                        </Typography>
                        <Divider/>
                        <RadioGroup defaultValue="manual">
                            <FormControlLabel
                                value="automatic"
                                control={<Radio sx={{ alignSelf: 'flex-start' }} />}
                                sx={{ alignItems: 'flex-start' }}
                                label={
                                    <Box>
                                        <Typography sx={{fontWeight:'600', fontSize:'16px'}}>
                                            Automatically charge a payment method on file
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            This customer doesn't have any valid payment methods on file.
                                        </Typography>
                                        <Box sx={{mt:1}}>
                                            <Button variant="cancel" startIcon={<AddIcon />}>
                                                Add a payment method
                                            </Button>
                                        </Box>
                                    </Box>
                                }
                            />

                            <Divider/>
                            <FormControlLabel
                                value="manual"
                                control={<Radio sx={{ alignSelf: 'flex-start' }} />}
                                sx={{ alignItems: 'flex-start' }}
                                label={
                                    <Box>
                                        <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                                            Email invoice to the customer to pay manually
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Set your payment preferences, and we'll take care of the rest.
                                        </Typography>

                                        <Typography sx={{ fontWeight: '600', fontSize: '16px', mt: 2 }}>
                                            Payment due
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            <TextField size="small" placeholder="3 days" sx={{ width: 75 }} />
                                            <Typography variant="body2" sx={{ ml: 1 }}>
                                                after invoice is sent
                                            </Typography>
                                        </Box>

                                        <Typography sx={{ fontWeight: '600', fontSize: '16px', mt: 2 }}>
                                            Invoice payment page
                                        </Typography>
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked />}
                                            label={
                                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                                                    Include a Stripe-hosted link to an invoice payment page in the invoice email
                                                </Typography>
                                            }
                                        />
                                        <Typography sx={{ fontWeight: '600', fontSize: '16px', mt: 2 }}>
                                            Payment methods
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt:1 }}>
                                            <IconButtonWithLabel icon={<CreditCardIcon />} label="" />
                                            <IconButtonWithLabel icon={<PaymentIcon />} label="FPX" />
                                            <IconButtonWithLabel icon={<GIcon />} label="" />
                                            <Link
                                                href="#"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'orange',
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                    ml: 1,
                                                }}
                                            >
                                                <EditIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                Manage
                                            </Link>
                                        </Box>
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    </Box>

                    <Divider/>

                    <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Invoice template
                        </Typography>
                        <Select defaultValue="default" sx={{width:'300px'}}>
                            <MenuItem value="default">Select a template...</MenuItem>
                            <MenuItem value="template1">Template 1</MenuItem>
                            <MenuItem value="template2">Template 2</MenuItem>
                        </Select>
                    </Box>
                    <Divider/>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <Box/>
                        <Button variant="save">
                            Create subscription
                        </Button>
                    </Box>

                </Box>

                {/* Rightside - Invoice Preview */}
                <Paper elevation={3} sx={{ flex: 1, p: 3, height: '650px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Invoice Preview
                    </Typography>
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            Invoice number: B0FB83D7-0001
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Date due: August 24, 2024
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">
                            <strong>Orchard Villa</strong><br />
                            +60 14-911 2550<br />
                            ys.event.properties@gmail.com
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            <strong>Bill to</strong>: Customer<br />
                            +60 12-123 3600<br />
                           customer@gmail.com
                        </Typography>

                        <Grid container spacing={2} sx={{ mt: 4 }}>
                            <Grid item xs={6}>
                                <Typography variant="body2">Description</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">Qty</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">Amount</Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {selectedProducts.map((product, index) => (
                            <Grid container spacing={2} sx={{ mb: 2 }} key={product.id}>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography variant="body2">
                                            {product.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">
                                        {product.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">RM {calculateTotal(product).toFixed(2)}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                        <Divider/>

                        <Box sx={{ mt: 4 }}>
                            {selectedProducts.length > 0 ? (
                                <React.Fragment>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        Subtotal: RM {selectedProducts.reduce((total, product) => total + calculateTotal(product), 0).toFixed(2)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        Total: RM {selectedProducts.reduce((total, product) => total + calculateTotal(product), 0).toFixed(2)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                                        Amount due: RM {selectedProducts.reduce((total, product) => total + calculateTotal(product), 0).toFixed(2)}
                                    </Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        Subtotal: RM 0.00
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        Total: RM 0.00
                                    </Typography>
                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                                        Amount due: RM 0.00
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <ProductSelectionDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                products={products}
                onSelectProduct={handleSelectProduct}
            />
        </Box>
    );
};

export default CreateSubscription;
