import { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton, Grid } from '@mui/material';;
import PaymentTicketingOverviewPast from "./PaymentTicketingOverviewPast";
import {
    LocalActivity as TicketIcon,
    PaidOutlined as RevenueIcon,
    SellOutlined as PriceIcon,
    CancelOutlined as CancelIcon
} from '@mui/icons-material';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PaymentProviderTicketing = () => {
    const [value, setValue] = useState(0);

    const [monthlyTickets, setMonthlyTickets] = useState(0);
    const [todayTotalRevenue, setTodayTotalRevenue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ticketsResponse, revenueResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/purchases?purchase_type=2&status_id=3`),
                    axios.get(`${API_BASE_URL}/purchases?today_total_revenue=true&purchase_type=2&status_id=3`)
                ]);

                setMonthlyTickets(ticketsResponse.data.data.total || 0);
                setTodayTotalRevenue(revenueResponse.data.data.todayTotalRevenue || 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>Ticketing Overview</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>All of your event tickets and pricing.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/*buttons*/}
                    </Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Monthly Tickets Sold"
                            percent={3.2}
                            total={monthlyTickets}
                            icon={<TicketIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Today's Revenue"
                            percent={4.5}
                            total={todayTotalRevenue}
                            color="secondary"
                            icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Average Ticket Price"
                            percent={1.8}
                            total={54.2}
                            color="warning"
                            icon={<PriceIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [40, 25, 48, 32, 50, 38, 45, 54.2],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Cancelled Tickets"
                            percent={-0.5}
                            total={124}
                            color="error"
                            icon={<CancelIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [220, 280, 180, 250, 160, 200, 140, 124],
                            }}
                        />
                    </Grid>
                </Grid>

                {/*<Box height='38px'>*/}
                {/*    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">*/}
                {/*        <Tab label="Current" />*/}
                {/*        <Tab label="Upcoming" />*/}
                {/*        <Tab label="Past" />*/}
                {/*    </Tabs>*/}
                {/*</Box>*/}

                <Divider sx={{margin: '10px 0 10px 0'}}/>

                <Box>
                    {/*{ value === 0 && <TicketingOverviewCurrent/> }*/}
                    {/*{ value === 1 && <TicketingOverviewUpcoming/> }*/}
                    {/*{ value === 2 && <TicketingOverviewPast/> }*/}
                    <PaymentTicketingOverviewPast/>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentProviderTicketing;