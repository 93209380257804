import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    Card,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
    CheckCircle as CheckCircleIcon,
    ArrowRight as ArrowRightIcon
} from "@mui/icons-material";
import { fetchSubscriptionFeatures } from '../../redux/subscriptionFeaturesSlice';

const FeatureList = styled(List)(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}));

const FeatureCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
}));

const FeatureItem = ({ feature, depth = 0, onEdit, onDelete }) => (
    <>
        <ListItem 
            sx={{ 
                pl: depth * 2,
                py: 0.5,
                '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                    '& .feature-actions': {
                        opacity: 1,
                    },
                },
            }}
        >
            <ListItemIcon sx={{ minWidth: 24 }}>
                {depth === 0 ? (
                    <CheckCircleIcon sx={{ fontSize: 18, color: 'primary.main' }} />
                ) : (
                    <ArrowRightIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                )}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        gap: 1,
                    }}>
                        <Typography
                            sx={{
                                fontSize: depth === 0 ? '0.9rem' : '0.85rem',
                                fontWeight: depth === 0 ? 600 : 400,
                                color: depth === 0 ? 'text.primary' : 'text.secondary',
                            }}
                        >
                            {feature.name}
                        </Typography>
                        <Box className="feature-actions" sx={{ opacity: 0, transition: 'opacity 0.2s' }}>
                            <IconButton size="small" onClick={() => onEdit(feature)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={() => onDelete(feature.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                }
                secondary={
                    <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                        {feature.code} - {feature.description}
                    </Typography>
                }
            />
        </ListItem>
        {feature.children?.map(child => (
            <FeatureItem 
                key={child.id}
                feature={child}
                depth={depth + 1}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        ))}
    </>
);

const AdminSubscriptionFeatures = () => {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.subscriptionFeatures.features);
    const { status } = useSelector((state) => state.subscriptionFeatures);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchSubscriptionFeatures());
        }
    }, [status, dispatch]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (feature) => {
        console.log('Edit feature:', feature);
        // Implement edit functionality
    };

    const handleDelete = (featureId) => {
        console.log('Delete feature:', featureId);
        // Implement delete functionality
    };

    const handleAddFeature = () => {
        console.log('Add new feature');
        // Implement add functionality
    };

    const organizeFeatures = (features) => {
        if (!features) return [];
        
        const featureMap = new Map();
        const rootFeatures = [];

        // First pass: Create map of all features
        features.forEach(feature => {
            featureMap.set(feature.id, {
                ...feature,
                children: []
            });
        });

        // Second pass: Build hierarchy
        features.forEach(feature => {
            if (feature.parent_id) {
                const parentFeature = featureMap.get(feature.parent_id);
                if (parentFeature) {
                    parentFeature.children.push(featureMap.get(feature.id));
                }
            } else {
                rootFeatures.push(featureMap.get(feature.id));
            }
        });

        // Sort features alphabetically
        const sortFeatures = (features) => {
            features.sort((a, b) => a.name.localeCompare(b.name));
            features.forEach(feature => {
                if (feature.children?.length > 0) {
                    sortFeatures(feature.children);
                }
            });
        };

        sortFeatures(rootFeatures);
        return rootFeatures;
    };

    const filteredFeatures = useMemo(() => {
        if (!features) return [];

        const searchLower = searchQuery.toLowerCase();
        const filterFeature = (feature) => {
            const matchesSearch = 
                feature.name.toLowerCase().includes(searchLower) ||
                feature.code.toLowerCase().includes(searchLower) ||
                feature.description.toLowerCase().includes(searchLower);

            if (matchesSearch) return true;

            if (feature.children?.length > 0) {
                feature.children = feature.children.filter(filterFeature);
                return feature.children.length > 0;
            }

            return false;
        };

        const organizedFeatures = organizeFeatures(features);
        return searchQuery ? organizedFeatures.filter(filterFeature) : organizedFeatures;
    }, [features, searchQuery]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box>
                    <Typography variant="h4">Features</Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        Manage your subscription features and their hierarchy
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddFeature}
                >
                    Add Feature
                </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box />
                <TextField
                    variant="outlined"
                    placeholder="Search features..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        width: 300,
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                        }
                    }}
                />
            </Box>

            <Paper sx={{ p: 2 }}>
                {status === 'loading' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <FeatureList>
                        {filteredFeatures.map(feature => (
                            <FeatureCard key={feature.id}>
                                <FeatureItem
                                    feature={feature}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            </FeatureCard>
                        ))}
                    </FeatureList>
                )}
            </Paper>
        </Box>
    );
};

export default AdminSubscriptionFeatures;