import { useState, useEffect } from 'react';
import { useKeycloak } from '../../contexts/KeycloakContext';

export const useOrganizationRoles = () => {
    const { organizationRoles } = useKeycloak();
    return organizationRoles;
};

export const useOrganizationPlan = () => {
    const { keycloak, isAuthenticated } = useKeycloak();
    const [organizationPlan, setOrganizationPlan] = useState(['default']);

    useEffect(() => {
        const fetchOrganizationPlan = async () => {
            if (isAuthenticated && keycloak) {
                try {
                    await keycloak.updateToken(30);
                    const token = keycloak.tokenParsed;
                    if (token && token.organization_plan) {
                        setOrganizationPlan(Array.isArray(token.organization_plan)
                            ? token.organization_plan
                            : [token.organization_plan]);
                    }
                } catch (error) {
                    console.error('Failed to fetch organization plan:', error);
                }
            }
        };

        fetchOrganizationPlan();
    }, [isAuthenticated, keycloak]);

    return organizationPlan;
};