import { useState, useEffect } from 'react';
import { Box, Typography, Card, Grid, Divider, Skeleton } from '@mui/material';
import { CalendarToday as CalendarTodayIcon, Pending as PendingIcon, Paid as PaidIcon } from '@mui/icons-material';
import { Chart, useChart } from '../../material-kit/chart';
import { useTheme } from '@mui/material';
import { alpha as hexAlpha } from '@mui/material/styles';
import AnalyticsWidgetSummary from '../../shared/AnalyticsWidgetSummary';
import AgentReferral from './AgentReferral';
import PermissionGate from 'components/shared/PermissionGate';

const AgentDashboard = () => {
    const [loading, setLoading] = useState(true);
    const agentCode = 'AGENT123';
    const theme = useTheme();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, []);

    const salesChart = {
        categories: Array.from({length: 31}, (_, i) => (i + 1).toString()),
        series: [
            {
                name: 'Adult Tickets',
                data: Array.from({length: 31}, () => Math.floor(Math.random() * (40 - 10 + 1)) + 10),
            },
            {
                name: 'Kids Tickets',
                data: Array.from({length: 31}, () => Math.floor(Math.random() * (20 - 5 + 1)) + 5),
            },
            {
                name: 'Senior Tickets',
                data: Array.from({length: 31}, () => Math.floor(Math.random() * (15 - 2 + 1)) + 2),
            },
        ],
    };

    const chartColors = [theme.palette.primary.dark, hexAlpha(theme.palette.primary.light, 0.64)];

    const chartOptions = useChart({
        colors: chartColors,
        stroke: { width: 2, colors: ['transparent'] },
        xaxis: { categories: salesChart.categories },
        legend: { show: true },
        tooltip: {
            y: { formatter: value => `RM ${value}` },
        },
    });

    return (
        <Box sx={{ padding: { xs: 1, md: '32px' } }}>
            <Typography variant='h4' sx={{ mb: { xs: 3, md: 5 } }}>
                Hi, Welcome back 👋
            </Typography>

            <PermissionGate featureCode='COPY' fallback={null}>
                <AgentReferral agentCode={agentCode} />
            </PermissionGate>

            <Divider sx={{ my: 6 }} />

            <PermissionGate featureCode='READ3' fallback={null}>
                <>
                    <Typography variant='h5' sx={{ mb: 3, fontWeight: 600 }}>
                        Booking Stats
                    </Typography>
                    <Grid container spacing={3} sx={{ mb: 6 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <AnalyticsWidgetSummary
                                title='All Bookings'
                                percent={2.5}
                                total={3842}
                                icon={<CalendarTodayIcon sx={{ width: 32, height: 32, color: '#2196f3' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [2500, 3000, 2800, 3200, 3400, 3600, 3500, 3842],
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <AnalyticsWidgetSummary
                                title='Pending Payment'
                                percent={-1.2}
                                total={547}
                                color='warning'
                                icon={<PendingIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [400, 300, 450, 500, 480, 520, 550, 547],
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <AnalyticsWidgetSummary
                                title='Paid Bookings'
                                percent={4.8}
                                total={3295}
                                color='success'
                                icon={<PaidIcon sx={{ width: 32, height: 32, color: 'success.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [2100, 2700, 2350, 2700, 2920, 3080, 2950, 3295],
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            </PermissionGate>

            <PermissionGate featureCode='AGEN_SALE' fallback={null}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h5' sx={{ mb: 3, fontWeight: 600 }}>
                            Sales Overview
                        </Typography>
                        <Card sx={{ borderRadius: 2 }}>
                            {loading ? (
                                <Skeleton variant='rectangular' width='100%' height={364} sx={{ borderRadius: 2 }} />
                            ) : (
                                <Box sx={{ p: 3, pb: 0, width: '100%' }}>
                                    <Chart
                                        type='bar'
                                        series={salesChart.series}
                                        options={{
                                            ...chartOptions,
                                            chart: {
                                                ...chartOptions.chart,
                                                width: '100%',
                                                height: '100%',
                                                parentHeightOffset: 0,
                                            },
                                            responsive: [
                                                {
                                                    breakpoint: undefined,
                                                    options: {
                                                        chart: {
                                                            width: '100%',
                                                        },
                                                    },
                                                },
                                            ],
                                        }}
                                        height={364}
                                        width='100%'
                                    />
                                </Box>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </PermissionGate>
        </Box>
    );
};

export default AgentDashboard;
