import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton, Grid } from '@mui/material';
import TicketingOverviewCurrent from './TicketingOverviewCurrent';
import TicketingOverviewUpcoming from './TicketingOverviewUpcoming';
import TicketingOverviewPast from './TicketingOverviewPast';
import {
    QrCode as QrCodeIcon,
    LocalActivity as TicketIcon,
    PaidOutlined as RevenueIcon,
    SellOutlined as PriceIcon,
    CancelOutlined as CancelIcon,
} from '@mui/icons-material';
import QRDialog from './QRDialog';
import AnalyticsWidgetSummary from '../../shared/AnalyticsWidgetSummary';
import PermissionGate from '../../shared/PermissionGate';
import { useDispatch } from 'react-redux';
import { fetchTicketingStats } from './TicketingOverviewAPI';
import keycloak from '../../../keycloak';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TicketingOverview = () => {
    const [value, setValue] = useState(0);
    const [openQRScanner, setOpenQRScanner] = useState(false);
    const [stats, setStats] = useState({
        monthlyTicketsSold: 0,
        todayRevenue: 0,
        avgTicketPrice: 0,
        cancelledTickets: 0
    });
    
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const organizationId = keycloak.tokenParsed?.organization_id;
                if (!organizationId) {
                    console.error('No organization ID found in token');
                    return;
                }
                const response = await fetchTicketingStats(organizationId);
                setStats({
                    ...response.data,
                    avgTicketPrice: parseFloat(response.data.avgTicketPrice)
                });
            } catch (error) {
                console.error('Error fetching ticketing stats:', error);
            }
        };

        if (keycloak.authenticated) {
            fetchStats();
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQRIconClick = () => {
        setOpenQRScanner(true);
    };

    const handleCloseQRScanner = () => {
        setOpenQRScanner(false);
    };

    const renderTabContent = () => {
        switch (value) {
            case 0:
                return (
                    <PermissionGate featureCode='TCKO_READ' fallback={null}>
                        <TicketingOverviewCurrent />
                    </PermissionGate>
                );
            case 1:
                return (
                    <PermissionGate featureCode='TCKO_READ1' fallback={null}>
                        <TicketingOverviewUpcoming />
                    </PermissionGate>
                );
            case 2:
                return (
                    <PermissionGate featureCode='TCKO_READ2' fallback={null}>
                        <TicketingOverviewPast />
                    </PermissionGate>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ display: 'flex', padding: { xs: 1, md: '32px' } }}>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Ticketing Overview</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            All of your event tickets and pricing.
                        </Typography>
                    </Box>
                    <PermissionGate featureCode='SCNTCK' fallback={null}>
                        <Box
                            sx={{
                                ml: 'auto',
                                mr: '32px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant='body2' align='center'>
                                Click here to scan
                                <br />
                                customer tickets
                            </Typography>
                            <IconButton aria-label='qr' size='large' onClick={handleQRIconClick}>
                                <QrCodeIcon sx={{ fontSize: 48 }} />
                            </IconButton>
                        </Box>
                    </PermissionGate>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='TCKO_READ3' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Monthly Tickets Sold'
                                percent={3.2}
                                total={stats.monthlyTicketsSold}
                                icon={<TicketIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='TCKO_READ4' fallback={null}>
                            <AnalyticsWidgetSummary
                                title="Today's Revenue"
                                percent={4.5}
                                total={stats.todayRevenue}
                                color='secondary'
                                icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='TCKO_READ5' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Average Ticket Price'
                                percent={1.8}
                                total={stats.avgTicketPrice}
                                color='warning'
                                icon={<PriceIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [40, 25, 48, 32, 50, 38, 45, 54.2],
                                }}
                            />
                        </PermissionGate>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <PermissionGate featureCode='TCKO_READ6' fallback={null}>
                            <AnalyticsWidgetSummary
                                title='Cancelled Tickets'
                                percent={-0.5}
                                total={stats.cancelledTickets}
                                color='error'
                                icon={<CancelIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [220, 280, 180, 250, 160, 200, 140, 124],
                                }}
                            />
                        </PermissionGate>
                    </Grid>
                </Grid>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label='service tabs'>
                        <PermissionGate featureCode='TCKO_READ' fallback={null}>
                            <Tab label='Current' />
                        </PermissionGate>
                        <PermissionGate featureCode='TCKO_READ1' fallback={null}>
                            <Tab label='Upcoming' />
                        </PermissionGate>
                        <PermissionGate featureCode='TCKO_READ2' fallback={null}>
                            <Tab label='Past' />
                        </PermissionGate>
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '10px 0 10px 0' }} />

                <Box>{renderTabContent()}</Box>
            </Box>

            <QRDialog open={openQRScanner} onClose={handleCloseQRScanner} />
        </Box>
    );
};

export default TicketingOverview;
