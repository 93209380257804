import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Stack,
} from '@mui/material';

const ReviewConfirm = ({ selectedPromotions, subscriptionDetails }) => {
    const SummaryItem = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 1
        }}>
            <Typography color="text.secondary">{label}</Typography>
            <Typography fontWeight="medium">
                {value === '' || value === undefined ? '0' : value}
            </Typography>
        </Box>
    );

    return (
        <Box sx={{ maxWidth: 'md' }}>
            <Typography variant="h5" sx={{ mb: 4, fontWeight: 'bold' }}>
                Review Your New Subscription Plan: Basic
            </Typography>
            <Stack spacing={3}>
                {selectedPromotions.map((promo, index) => (
                    <Paper
                        key={`${promo.category}-${promo.id}`}
                        sx={{
                            p: 3,
                            borderRadius: 2,
                            boxShadow: 1,
                            bgcolor: 'background.paper'
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                            {promo.category} Promotion
                        </Typography>
                        <SummaryItem
                            label="Promotion Type"
                            value={promo.title}
                        />
                        <SummaryItem
                            label="Description"
                            value={promo.description}
                        />
                        <SummaryItem
                            label="Example"
                            value={promo.example}
                        />
                    </Paper>
                ))}

                {selectedPromotions.length > 0 && (
                    <Paper sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 1,
                        bgcolor: 'background.paper'
                    }}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                            Subscription Details
                        </Typography>
                        <SummaryItem
                            label="Number of Tickets"
                            value={subscriptionDetails.ticketCount}
                        />
                        <SummaryItem
                            label="Number of Bookings"
                            value={subscriptionDetails.bookingCount}
                        />
                        <SummaryItem
                            label="Number of Products"
                            value={subscriptionDetails.productCount}
                        />
                    </Paper>
                )}

                {selectedPromotions.length === 0 && (
                    <Paper sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 1,
                        bgcolor: 'background.paper'
                    }}>
                        <Typography variant="body1" color="text.secondary" align="center">
                            No promotions selected
                        </Typography>
                    </Paper>
                )}
            </Stack>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                mt: 3
            }}>
                <Button
                    variant="cancel"
                >
                    Cancel
                </Button>
                <Button
                    variant="save"
                    disabled={selectedPromotions.length === 0}
                >
                    Confirm Plan
                </Button>
            </Box>
        </Box>
    );
};

export default ReviewConfirm;