import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Paper,
    alpha,
    Stack,
    TextField,
    Button,
    Switch,
    Snackbar,
    Alert, FormHelperText, InputAdornment
} from '@mui/material';
import {createTheme} from "../../../theme/create-theme";
import { useTheme } from '@mui/material/styles';
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import FeatureHierarchy from "./FeatureHierarchy";

const PromotionOptions = ({ promotions, category, selectedPromo, onPromoSelect }) => {
    const theme = useTheme();

    return (
        <Stack spacing={2} sx={{ mt: 2, ml: 0 }}>
            {promotions.map((promo) => {
                const IconComponent = promo.icon;
                const isSelected = selectedPromo === promo.id;

                return (
                    <Paper
                        key={promo.id}
                        elevation={isSelected ? 2 : 0}
                        sx={{
                            position: 'relative',
                            border: 1,
                            borderColor: isSelected ? 'primary.main' : 'divider',
                            borderRadius: 2,
                            transition: 'all 0.2s ease-in-out',
                            cursor: 'pointer',
                            '&:hover': {
                                borderColor: 'primary.main',
                                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                transform: 'translateX(8px)',
                            },
                        }}
                        onClick={() => onPromoSelect(category, promo.id)}
                    >
                        <Box sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: isSelected
                                            ? 'primary.main'
                                            : alpha(theme.palette.primary.main, 0.04),
                                        borderRadius: 1,
                                        width: 64,
                                        height: 64,
                                        transition: 'background-color 0.2s',
                                        my:'auto',
                                    }}
                                >
                                    <IconComponent
                                        sx={{
                                            fontSize: 28,
                                            color: isSelected ? 'white' : 'theme.palette.primary.main',
                                        }}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                            fontWeight: 600,
                                            color: isSelected ? 'primary.main' : 'text.primary',
                                        }}
                                    >
                                        {promo.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ mt: 0.5 }}
                                    >
                                        {promo.description}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            display: 'block',
                                            mt: 1,
                                            color: 'primary.main',
                                            fontStyle: 'italic',
                                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                                            py: 0.5,
                                            px: 1,
                                            borderRadius: 0.5,
                                            width: 'fit-content'
                                        }}
                                    >
                                        {promo.example}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                );
            })}
        </Stack>
    );
};

const PromoSelection = ({
    editMode,
    planData,
    selectedCategories,
    selectedPromos,
    onCategoryChange,
    onPromoSelect,
    promotionsByCategory,
    isActive,
    isCustomized,
    onToggleChange
}) => {
    const [features, setFeatures] = useState([]);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [yearlyPriceError, setYearlyPriceError] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [localPlanData, setLocalPlanData] = useState(planData || {
        name: '',
        description: '',
        planFeatures: [],
        price_monthly: '',
        price_yearly: ''
    });

    useEffect(() => {
        if (editMode && planData) {
            setLocalPlanData(planData);
            console.log('Initial planData:', planData);
        }
    }, [editMode, planData]);

    // Automatically calculate suggested yearly price (10 months)
    useEffect(() => {
        if (localPlanData.price_monthly) {
            const suggestedYearlyPrice = parseFloat(localPlanData.price_monthly) * 10;
            setLocalPlanData(prev => ({
                ...prev,
                price_yearly: suggestedYearlyPrice.toString()
            }));
        }
    }, [localPlanData.price_monthly]);

    // Validate yearly price is less than 12x monthly price
    useEffect(() => {
        if (localPlanData.price_monthly && localPlanData.price_yearly) {
            const monthlyTotal = parseFloat(localPlanData.price_monthly) * 12;
            const yearlyPrice = parseFloat(localPlanData.price_yearly);

            if (yearlyPrice >= monthlyTotal) {
                setYearlyPriceError('Yearly price should be less than 12x monthly price to offer savings');
            } else {
                setYearlyPriceError('');
            }
        }
    }, [localPlanData.price_monthly, localPlanData.price_yearly]);

    const calculateSavingsPercentage = () => {
        if (localPlanData.price_monthly && localPlanData.price_yearly) {
            const yearlyTotal = parseFloat(localPlanData.price_monthly) * 12;
            const yearlyPrice = parseFloat(localPlanData.price_yearly);
            const savings = ((yearlyTotal - yearlyPrice) / yearlyTotal) * 100;
            return savings.toFixed(1);
        }
        return '0';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [featuresResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/features`)
                ]);
                setFeatures(featuresResponse.data.data.features || []);
            } catch (err) {
                setError('Failed to fetch data');
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            if (yearlyPriceError) {
                setSnackbar({
                    open: true,
                    message: 'Please fix pricing errors before saving',
                    severity: 'error'
                });
                return;
            }

            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
            
            // If we're editing, update existing features
            if (editMode && planData?.id) {
                // Process each feature in localPlanData
                for (const planFeature of localPlanData.planFeatures) {
                    // Update or create feature
                    await axios.put(`${API_BASE_URL}/plan-features`, {
                        plan_id: planData.id,
                        feature_id: planFeature.feature_id,
                        max_limit: planFeature.max_limit || null,
                        label: planFeature.label || null,
                        is_active: isActive,
                        is_customized: isCustomized,
                        price_monthly: parseFloat(localPlanData.price_monthly),
                        price_yearly: parseFloat(localPlanData.price_yearly)
                    });
                }
                
                // Get existing features to find removed ones
                const existingFeatures = await axios.get(`${API_BASE_URL}/plan-features?plan_id=${planData.id}`);
                const currentFeatureIds = new Set(localPlanData.planFeatures.map(pf => pf.feature_id));
                
                // Deactivate removed features
                for (const existingFeature of existingFeatures.data.planFeatures) {
                    if (!currentFeatureIds.has(existingFeature.feature_id)) {
                        await axios.put(`${API_BASE_URL}/plan-features`, {
                            plan_id: planData.id,
                            feature_id: existingFeature.feature_id,
                            max_limit: null,
                            label: null,
                            is_active: false,
                            is_customized: isCustomized,
                            price_monthly: parseFloat(localPlanData.price_monthly),
                            price_yearly: parseFloat(localPlanData.price_yearly)
                        });
                    }
                }
            } else {
                // Create new plan features
                for (const planFeature of localPlanData.planFeatures) {
                    await axios.post(`${API_BASE_URL}/plan-features`, {
                        plan_id: localPlanData.id,
                        feature_id: planFeature.feature_id,
                        max_limit: planFeature.max_limit || null,
                        label: planFeature.label || null,
                        is_active: isActive,
                        is_customized: isCustomized
                    });
                }
            }
            
            setSnackbar({
                open: true,
                message: 'Plan features saved successfully',
                severity: 'success'
            });
            
        } catch (error) {
            console.error('Error saving plan features:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.error?.message || 'Error saving plan features',
                severity: 'error'
            });
        }
    };

    const handleLimitChange = (featureId, value) => {
        setLocalPlanData(prevData => {
            const newData = {
                ...prevData,
                planFeatures: prevData.planFeatures.map(pf =>
                    pf.feature_id === featureId ? { ...pf, max_limit: value } : pf
                )
            };
            console.log('After limit change:', { featureId, value, newData });
            return newData;
        });
    };

    const handleLabelChange = (featureId, value) => {
        setLocalPlanData(prevData => {
            const newData = {
                ...prevData,
                planFeatures: prevData.planFeatures.map(pf =>
                    pf.feature_id === featureId ? { ...pf, label: value } : pf
                )
            };
            console.log('After label change:', { featureId, value, newData });
            return newData;
        });
    };

    const handleFeatureChange = (featureId, isChecked) => {
        setLocalPlanData(prevData => {
            const newData = {
                ...prevData,
                planFeatures: isChecked
                    ? [...prevData.planFeatures, { feature_id: featureId }]
                    : prevData.planFeatures.filter(pf => pf.feature_id !== featureId)
            };
            console.log('After feature toggle:', { featureId, isChecked, newData });
            return newData;
        });
    };

    const renderFeatureHierarchy = (feature) => {
        const hasChildren = Array.isArray(feature?.children) && feature.children.length > 0;
        const isFeatureSelected = localPlanData.planFeatures?.some(pf => pf.feature_id === feature.id);
        
        return (
            <Box 
                key={feature.id} 
                sx={{ 
                    ml: feature.parent_id ? 3 : 0,
                    py: 0.5,
                    borderRadius: 1,
                    '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.02)'
                    }
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isFeatureSelected}
                                onChange={(e) => handleFeatureChange(feature.id, e.target.checked)}
                                size="small"
                                sx={{ 
                                    color: 'text.secondary',
                                    '&.Mui-checked': {
                                        color: 'primary.main'
                                    }
                                }}
                            />
                        }
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        color: isFeatureSelected ? 'text.primary' : 'text.secondary',
                                        fontWeight: hasChildren ? 500 : 400
                                    }}
                                >
                                    {feature.name}
                                </Typography>
                            </Box>
                        }
                        sx={{ m: 0 }}
                    />
                    {!hasChildren && isFeatureSelected && (
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <TextField
                                label="Limit"
                                type="number"
                                size="small"
                                value={localPlanData.planFeatures.find(pf => pf.feature_id === feature.id)?.max_limit || ''}
                                onChange={(e) => handleLimitChange(feature.id, e.target.value)}
                                sx={{ 
                                    width: '90px',
                                    '& .MuiOutlinedInput-root': {
                                        height: '32px',
                                        backgroundColor: 'background.paper'
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.75rem',
                                        transform: 'translate(14px, -6px) scale(0.75)'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <TextField
                                label="Label"
                                size="small"
                                value={localPlanData.planFeatures.find(pf => pf.feature_id === feature.id)?.label || ''}
                                onChange={(e) => handleLabelChange(feature.id, e.target.value)}
                                sx={{ 
                                    width: '150px',
                                    '& .MuiOutlinedInput-root': {
                                        height: '32px',
                                        backgroundColor: 'background.paper'
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.75rem',
                                        transform: 'translate(14px, -6px) scale(0.75)'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Box>
                    )}
                </Box>
                {hasChildren && (
                    <Box>
                        {feature.children.map(childFeature => renderFeatureHierarchy(childFeature))}
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box component="form" onSubmit={handleSubmit} maxWidth="md">
            <Typography variant="h6" sx={{ mb: 2 }}>
                {editMode ? 'Edit Plan' : 'Create Plan'}
            </Typography>

            <TextField
                fullWidth
                label="Plan Name"
                value={localPlanData.name}
                onChange={(e) => setLocalPlanData({ ...localPlanData, name: e.target.value })}
                margin="normal"
            />

            <TextField
                fullWidth
                label="Description"
                value={localPlanData.description}
                onChange={(e) => setLocalPlanData({ ...localPlanData, description: e.target.value })}
                margin="normal"
                multiline
                rows={4}
            />

            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
                <Box sx={{ flex: 1 }}>
                    <TextField
                        fullWidth
                        label="Monthly Price"
                        type="number"
                        value={localPlanData.price_monthly}
                        onChange={(e) => setLocalPlanData({
                            ...localPlanData,
                            price_monthly: e.target.value
                        })}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                        }}
                    />
                    <FormHelperText>Base monthly price</FormHelperText>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <TextField
                        fullWidth
                        label="Yearly Price"
                        type="number"
                        value={localPlanData.price_yearly}
                        onChange={(e) => setLocalPlanData({
                            ...localPlanData,
                            price_yearly: e.target.value
                        })}
                        error={!!yearlyPriceError}
                        helperText={yearlyPriceError || (localPlanData.price_monthly && localPlanData.price_yearly &&
                            `Save ${calculateSavingsPercentage()}% with yearly billing`)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                        }}
                    />
                </Box>
            </Box>

            {editMode && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Plan Settings</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isActive}
                                    onChange={(e) => onToggleChange('is_active', e.target.checked)}
                                />
                            }
                            label="Active"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isCustomized}
                                    onChange={(e) => onToggleChange('is_customized', e.target.checked)}
                                />
                            }
                            label="Hide from Merchants Plan"
                        />
                    </Box>
                </Box>
            )}

            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Features
            </Typography>

            {features && features.length > 0 && (
                <FeatureHierarchy
                    features={features}
                    localPlanData={localPlanData}
                    handleFeatureChange={handleFeatureChange}
                    handleLimitChange={handleLimitChange}
                    handleLabelChange={handleLabelChange}
                />
            )}

            <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                Save Changes
            </Button>

            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PromoSelection;