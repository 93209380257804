import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    TextField,
    Box,
    Typography,
    Divider,
    Autocomplete,
    Snackbar,
    FormControlLabel,
    Switch
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from "@mui/system";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '212px',
}));

const FeatureForm = ({ onSuccess, onClose, editFeature = null }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    // Form states
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPopulateByDefault, setIsPopulateByDefault] = useState(false);
    const [parentFeature, setParentFeature] = useState(null);
    const [availableFeatures, setAvailableFeatures] = useState([]);

    // Initialize form with edit data if provided
    useEffect(() => {
        if (editFeature) {
            setName(editFeature.name || '');
            setDescription(editFeature.description || '');
            setIsPopulateByDefault(editFeature.is_populate_by_default || false);
            if (editFeature.parent) {
                setParentFeature(editFeature.parent);
            }
        }
    }, [editFeature]);

    // Fetch available features for parent selection
    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/features`);
                // Filter out the current feature and its children from available parents
                const features = response.data.data.features || [];
                const filteredFeatures = editFeature 
                    ? features.filter(f => f.id !== editFeature.id && !isDescendant(f, editFeature.id))
                    : features;
                setAvailableFeatures(filteredFeatures);
            } catch (err) {
                console.error('Error fetching features:', err);
                setError('Failed to fetch available features');
            }
        };
        fetchFeatures();
    }, [editFeature]);

    // Helper function to check if a feature is a descendant of another
    const isDescendant = (feature, parentId) => {
        if (!feature.children) return false;
        return feature.children.some(child => 
            child.id === parentId || isDescendant(child, parentId)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const featureData = {
                name,
                description,
                is_populate_by_default: isPopulateByDefault,
                parent_id: parentFeature?.id || null
            };

            let response;
            if (editFeature) {
                response = await axios.put(`${API_BASE_URL}/features?id=${editFeature.id}`, featureData);
            } else {
                response = await axios.post(`${API_BASE_URL}/features`, featureData);
            }
            
            // Call onSuccess with the new/updated feature data
            if (onSuccess) {
                onSuccess(response.data.data.feature);
            }
            
            // Show success message
            setSuccess(true);
            
            // Auto close dialog after success
            setTimeout(() => {
                onClose && onClose();
            }, 1500);
        } catch (err) {
            setError(err.response?.data?.error?.message || 'Failed to save feature');
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
        setError(null);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} p={3}>
            <Typography variant="h6" gutterBottom>
                {editFeature ? 'Edit Feature' : 'Add New Feature'}
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <TextFieldTitle gutterBottom>
                        Name*
                    </TextFieldTitle>
                    <TextField
                        fullWidth
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter feature name"
                        size="small"
                    />
                </Box>

                <Box>
                    <TextFieldTitle gutterBottom>
                        Description
                    </TextFieldTitle>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter feature description"
                        size="small"
                    />
                </Box>

                <Box>
                    <TextFieldTitle gutterBottom>
                        Parent Feature
                    </TextFieldTitle>
                    <Autocomplete
                        size="small"
                        options={availableFeatures}
                        value={parentFeature}
                        onChange={(event, newValue) => {
                            setParentFeature(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select parent feature"
                            />
                        )}
                    />
                </Box>

                <FormControlLabel
                    control={
                        <Switch
                            checked={isPopulateByDefault}
                            onChange={(e) => setIsPopulateByDefault(e.target.checked)}
                            name="isPopulateByDefault"
                        />
                    }
                    label="Populate by Default"
                />

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (editFeature ? 'Saving...' : 'Creating...') : (editFeature ? 'Save Changes' : 'Create Feature')}
                    </Button>
                </Box>
            </Box>

            <Snackbar
                open={success || error !== null}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={success ? "success" : "error"}
                >
                    {success 
                        ? (editFeature ? "Feature updated successfully!" : "Feature created successfully!") 
                        : error
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default FeatureForm;
