import { Box, Tabs, Tab, Divider, Typography, Grid } from '@mui/material';
import BookingOverviewAll from "./BookingOverviewAll";
import BookingOverviewCurrent from "./BookingOverviewCurrent";
import BookingOverviewUpcoming from "./BookingOverviewUpcoming";
import BookingOverviewPast from "./BookingOverviewPast";
import {
    CalendarMonth as BookingsIcon,
    PaidOutlined as RevenueIcon,
    CurrencyExchange as ValueIcon,
    CancelScheduleSend as CancelIcon
} from '@mui/icons-material';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";
import { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BookingOverview = () => {
    const [value, setValue] = useState(0);
    const [monthlyBookings, setMonthlyBookings] = useState(0);
    const [todayTotalRevenue, setTodayTotalRevenue] = useState(0);
    const [cancelledBookings, setCancelledBookings] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [bookingsResponse, revenueResponse, cancelledResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/purchases?purchase_type=3&status_id=3`),
                    axios.get(`${API_BASE_URL}/purchases`, {
                        params: {
                            today_total_revenue: true,
                            purchase_type: 3,
                            status_id: 3
                        }
                    }),
                    axios.get(`${API_BASE_URL}/purchases`, {
                        params: {
                            cancelled_count_by_type: true
                        }
                    })
                ]);
                setMonthlyBookings(bookingsResponse.data.data.total || 0);
                setTodayTotalRevenue(revenueResponse.data.data.todayTotalRevenue || 0);
                setCancelledBookings(cancelledResponse.data.data['3'] || 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>Booking Overview</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>All of your bookings.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/*add buttons here*/}
                    </Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Monthly Bookings"
                            percent={3.2}
                            total={monthlyBookings}
                            icon={<BookingsIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Today's Revenue"
                            percent={4.5}
                            total={todayTotalRevenue}
                            color="secondary"
                            icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Average Booking Value"
                            percent={1.8}
                            total={542}
                            color="warning"
                            icon={<ValueIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [400, 250, 480, 320, 500, 380, 450, 542],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Cancelled Bookings"
                            percent={-0.5}
                            total={cancelledBookings}
                            color="error"
                            icon={<CancelIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [220, 280, 180, 250, 160, 200, 140, 124],
                            }}
                        />
                    </Grid>
                </Grid>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="All" />
                        <Tab label="Current" />
                        <Tab label="Upcoming" />
                        <Tab label="Past" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '10px 0 10px 0'}}/>

                <Box>
                    { value === 0 && <BookingOverviewAll/> }
                    { value === 1 && <BookingOverviewCurrent/> }
                    { value === 2 && <BookingOverviewUpcoming/> }
                    { value === 3 && <BookingOverviewPast/> }
                </Box>
            </Box>
        </Box>
    );
};

export default BookingOverview;