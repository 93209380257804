import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useKeycloak } from '../../contexts/KeycloakContext';

const PublicLayout = () => {
    const { isAuthenticated } = useKeycloak();

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <Box sx={{ flex: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default PublicLayout;
