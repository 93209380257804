import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Chip,
    TextField,
    InputAdornment,
    Grid,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../../../utils/axios';
import { formatDistanceToNow } from 'date-fns';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { LoadingButton } from '@mui/lab';

const StatusChip = ({ status }) => {
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return 'success';
            case 'pending':
                return 'warning';
            case 'failed':
                return 'error';
            case 'cancelled':
                return 'default';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={status}
            color={getStatusColor(status)}
            size="small"
            sx={{ textTransform: 'capitalize' }}
        />
    );
};

const AdminTicketingTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [cancelling, setCancelling] = useState(false);

    const fetchTransactions = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/platform/ticketing-transactions', {
                params: {
                    page: page + 1,
                    limit: rowsPerPage,
                    search: searchQuery,
                    startDate: startDate?.toISOString(),
                    endDate: endDate?.toISOString()
                }
            });
            setTransactions(response.data.transactions);
            setTotalCount(response.data.total);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [page, rowsPerPage, searchQuery, startDate, endDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-MY', {
            style: 'currency',
            currency: 'MYR',
            minimumFractionDigits: 2
        }).format(amount);
    };

    const formatChargeDate = (date) => {
        const chargeDate = new Date(date);
        const today = new Date();
        
        if (chargeDate > today) {
            return `In ${formatDistanceToNow(chargeDate)}`;
        } else {
            return formatDistanceToNow(chargeDate, { addSuffix: true });
        }
    };

    const handleCancelClick = (transaction) => {
        setSelectedTransaction(transaction);
        setCancelDialogOpen(true);
    };

    const handleCancelConfirm = async () => {
        if (!selectedTransaction) return;

        try {
            setCancelling(true);
            const response = await axios.post('/platform/ticketing-transactions/cancel', {
                id: selectedTransaction.id
            });
            
            if (response.data.success) {
                // Update the transaction in the local state
                setTransactions(prevTransactions => 
                    prevTransactions.map(t => 
                        t.id === selectedTransaction.id 
                            ? { ...t, status: 'failed', error_message: 'Cancelled by admin' }
                            : t
                    )
                );
            }
            setCancelDialogOpen(false);
        } catch (error) {
            console.error('Error cancelling transaction:', error);
            alert(error.response?.data?.error || 'Failed to cancel transaction');
        } finally {
            setCancelling(false);
            setSelectedTransaction(null);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Ticketing Transactions
            </Typography>

            {/* Filters */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        placeholder="Search organizations..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={setStartDate}
                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={setEndDate}
                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                            minDate={startDate}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            {/* Transactions Table */}
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Organization</TableCell>
                                <TableCell>Period</TableCell>
                                <TableCell>Total Tickets</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Charge Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Payment Method</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">Loading...</TableCell>
                                </TableRow>
                            ) : transactions.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">No transactions found</TableCell>
                                </TableRow>
                            ) : (
                                transactions.map((transaction) => (
                                    <TableRow key={transaction.id}>
                                        <TableCell>{transaction.organization?.name}</TableCell>
                                        <TableCell>
                                            {new Date(transaction.period_start).toLocaleDateString()} - {new Date(transaction.period_end).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>{transaction.total_tickets}</TableCell>
                                        <TableCell>{formatAmount(transaction.amount)}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body2">
                                                    {new Date(transaction.scheduled_date).toLocaleDateString()}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {formatChargeDate(transaction.scheduled_date)}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <StatusChip status={transaction.status} />
                                        </TableCell>
                                        <TableCell>
                                            {transaction.organization?.default_payment_method_id ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <CreditCardIcon sx={{ fontSize: 20, mr: 1 }} />
                                                    <Typography variant="body2">
                                                        Default Card
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary">
                                                    Debit/Credit Card
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.status === 'pending' && (
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={() => handleCancelClick(transaction)}
                                                    title="Cancel Transaction"
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                />
            </Paper>

            {/* Cancel Confirmation Dialog */}
            <Dialog
                open={cancelDialogOpen}
                onClose={() => !cancelling && setCancelDialogOpen(false)}
            >
                <DialogTitle>Cancel Transaction</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to cancel this transaction? This action cannot be undone.
                    </DialogContentText>
                    {selectedTransaction && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body2">
                                <strong>Organization:</strong> {selectedTransaction.organization?.name}
                            </Typography>
                            <Typography variant="body2">
                                <strong>Amount:</strong> {formatAmount(selectedTransaction.amount)}
                            </Typography>
                            <Typography variant="body2">
                                <strong>Charge Date:</strong> {new Date(selectedTransaction.scheduled_date).toLocaleDateString()}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setCancelDialogOpen(false)} 
                        disabled={cancelling}
                    >
                        No, Keep It
                    </Button>
                    <LoadingButton
                        onClick={handleCancelConfirm}
                        loading={cancelling}
                        color="error"
                        variant="contained"
                    >
                        Yes, Cancel Transaction
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminTicketingTransactions;
