import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useKeycloak } from '../../../contexts/KeycloakContext';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    Divider,
    Snackbar,
    Alert,
    Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { ViewIcon, EditIcon, DeleteIcon } from '../IconButtons';
import AddIcon from '@mui/icons-material/Add';
import { fetchEvents, updateEvent, deleteEvent } from '../../../redux/eventsSlice';
import EditTicketingDialog from './EditTicketingDialog';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import dayjs from 'dayjs';
import { useOrganizationPlan } from '../useOrganizationRoles';
import axios from 'axios';
import PermissionGate from '../../shared/PermissionGate';

const images = ['/1.jpeg', '/2.jpeg', '/3.jpeg', '/4.jpeg', '/5.jpeg', '/6.jpeg'];

const TicketingList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const eventsData = useSelector(state => state.events.events);
    const { pagination, status } = useSelector(state => state.events);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const organizationPlan = useOrganizationPlan() || 'default';
    const [ticketLimit, setTicketLimit] = useState(null);
    const [ticketCount, setTicketCount] = useState(0);
    const [isLoadingPlan, setIsLoadingPlan] = useState(true);
    const [planError, setPlanError] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { userInfo } = useKeycloak();

    useEffect(() => {
        const fetchOrganizationPlan = async () => {
            if (!userInfo?.organization_id) {
                setPlanError('Organization ID not available');
                setIsLoadingPlan(false);
                return;
            }
            
            try {
                setIsLoadingPlan(true);
                setPlanError(null);
                const response = await axios.get(`${API_BASE_URL}/organization-plans?organizationId=${userInfo.organization_id}`);
                setTicketLimit(response.data.ticketLimit);
            } catch (error) {
                console.error('Failed to fetch organization plan:', error);
                setPlanError(error.message);
            } finally {
                setIsLoadingPlan(false);
            }
        };

        fetchOrganizationPlan();
    }, [userInfo]);

    useEffect(() => {
        // Fetch the current ticket count when the component mounts
        // This is a placeholder. You should replace this with an actual API call
        setTicketCount(eventsData.length);
    }, [eventsData]);

    const isTicketLimitReached = () => {
        if (organizationPlan.includes('starter')) {
            return ticketCount >= ticketLimit; // For testing, limit to 3 tickets
        }
        return false; // No limit for other plans
    };

    const handleUpgradeClick = event => {
        event.preventDefault();
        navigate('/admin/admin-subscription-plans');
    };

    useEffect(() => {
        dispatch(
            fetchEvents({
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                searchQuery: searchQuery,
            })
        ).then(() => {
            setIsInitialLoad(false);
        });
    }, [paginationModel, searchQuery, dispatch]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const handleEditClick = event => {
        setEventToEdit(event);
        navigate('/settings/ticketing-setup/edit-events', { state: { event } });
    };

    const handleEditCancel = () => {
        setEditDialogOpen(false);
        setEventToEdit(null);
    };

    const handleEditConfirm = editedEvent => {
        setIsEditing(true);
        dispatch(updateEvent(editedEvent))
            .unwrap()
            .then(() => {
                setSnackbarMessage('Event updated successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                dispatch(
                    fetchEvents({
                        page: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                        searchQuery: searchQuery,
                    })
                );
            })
            .catch(error => {
                setSnackbarMessage('Failed to update event: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setEditDialogOpen(false);
                setEventToEdit(null);
                setIsEditing(false);
            });
    };

    const handleDeleteClick = eventId => {
        setEventToDelete(eventId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        setIsDeleting(true);
        dispatch(deleteEvent(eventToDelete))
            .unwrap()
            .then(() => {
                setSnackbarMessage('Event deleted successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                dispatch(
                    fetchEvents({
                        page: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                        searchQuery: searchQuery,
                    })
                );
            })
            .catch(error => {
                setSnackbarMessage('Failed to delete event: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
                setEventToDelete(null);
                setIsDeleting(false);
            });
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setEventToDelete(null);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{ padding: '0 32px 24px 32px' }}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant='rectangular' width={60} height={40} sx={{ mr: 5, borderRadius: '4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant='text' width='100%' />
                        <Skeleton variant='text' width='100%' />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const rows = useMemo(() => {
        // console.log(eventsData);
        return (
            eventsData?.map(event => ({
                ...event,
                id: event?.id,
                name: event?.name,
                description: event?.description || '',
                startDate: event?.event_start_date,
                endDate: event?.event_end_date,
                minPax: event?.minimum_pax,
                maxPax: event?.maximum_pax,
                eventType: event?.event_type?.name,
                status: 'Active',
                isDailyEvents: '-',
            })) || []
        );
    }, [eventsData]);

    const formatDate = date => {
        return date ? dayjs(date).format('DD/MM/YYYY') : '';
    };

    const columns = [
        {
            field: 'image',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        component='img'
                        sx={{
                            width: '60px',
                            height: '45px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            objectFit: 'cover',
                        }}
                        alt='Event Image'
                        src={
                            params.row.event_images && params.row.event_images.length > 0
                                ? params.row.event_images[0].image_url
                                : images[0]
                        }
                    />
                </Box>
            ),
        },
        {
            field: 'eventType',
            headerName: 'Event Type',
            flex: 0.5,
            minWidth: 150,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.6,
            minWidth: 350,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{params?.row?.name || 'N/A'}</Typography>
                    <Typography variant='caption' color='textSecondary' noWrap>
                        {params?.row?.description}
                    </Typography>
                </Box>
            ),
        },
        // {
        //     field: 'name',
        //     headerName: 'Name',
        //     flex: 0.6,
        //     minWidth: 120,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
        //             <Typography variant="body2">{params.value}</Typography>
        //         </Box>
        //     )
        // },
        // {
        //     field: 'description',
        //     headerName: 'Description',
        //     flex: 0.7,
        //     minWidth: 140,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
        //             <Typography variant="body2" noWrap>{params.value}</Typography>
        //         </Box>
        //     )
        // },
        {
            field: 'startDate',
            headerName: 'Start Date',
            flex: 1,
            minWidth: 140,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{formatDate(params.row.startDate)}</Typography>
                </Box>
            ),
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            flex: 1,
            minWidth: 140,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{formatDate(params.row.endDate)}</Typography>
                </Box>
            ),
        },
        {
            field: 'minPax',
            headerName: 'Min. Pax',
            flex: 1,
            minWidth: 100,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{`${params.row.minimum_pax || ''}`}</Typography>
                </Box>
            ),
        },
        {
            field: 'maxPax',
            headerName: 'Max. Pax',
            flex: 1,
            minWidth: 100,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant='body2'>{`${params.row.maximum_pax || ''}`}</Typography>
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1.5,
            minWidth: 150,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <PermissionGate featureCode='RDTCK' fallback={null}>
                        <IconButton aria-label='view'>
                            <ViewIcon />
                        </IconButton>
                    </PermissionGate>
                    <PermissionGate featureCode='UPDTCK' fallback={null}>
                        <IconButton aria-label='edit' onClick={() => handleEditClick(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </PermissionGate>
                    <PermissionGate featureCode='DELTCK' fallback={null}>
                        <IconButton aria-label='delete' onClick={() => handleDeleteClick(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </PermissionGate>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <Box flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography variant='h4'>Ticketing Setup</Typography>
                        <Typography variant='h5'>View, purchase, and manage all your tickets in one place.</Typography>
                        {isTicketLimitReached() && (
                            <Alert
                                severity='warning'
                                sx={{
                                    mt: 2,
                                    '& a': {
                                        color: 'inherit',
                                        textDecoration: 'underline',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: 'primary.main',
                                        },
                                    },
                                }}
                            >
                                You have reached the maximum number of tickets ({ticketCount}) for your starter plan.{' '}
                                <a href='/admin/admin-subscription-plans' onClick={handleUpgradeClick}>
                                    Upgrade your plan
                                </a>
                            </Alert>
                        )}
                        {organizationPlan === 'starter' && !isTicketLimitReached() && (
                            <Alert severity='info' sx={{ mt: 2 }}>
                                Your starter plan allows up to 3 tickets. You currently have {ticketCount} ticket(s).
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PermissionGate featureCode='CRTTCK' fallback={null}>
                            <Button
                                component={Link}
                                to='/settings/ticketing-setup/add-ticketing'
                                variant='save'
                                startIcon={<AddIcon />}
                                disabled={isTicketLimitReached()}
                            >
                                Add New Ticketing
                            </Button>
                        </PermissionGate>
                    </Box>
                </Box>

                <Divider />

                <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                    <Box />
                    <Box display='flex' gap='12px'>
                        <TextField
                            variant='outlined'
                            placeholder='Search by Name'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size='small'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: '100%',
                        borderRadius: '16px',
                        marginTop: '24px',
                        border: '1px solid var(--Gray-200, #EAECF0)',
                        background: 'var(--White, #FFF)',
                        boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                    }}
                >
                    <Typography
                        sx={{
                            color: 'var(--Gray-900, #101828)',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '28px',
                            padding: '20px 24px',
                        }}
                    >
                        All Tickets
                    </Typography>

                    <Divider sx={{ my: 0 }} />

                    {isInitialLoad || status === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode='server'
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={status === 'loading'}
                        />
                    )}
                </Paper>
            </Box>

            {/* Edit Event Dialog */}
            <EditTicketingDialog
                open={editDialogOpen}
                onClose={handleEditCancel}
                onConfirm={handleEditConfirm}
                event={eventToEdit}
                isEditing={isEditing}
            />

            {/* Delete Confirmation Dialog */}
            <DeleteConfirmationDialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                isDeleting={isDeleting}
            />

            {/* Snackbar for notifications */}
            {/*<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>*/}
            {/*    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>*/}
            {/*        {snackbarMessage}*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default TicketingList;
