import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { authService } from '../services/auth.service';

const KeycloakContext = createContext(null);

export const useKeycloak = () => {
    const context = useContext(KeycloakContext);
    if (!context) {
        throw new Error('useKeycloak must be used within a KeycloakProvider');
    }
    return context;
};

export const KeycloakProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [organizationRoles, setOrganizationRoles] = useState([]);
    const refreshTimerRef = useRef(null);

    const loadUserInfo = useCallback(async () => {
        try {
            const info = await authService.getUserInfo();
            console.log('User info loaded:', info);
            setUserInfo(info);
            setOrganizationRoles(info.organization_roles || []);
            return true;
        } catch (error) {
            console.error('Failed to load user info:', error);
            setUserInfo(null);
            setOrganizationRoles([]);
            return false;
        }
    }, []);

    const updateUserInfo = useCallback(async () => {
        try {
            const info = await authService.getUserInfo();
            setUserInfo(info);
            setOrganizationRoles(info.organization_roles || []);
        } catch (error) {
            console.error('Error updating user info:', error);
            setUserInfo(null);
            setOrganizationRoles([]);
        }
    }, []);

    const handleLogout = useCallback(async () => {
        try {
            await authService.logout();
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            setIsAuthenticated(false);
            setUserInfo(null);
            setOrganizationRoles([]);
            if (refreshTimerRef.current) {
                clearInterval(refreshTimerRef.current);
            }
            window.location.href = '/login';
        }
    }, []);

    const setupRefreshTimer = useCallback(() => {
        if (refreshTimerRef.current) {
            clearInterval(refreshTimerRef.current);
        }

        // Refresh token 30 seconds before expiry
        refreshTimerRef.current = setInterval(async () => {
            try {
                if (authService.isTokenExpired()) {
                    await authService.refreshToken();
                    // After successful refresh, update user info
                    await loadUserInfo();
                }
            } catch (error) {
                console.error('Token refresh failed:', error);
                // If refresh fails, trigger logout
                setIsAuthenticated(false);
                setUserInfo(null);
                setOrganizationRoles([]);
                clearInterval(refreshTimerRef.current);
                window.location.href = '/login';
            }
        }, 30000); // Check every 30 seconds
    }, [loadUserInfo]);

    // Check authentication status on mount
    useEffect(() => {
        let mounted = true;

        const checkAuth = async () => {
            try {
                // First check if we have any tokens
                const token = authService.getAccessToken();
                const refreshToken = authService.getRefreshToken();

                if (!token && !refreshToken) {
                    // No tokens available, user needs to log in
                    if (mounted) {
                        setIsAuthenticated(false);
                        setIsLoading(false);
                    }
                    return;
                }

                // Try to ensure we have a valid token
                const validToken = await authService.ensureValidToken();
                if (validToken && mounted) {
                    const userInfoLoaded = await loadUserInfo();
                    if (mounted) {
                        setIsAuthenticated(userInfoLoaded);
                        if (userInfoLoaded) {
                            setupRefreshTimer();
                        }
                    }
                }
            } catch (error) {
                console.error('Auth check failed:', error);
                if (mounted) {
                    setIsAuthenticated(false);
                    // Redirect to login if we have an authentication error
                    if (error.message.includes('Please log in')) {
                        window.location.href = '/login';
                    }
                }
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        checkAuth();

        return () => {
            mounted = false;
            if (refreshTimerRef.current) {
                clearInterval(refreshTimerRef.current);
            }
        };
    }, [setupRefreshTimer, loadUserInfo]);

    const login = async (email, password) => {
        try {
            await authService.login(email, password);
            const userInfoLoaded = await loadUserInfo();
            if (userInfoLoaded) {
                setIsAuthenticated(true);
                setupRefreshTimer();
            }
            return true;
        } catch (error) {
            console.error('Login failed:', error);
            return false;
        }
    };

    const value = {
        isAuthenticated,
        isLoading,
        userInfo,
        organizationRoles,
        login,
        logout: handleLogout,
        updateUserInfo,
    };

    return (
        <KeycloakContext.Provider value={value}>
            {children}
        </KeycloakContext.Provider>
    );
};

export default KeycloakContext;
