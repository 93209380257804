import {
    Card,
    Button,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Container
} from '@mui/material';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { styled, alpha } from '@mui/material/styles';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useEffect, useState } from "react";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledCard = styled(Card, {
    shouldForwardProp: prop => prop !== 'isFeatured',
})(({ theme, isFeatured }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${isFeatured ? '#ffeb3b' : theme.palette.divider}`,
    borderRadius: 16,
    boxShadow: isFeatured ? `0 0 0 2px #ffeb3b` : 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    position: 'relative',
    '&:before': isFeatured ? {
        content: '""',
        position: 'absolute',
        top: -2,
        right: -2,
        bottom: -2,
        left: -2,
        background: 'linear-gradient(45deg, #ffeb3b, #ffd700)',
        borderRadius: '18px',
        zIndex: -1,
    } : {},
    transition: 'transform 0.2s ease-in-out',
}));

const HeaderSection = styled(Box)(() => ({
    minHeight: 140,
    marginBottom: 24,
}));

const PriceSection = styled(Box)(() => ({
    position: 'relative',
    marginBottom: 24,
}));

const FeaturesSection = styled(Box)(() => ({
    flex: 1,
}));

const FeatureList = styled(List)(() => ({
    '& .MuiListItem-root': {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
}));

const FeaturedBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: -8,
    left: -1,
    right: -1,
    background: '#ffeb3b',
    color: 'rgba(0,0,0,0.87)',
    padding: '6px 24px',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 600,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    zIndex: 1,
    border: '1px solid #ffeb3b',
    borderBottom: 'none',
}));

const DiscountBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: -8,
    right: 0,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    padding: '2px 8px',
    borderRadius: '12px',
    fontSize: '0.75rem',
    fontWeight: 500,
}));

const CollaborationPlans = () => {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${API_BASE_URL}/organization-plans`);
                setPlans(response.data.plans);
            } catch (error) {
                console.error('Error fetching plans:', error);
                setError('Failed to fetch collaboration plans');
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);

    const organizeFeatures = (features = []) => {
        if (!Array.isArray(features)) {
            console.warn('Features is not an array:', features);
            return [];
        }

        const featureMap = new Map();
        const rootFeatures = [];

        features.forEach(feature => {
            if (feature?.feature?.id) {
                featureMap.set(feature.feature.id, {
                    ...feature,
                    children: []
                });
            }
        });

        features.forEach(feature => {
            if (feature?.feature?.parentHierarchies?.length > 0) {
                const parentId = feature.feature.parentHierarchies[0].parentFeature.id;
                const parentFeature = featureMap.get(parentId);
                if (parentFeature) {
                    parentFeature.children.push(featureMap.get(feature.feature.id));
                }
            } else if (feature?.feature?.id) {
                rootFeatures.push(featureMap.get(feature.feature.id));
            }
        });

        return rootFeatures;
    };

    const formatLimitText = (feature) => {
        const name = feature.feature.name.toLowerCase();
        const limit = feature.max_limit;
        const min_limit = feature.min_limit;

        if (name.includes('extra')) {
            if (min_limit !== null && min_limit !== undefined) {
                return `${min_limit}-${limit} tickets: ${feature.commission_rate}%`;
            } else {
                return `${limit}+ tickets: ${feature.commission_rate}%`;
            }
        } else if (name.includes('storage') || name.includes('space')) {
            return `${limit}GB`;
        } else if (name.includes('bandwidth')) {
            return `${limit}GB/mo`;
        } else if (name.includes('user') || name.includes('member')) {
            return `${limit} users`;
        } else if (name.includes('event')) {
            return `${limit} events`;
        } else if (name.includes('product')) {
            return `${limit} products`;
        } else if (name.includes('booking')) {
            return `${limit} bookings`;
        } else {
            return `Up to ${limit}`;
        }
    };

    const FeatureItem = ({ feature, depth = 0 }) => {
        const isExtraFeesFeature = feature.feature.name.toLowerCase().includes('extra');
        
        return (
            <>
                <ListItem 
                    disablePadding 
                    sx={{ 
                        pl: depth * 3,
                        mb: feature.children?.length > 0 ? 1 : 0,
                        ...(isExtraFeesFeature && {
                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                            borderRadius: 1,
                            py: 1
                        })
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 28 }}>
                        <CheckIcon sx={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Box component="span" sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                ...(feature.children?.length > 0 && {
                                    fontWeight: 'bold'
                                })
                            }}>
                                <span>{feature.feature.name}</span>
                                {feature.max_limit !== null && feature.children?.length === 0 && (
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        sx={(theme) => ({
                                            backgroundColor: isExtraFeesFeature ? '#E3F2FD' : '#EAECF0',
                                            color: isExtraFeesFeature ? '#1976D2' : '#344054',
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: 1,
                                            fontSize: '0.75rem',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 500,
                                            border: `1px solid ${isExtraFeesFeature ? '#90CAF9' : theme.palette.action.disabledBackground}`
                                        })}
                                    >
                                        {formatLimitText(feature)}
                                    </Typography>
                                )}
                            </Box>
                        }
                        primaryTypographyProps={{
                            variant: 'body2',
                            sx: { lineHeight: 1.3 }
                        }}
                    />
                </ListItem>
                {feature.children?.map((child, index) => (
                    <FeatureItem key={index} feature={child} depth={depth + 1} />
                ))}
            </>
        );
    };

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ py: 2 }}>
                <Typography>Loading plans...</Typography>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ py: 2 }}>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    if (!plans.length) {
        return (
            <Container maxWidth="lg" sx={{ py: 2 }}>
                <Typography>No collaboration plans available.</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1">Collaboration Plans</Typography>
                <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <Box>
                        <Button
                            variant="save"
                            component={Link}
                            to="/admin/collaboration-plans/new"
                        >
                            + New Plan
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={3}>
                {plans
                    .filter(plan => plan.is_active)
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .map((plan) => (
                        <Grid item xs={12} md={4} key={plan.id}>
                            <StyledCard isFeatured={plan.is_most_popular}>
                                {plan.is_most_popular && (
                                    <FeaturedBadge>Most Popular!</FeaturedBadge>
                                )}
                                <HeaderSection>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                        <SmallText>{plan.description}</SmallText>
                                        {plan.is_most_popular && (
                                            <WorkspacePremiumIcon sx={{ color: '#FFD700', fontSize: 20 }} />
                                        )}
                                    </Box>
                                    <Typography variant="h6" component="h2" sx={{ fontWeight: 600, mb: 2 }}>
                                        {plan.name}
                                    </Typography>
                                </HeaderSection>

                                <PriceSection>
                                    {plan.has_discount && (
                                        <DiscountBadge>
                                            Save {plan.discount_percentage}%
                                        </DiscountBadge>
                                    )}
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 0.5 }}>
                                        <Typography component="span" sx={{ fontWeight: 600 }}>RM</Typography>
                                        <Typography variant="h4" component="span" sx={{ fontWeight: 600 }}>
                                            {parseFloat(plan.price_monthly).toFixed(2)}
                                        </Typography>
                                        {plan.original_price && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    textDecoration: 'line-through',
                                                    color: 'text.secondary',
                                                    ml: 1
                                                }}
                                            >
                                                RM{parseFloat(plan.original_price).toFixed(2)}
                                            </Typography>
                                        )}
                                    </Box>
                                    <SmallText>/month per organization</SmallText>
                                </PriceSection>

                                <FeaturesSection>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Extra Fees:
                                    </Typography>
                                    <FeatureList>
                                        {organizeFeatures(plan.planFeatures)
                                            .filter(feature => feature.feature.name.toLowerCase().includes('extra'))
                                            .sort((a, b) => (a.min_limit || 0) - (b.min_limit || 0))
                                            .map((feature, featureIndex) => (
                                                <FeatureItem key={featureIndex} feature={feature} />
                                            ))}
                                    </FeatureList>
                                    
                                    {/* <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
                                        Other Features:
                                    </Typography>
                                    <FeatureList>
                                        {organizeFeatures(plan.planFeatures)
                                            .filter(feature => !feature.feature.name.toLowerCase().includes('commission'))
                                            .map((feature, featureIndex) => (
                                                <FeatureItem key={featureIndex} feature={feature} />
                                            ))}
                                    </FeatureList> */}
                                </FeaturesSection>

                                <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
                                    <Button
                                        variant="save"
                                        color="primary"
                                        fullWidth
                                        component={Link}
                                        to={`/admin/collaboration-plans/${plan.id}`}
                                    >
                                        Choose Plan
                                    </Button>
                                </Box>
                            </StyledCard>
                        </Grid>
                    ))}
            </Grid>
        </Container>
    );
};

export default CollaborationPlans;
