export const agreements = [
    {
        title: "Terms of Service",
        content: [
            {
                header: "1. Introduction",
                text: "Welcome to our event e-commerce website! By accessing or using our site to purchase event tickets, you agree to comply with and be bound by the following terms and conditions. Please review them carefully. If you do not agree with any of these terms, you are prohibited from using or accessing this site."
            },
            {
                header: "2. Event Ticket Purchases",
                text: "All ticket sales are final. Once a ticket is purchased, it cannot be refunded or exchanged for another event, date, or time, except in the event of a cancellation or rescheduling by the event organizer."
            },
            {
                header: "3. Use of the Site",
                text: "You may use the site, its content, and services only for lawful purposes related to purchasing event tickets and in accordance with these Terms of Service. You agree not to use the site in any way that violates any applicable federal, state, local, or international law or regulation."
            },
            {
                header: "4. Intellectual Property Rights",
                text: "All content on this site, including but not limited to text, graphics, logos, icons, and images, is the property of our company or our content suppliers and is protected by international copyright and trademark laws."
            },
            {
                header: "5. Limitation of Liability",
                text: "In no event shall our company or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to event cancellations) arising out of the use or inability to use the materials on this site, even if our company or an authorized representative has been notified orally or in writing of the possibility of such damage."
            },
            {
                header: "6. Changes to These Terms",
                text: "We may revise these Terms of Service at any time without notice. By using this site, you agree to be bound by the then-current version of these Terms of Service."
            },
            {
                header: "7. Governing Law",
                text: "These terms and conditions are governed by and construed in accordance with the laws of [Your State/Country], and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location."
            },
            {
                header: "8. Contact Information",
                text: "If you have any questions about these Terms of Service, please contact us at support@example.com."
            }
        ]
    },
    {
        title: "Privacy Policy",
        content: [
            {
                header: "1. Introduction",
                text: "This Privacy Policy describes how we handle your personal information for our services on our event e-commerce platform. By visiting our site, you accept the practices described in this Policy."
            },
            {
                header: "2. Information We Collect",
                text: "We collect information from you when you register on our site, purchase tickets, subscribe to our newsletter, respond to a survey, or fill out a form. This includes your name, email address, mailing address, phone number, and payment information."
            },
            {
                header: "3. How We Use Your Information",
                text: "Your information helps us better respond to your individual needs, process ticket transactions, improve our website, and send periodic emails regarding your ticket purchase or other event-related updates."
            },
            {
                header: "4. Information Protection",
                text: "We implement a variety of security measures to maintain the safety of your personal information when you purchase tickets or enter, submit, or access your personal information."
            },
            {
                header: "5. Sharing of Information",
                text: "We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information except to provide services you have requested or when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety."
            },
            {
                header: "6. Cookies",
                text: "We use cookies to enhance your experience, gather general visitor information, and track tickets to our website. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information."
            }
        ]
    },
    {
        title: "Return and Refund Policy",
        content: [
            {
                header: "1. Refunds for Event Cancellations",
                text: "If an event is canceled, you will be notified as soon as possible. You will receive a refund for the face value of the ticket. Service fees are non-refundable. Refunds will be issued to the original method of payment used at the time of purchase."
            },
            {
                header: "2. Non-Refundable Tickets",
                text: "Tickets purchased for events are non-refundable and non-transferable, except in the event of an event cancellation by the organizer."
            },
            {
                header: "3. Processing Refunds",
                text: "Refunds will be processed within 7-10 business days from the date of the event cancellation notification."
            }
        ]
    },
    {
        title: "Shipping Policy",
        content: [
            {
                header: "1. Delivery of Digital Tickets",
                text: "Digital tickets will be delivered to the email address provided at the time of purchase. Please ensure that your email address is correct and up to date."
            },
            {
                header: "2. Physical Tickets",
                text: "If applicable, physical tickets will be shipped within 5-7 business days of purchase. Shipping costs are calculated based on the delivery destination and will be displayed during the checkout process."
            },
            {
                header: "3. Tracking Your Order",
                text: "Once your tickets have been shipped, you will receive an email with a tracking number and a link to track your package online."
            }
        ]
    },
    {
        title: "Payment Policy",
        content: [
            {
                header: "1. Payment Methods",
                text: "We accept various payment methods, including credit cards, debit cards, PayPal, and other payment options as specified on our site. All payments must be made in full at the time of purchase."
            },
            {
                header: "2. Pricing",
                text: "All prices listed on our site are in USD and are subject to change without notice. Prices do not include applicable taxes and service fees, which will be calculated at checkout."
            },
            {
                header: "3. Billing Information",
                text: "You agree to provide accurate and complete billing information. If your payment method is declined, we may suspend or cancel your order."
            }
        ]
    },
    {
        title: "Cancellation Policy",
        content: [
            {
                header: "1. Event Cancellation",
                text: "If an event is canceled, you will be notified via email and will receive a refund for the face value of the ticket. Service fees are non-refundable."
            },
            {
                header: "2. Voluntary Ticket Cancellations",
                text: "All ticket sales are final, and voluntary cancellations are not permitted. If you cannot attend an event, you may transfer your ticket to another individual by contacting our support team."
            }
        ]
    },
    {
        title: "User Account Policy",
        content: [
            {
                header: "1. Account Creation",
                text: "To use certain features of our site, such as purchasing tickets, you must register for an account. You agree to provide accurate, current, and complete information during the registration process."
            },
            {
                header: "2. Account Security",
                text: "You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account."
            },
            {
                header: "3. Account Termination",
                text: "We reserve the right to terminate or suspend your account at any time if we believe that you have violated these terms or for any other reason."
            }
        ]
    }
];
