import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CssBaseline, CircularProgress } from '@mui/material';
import Header from './components/header/Header';
import Sidebar from './components/Sidebar';
import { fetchOrganizationProfiles } from './redux/organizationProfilesSlice';
import { fetchOnboardingRecords } from "./redux/accountOnboardingSlice";
import { fetchUserPermissions } from './redux/permissionsSlice';
import keycloak, { initKeycloak } from "./keycloak";
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from './AppRoutes';
import { FormProvider } from "./components/context/FormContext";
import { createTheme } from './theme/create-theme';
import { StripeProvider } from './contexts/StripeContext';
import { KeycloakProvider } from './contexts/KeycloakContext';

const App = () => {
    const [keycloakInitialized, setKeycloakInitialized] = useState(false);
    const theme = createTheme();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { status: orgProfileStatus } = useSelector((state) => state.organizationProfiles);
    const { currentStep, status: onboardingStatus } = useSelector((state) => state.accountOnboarding);
    const { status: permissionsStatus } = useSelector((state) => state.permissions);

    const [isLoading, setIsLoading] = useState({
        orgProfiles: false,
        onboarding: false,
        permissions: false
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Initialize keycloak only once when component mounts
    useEffect(() => {
        const init = async () => {
            try {
                await initKeycloak();
                setKeycloakInitialized(true);
            } catch (error) {
                console.error('Failed to initialize Keycloak:', error);
                setSnackbar({
                    open: true,
                    message: 'Failed to initialize authentication. Please refresh the page.',
                    severity: 'error'
                });
            }
        };
        init();
    }, []);

    // Handle initial data fetching
    useEffect(() => {
        if (!keycloakInitialized || !keycloak.authenticated) {
            return;
        }

        const fetchInitialData = async () => {
            const token = keycloak.tokenParsed;
            const organizationId = token?.organization_id;
            const userId = token?.sub;

            // Fetch organization profiles if needed
            if (orgProfileStatus === 'idle' && organizationId) {
                setIsLoading(prev => ({...prev, orgProfiles: true}));
                try {
                    await dispatch(fetchOrganizationProfiles(organizationId)).unwrap();
                } catch (error) {
                    console.error('Organization profiles fetch error:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load organization data',
                        severity: 'error'
                    });
                } finally {
                    setIsLoading(prev => ({...prev, orgProfiles: false}));
                }
            }

            // Fetch onboarding records if needed
            if (onboardingStatus === 'idle' && !keycloak.hasRealmRole('eventlah-admin')) {
                setIsLoading(prev => ({...prev, onboarding: true}));
                try {
                    await dispatch(fetchOnboardingRecords(token?.email)).unwrap();
                } catch (error) {
                    console.error('Onboarding records fetch error:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load onboarding data',
                        severity: 'error'
                    });
                } finally {
                    setIsLoading(prev => ({...prev, onboarding: false}));
                }
            }

            // Fetch user permissions if needed
            if (permissionsStatus === 'idle' && userId) {
                setIsLoading(prev => ({...prev, permissions: true}));
                try {
                    await dispatch(fetchUserPermissions(userId)).unwrap();
                } catch (error) {
                    console.error('Permissions fetch error:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load user permissions',
                        severity: 'error'
                    });
                } finally {
                    setIsLoading(prev => ({...prev, permissions: false}));
                }
            }
        };

        fetchInitialData();
    }, [
        keycloakInitialized,
        dispatch,
        orgProfileStatus,
        onboardingStatus,
        permissionsStatus
    ]);

    // Handle routing based on authentication and role
    useEffect(() => {
        if (!keycloakInitialized || !keycloak.authenticated) {
            return;
        }

        const handleRouting = () => {
            const isMerchants = keycloak.hasRealmRole('merchants');
            const isAdmin = keycloak.hasRealmRole('eventlah-admin');
            const isAgent = keycloak.hasRealmRole('eventlah-agent');
            const currentPath = location.pathname;

            // Admin users don't need special routing
            if (isAdmin) {
                return;
            }

            // Handle completed onboarding
            if (currentStep === 'completed') {
                if (currentPath === '/') {
                    if (isMerchants) {
                        navigate('/dashboard');
                    } else if (isAgent) {
                        navigate('/agent-dashboard');
                    }
                }
                return;
            }

            // Handle incomplete onboarding
            if (currentStep && currentStep !== 'completed') {
                const onboardingPath = '/' + currentStep;
                if (currentPath !== onboardingPath) {
                    navigate(onboardingPath);
                }
            }
        };

        handleRouting();
    }, [keycloakInitialized, currentStep, location.pathname, navigate]);

    if (!keycloakInitialized) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <KeycloakProvider>
            <FormProvider>
                <StripeProvider>
                    {keycloak.authenticated ? (
                        <ThemeProvider theme={theme}>
                            <Box sx={{ display: 'flex' }}>
                                <CssBaseline />
                                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                                <Sidebar open={isSidebarOpen} onClose={toggleSidebar} />
                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                        height: '100vh',
                                        overflow: 'auto',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[100]
                                                : theme.palette.grey[900],
                                    }}
                                >
                                    <AppRoutes />
                                </Box>
                            </Box>
                        </ThemeProvider>
                    ) : (
                        <AppRoutes />
                    )}
                </StripeProvider>
            </FormProvider>
        </KeycloakProvider>
    );
};

export default App;