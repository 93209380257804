import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Paper,
    Typography,
    Button,
    Chip,
    TextField,
    Skeleton
} from '@mui/material';
import { fetchAccounts } from '../../../../redux/accountSlice';
import {
    CreditCard as CreditCardIcon
} from "@mui/icons-material";

export const StatusChip = ({ status }) => {
    const statusColors = {
        'Paid': 'success',
        'Pending': 'warning',
        'Failed': 'error',
        'Active': 'success',
        'Inactive': 'error'
    };

    return (
        <Chip
            label={status}
            color={statusColors[status] || 'default'}
            size="small"
            sx={{ 
                fontWeight: 600,
                minWidth: 80
            }}
        />
    );
};

const Billing = () => {
    const dispatch = useDispatch();
    const { billing, status, error } = useSelector((state) => state.accounts);
    const [searchQuery, setSearchQuery] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Sample billing history data
    const billingHistoryData = [
        {
            id: 1,
            date: '2025-01-08',
            description: 'Ticketing Fee (Jan 6 - Jan 8)',
            amount: 2850.00,
            status: 'Paid',
            invoiceNo: 'INV-2025-001'
        },
        {
            id: 2,
            date: '2025-01-06',
            description: 'Ticketing Fee (Jan 4 - Jan 6)',
            amount: 1920.50,
            status: 'Paid',
            invoiceNo: 'INV-2025-002'
        },
        {
            id: 3,
            date: '2025-01-01',
            description: 'Monthly Subscription - Basic Plan',
            amount: 99.00,
            status: 'Paid',
            invoiceNo: 'INV-2025-003'
        },
        {
            id: 4,
            date: '2024-12-30',
            description: 'Ticketing Fee (Dec 28 - Dec 30)',
            amount: 3150.75,
            status: 'Paid',
            invoiceNo: 'INV-2024-125'
        },
        {
            id: 5,
            date: '2024-12-28',
            description: 'Ticketing Fee (Dec 26 - Dec 28)',
            amount: 2680.25,
            status: 'Paid',
            invoiceNo: 'INV-2024-124'
        }
    ];

    const handleViewTransaction = (row) => {
        console.log('Viewing transaction:', row);
        // Add view transaction logic here
    };

    useEffect(() => {
        dispatch(fetchAccounts('billing'));
        setIsInitialLoad(false);
    }, [dispatch]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredBillingHistory = billingHistoryData.filter(row =>
        row.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns = [
        { 
            field: 'date', 
            headerName: 'Date', 
            flex: 1,
            valueFormatter: (params) => {
                if (!params.value) return '';
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
            }
        },
        { 
            field: 'invoiceNo', 
            headerName: 'Invoice No', 
            flex: 1 
        },
        { 
            field: 'description', 
            headerName: 'Description', 
            flex: 2 
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => {
                if (params.value === undefined || params.value === null) return '';
                return `RM ${Number(params.value).toFixed(2)}`;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <StatusChip status={params.value || 'Unknown'} />
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ 
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '&:hover': {
                            borderColor: 'primary.dark',
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        fontSize: '0.75rem',
                        py: 0.5
                    }}
                    onClick={() => handleViewTransaction(params.row)}
                >
                    View
                </Button>
            )
        }
    ];

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    if (status === 'failed') {
        return <Typography color="error">Error: {error}</Typography>;
    }

    if (status === 'loading' || isInitialLoad) {
        return renderSkeletons();
    }

    return (
        <Box sx={{ p: 3 }}>
            {/* Billing History Section */}
            <Typography 
                variant="h6" 
                sx={{ 
                    mb: 3,
                    color: 'text.primary',
                    fontWeight: 600
                }}
            >
                Billing History
            </Typography>
            <Paper sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                    <TextField
                        label="Search by Description or Invoice No"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ mb: 2 }}
                        fullWidth
                    />
                </Box>
                <DataGrid
                    rows={filteredBillingHistory}
                    columns={columns}
                    pageSize={5}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    autoHeight
                    disableSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-cell': {
                            fontSize: '0.875rem'
                        }
                    }}
                />
            </Paper>
        </Box>
    );
};

export default Billing;