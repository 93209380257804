import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAdminOrganizations = createAsyncThunk(
    'adminOrganizations/fetchAdminOrganizations',
    async (params = { page: 0, limit: 10, status: 'pending' }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/organizations`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            console.log('fetchAdminOrganizations response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchAdminOrganizations error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const fetchSingleOrganization = createAsyncThunk(
    'adminOrganizations/fetchSingleOrganization',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/administrations/organizations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('fetchSingleOrganization response:', response.data);
            return response.data;
        } catch (error) {
            console.error('fetchSingleOrganization error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const updateAdminOrganization = createAsyncThunk(
    'adminOrganizations/updateAdminOrganization',
    async (organizationData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${API_BASE_URL}/administrations/organizations/${organizationData.id}`,
                organizationData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('updateAdminOrganization response:', response.data);
            return response.data;
        } catch (error) {
            console.error('updateAdminOrganization error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

export const deleteAdminOrganization = createAsyncThunk(
    'adminOrganizations/deleteAdminOrganization',
    async (organizationId, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/administrations/organizations/${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('deleteAdminOrganization success for id:', organizationId);
            return organizationId;
        } catch (error) {
            console.error('deleteAdminOrganization error:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || { message: error.message } || { message: 'An error occurred' });
        }
    }
);

const adminOrganizationSlice = createSlice({
    name: 'adminOrganizations',
    initialState: {
        organizations: [],
        selectedOrganization: null,
        pagination: {
            page: 0,
            limit: 10,
            totalItems: 0,
            totalPages: 0,
        },
        status: 'idle',
        error: null,
        currentStatus: 'pending',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminOrganizations.pending, (state) => {
                state.status = 'loading';
                console.log('fetchAdminOrganizations pending');
            })
            .addCase(fetchAdminOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations = action.payload.data.data;
                state.pagination = {
                    page: action.payload.data.page,
                    limit: action.payload.data.limit,
                    totalItems: action.payload.data.total_items,
                    totalPages: action.payload.data.total_pages,
                };
                state.currentStatus = action.meta.arg.status || 'pending';
                console.log('fetchAdminOrganizations fulfilled, state:', state);
            })
            .addCase(fetchAdminOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchAdminOrganizations rejected, error:', state.error);
            })
            .addCase(fetchSingleOrganization.pending, (state) => {
                state.status = 'loading';
                console.log('fetchSingleOrganization pending');
            })
            .addCase(fetchSingleOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedOrganization = action.payload;
                console.log('fetchSingleOrganization fulfilled, selectedOrganization:', state.selectedOrganization);
            })
            .addCase(fetchSingleOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload && typeof action.payload === 'object'
                    ? action.payload.message || JSON.stringify(action.payload)
                    : action.payload || 'An error occurred';
                console.error('fetchSingleOrganization rejected, error:', state.error);
            })
            .addCase(updateAdminOrganization.fulfilled, (state, action) => {
                const index = state.organizations.findIndex(org => org.id === action.payload.id);
                if (index !== -1) {
                    state.organizations[index] = action.payload;
                }
                if (state.selectedOrganization && state.selectedOrganization.id === action.payload.id) {
                    state.selectedOrganization = action.payload;
                }
                console.log('updateAdminOrganization fulfilled, updated state:', state);
            })
            .addCase(deleteAdminOrganization.fulfilled, (state, action) => {
                state.organizations = state.organizations.filter(org => org.id !== action.payload);
                state.pagination.totalItems -= 1;
                if (state.selectedOrganization && state.selectedOrganization.id === action.payload) {
                    state.selectedOrganization = null;
                }
                console.log('deleteAdminOrganization fulfilled, updated state:', state);
            });
    },
});

export default adminOrganizationSlice.reducer;