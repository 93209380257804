import React, { useState } from "react";
import {Box, Paper, Switch, Snackbar, Alert as MuiAlert, Typography, Divider} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const initialRows = [
    { id: 1, ordering: 'Step 1', name: 'Enter your organization details', status: true, dateCreated: '2024-08-01' },
    { id: 2, ordering: 'Step 2', name: 'Upload SSM and Selfie with ID and TOS agreement', status: true, dateCreated: '2024-08-02' },
    { id: 3, ordering: 'Step 3', name: 'Store Setup', status: false, skippable: true, dateCreated: '2024-08-03' },
    { id: 4, ordering: 'Step 4', name: 'Product Setup', status: true, skippable: true, dateCreated: '2024-08-04' },
    { id: 5, ordering: 'Step 5', name: 'Payment Gateway Setup', status: false, skippable: true, dateCreated: '2024-08-05' },
];

const AdminSettingPage = () => {
    const [rows, setRows] = useState(initialRows);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('info');
    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragEnter = (index) => {
        const updatedRows = Array.from(rows);
        const draggedItem = updatedRows[draggedIndex];
        updatedRows.splice(draggedIndex, 1);
        updatedRows.splice(index, 0, draggedItem);
        setDraggedIndex(index);

        const reorderedRows = updatedRows.map((row, idx) => ({
            ...row,
            ordering: `Step ${idx + 1}`,
        }));

        setRows(reorderedRows);
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    const handleStatusChange = (id) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                const newStatus = !row.status;
                setAlertMessage(newStatus ? 'Status: Active' : 'Status: Inactive');
                setAlertColor(newStatus ? 'success' : 'error');
                setOpenSnackbar(true);
                return { ...row, status: newStatus };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const handleSkippableChange = (id) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                const newSkippableStatus = !row.skippable;
                setAlertMessage(newSkippableStatus ? 'Skippable: Active' : 'Skippable: Inactive');
                setAlertColor(newSkippableStatus ? 'success' : 'error');
                setOpenSnackbar(true);
                return { ...row, skippable: newSkippableStatus };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box >
            <Typography variant='h4'>Merchant Onboarding Settings</Typography>
            <Divider/>
            <Paper
                sx={{
                    width: "100%",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Merchant Onboarding Steps
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '16px',
                        borderBottom: '2px solid #e0e0e0',
                        background: '#f5f5f5',
                        fontWeight: 'bold'
                    }}
                >
                    <Typography flex={0.7}>Ordering</Typography>
                    <Typography flex={2}>Name</Typography>
                    <Typography flex={1}>Status</Typography>
                    <Typography flex={0.5}>Skippable</Typography>
                    <Typography flex={0.5}>Date Created</Typography>
                </Box>

                <Box>
                    {rows.map((row, index) => (
                        <Box
                            key={row.id}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragEnter={() => handleDragEnter(index)}
                            onDragEnd={handleDragEnd}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '26px',
                                borderBottom: '1px solid #e0e0e0',
                                background: '#fff',
                                cursor: 'move',
                                opacity: draggedIndex === index ? 0.5 : 1,
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', flex: 0.7, gap: 2 }}>
                                <DragIndicatorIcon />
                                <Typography>{row.ordering}</Typography>
                            </Box>
                            <Typography flex={2}>{row.name}</Typography>
                            <Box sx={{ flex: 1 }}>
                                <Switch
                                    checked={row.status}
                                    onChange={() => handleStatusChange(row.id)}
                                />
                            </Box>
                            <Box sx={{ flex: 0.5 }}>
                                {row.id <= 2 ? (
                                    <Typography>Required</Typography>
                                ) : (
                                    <Switch
                                        checked={row.skippable}
                                        onChange={() => handleSkippableChange(row.id)}
                                    />
                                )}
                            </Box>
                            <Typography flex={0.5}>{row.dateCreated}</Typography>
                        </Box>
                    ))}
                </Box>
            </Paper>

            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <MuiAlert
                    onClose={handleCloseSnackbar}
                    severity="info"
                    icon={<InfoIcon sx={{ color: 'white' }} />}
                    sx={{
                        width: '100%',
                        backgroundColor: alertColor === 'success' ? 'green' : 'red',
                        color: '#fff',
                    }}
                >
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default AdminSettingPage;