import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Container, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '../../theme/theme-provider';
import { createTheme } from '../../theme/create-theme';
import { CompanyLogo } from '../header/HeaderSvgImages';
import { initKeycloak, getToken, updateToken } from '../../keycloak';
import { registerUser } from '../../api/auth';

const Registration = () => {
  const theme = createTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: 'M',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        // Register user through API
        const response = await registerUser({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password
        });

        // Only redirect if registration was successful
        if (!response.error) {
          navigate('/email-verification', { state: { email: formData.email } });
        } else {
          // Handle specific error cases
          if (response.code === 409) {
            setErrors({ 
              submit: response.error?.message || 'User exists with same email'
            });
          } else {
            setErrors({ 
              submit: response.error?.message || 'Registration failed'
            });
          }
        }
      } catch (error) {
        console.error('Registration error:', error);
        setErrors({ 
          submit: error.response?.data?.error || error.message || 'Registration failed'
        });
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        bgcolor: 'background.default',
        padding: 0,
        margin: 0
      }}>
        <Container maxWidth="100%" sx={{ padding: 0, margin: 0 }}>
          <Box sx={{
            display: 'flex',
            height: '100vh',
            width: '100%',
            '& > *': {
              flex: '0 0 50%!important',
              width: '50%!important',
              maxWidth: '50%!important',
              height: '100vh',
              boxSizing: 'border-box',
              overflowY: 'auto'
            }
          }}>
            {/* Form Section */}
            <Box sx={{
              p: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%!important',
              height: '100%!important',
              boxSizing: 'border-box',
              flex: '0 0 50%!important'
            }}>
              <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src="/fantet-logo.png" alt="Fantet Logo" style={{ height: '50px' }} />
              </Box>
              <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                Welcome!
              </Typography>
              <Typography color="textSecondary" gutterBottom sx={{ mb: 4 }}>
                Sign In to access and start manage your account.
              </Typography>

              {errors.submit && (
                <Alert severity="error" sx={{ mb: 4 }}>
                  {errors.submit}
                </Alert>
              )}
              
              <form onSubmit={handleSubmit}>
                {/* Hidden type field for future use */}
                <input type="hidden" name="type" value="M" />

                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  sx={{ mb: 2 }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    bgcolor: '#ffed00',
                    color: 'black',
                    '&:hover': {
                      bgcolor: '#ffed00',
                    },
                    mb: 2,
                  }}
                >
                  Sign Up
                </Button>

                {errors.success && (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    {errors.success}
                  </Alert>
                )}

                <Box sx={{ textAlign: 'center' }}>
                  <Typography color="textSecondary">
                    Already have an account?{' '}
                    <Link to="/login" style={{ color: '#ffb800', textDecoration: 'none' }}>
                      Sign In
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Box>

            {/* Image Section */}
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                bgcolor: '#f5f5f5',
                backgroundImage: 'url("/bg.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
                boxSizing: 'border-box',
                position: 'relative'
              }}
            >
              <Box sx={{ alignSelf: 'flex-end', mt: 2, mr: 2 }}>
                <img src="/fantet-logo.png" alt="Fantet" style={{ width: '180px' }} />
              </Box>
              <Box sx={{ 
                color: 'white',
                position: 'absolute',
                bottom: 40,
                right: 40,
                textAlign: 'right',
                maxWidth: '500px',
                textShadow: '0 2px 4px rgba(0,0,0,0.5)',
                p: 2
              }}>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  Become The Best Host of Your
                </Typography>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  Venue or Event!
                </Typography>
                <Typography>
                  If you have a unique venue that you would like to share with the world,
                  list it on Eventlah now and start earning!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Registration;
