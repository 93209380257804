import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Divider,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Skeleton,
    Link
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from 'axios';
import AddIcon from "@mui/icons-material/Add";
import { ViewIcon, EditIcon, DeleteIcon } from "../../shared/IconButtons";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminSubscriptionPlans = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/organization-plans`);
                setPlans(response.data.plans || []);
                setTotalCount(response.data.plans?.length || 0);
                setIsInitialLoad(false);
            } catch (error) {
                console.error('Error fetching plans:', error);
                setSnackbarMessage('Failed to fetch plans: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setIsInitialLoad(false);
            }
        };

        fetchPlans();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (plan) => {
        navigate('/admin/admin-subscription-settings', {
            state: {
                editMode: true,
                planData: plan
            }
        });
    };

    const handleDeleteClick = async (planId) => {
        try {
            await axios.delete(`${API_BASE_URL}/organization-plans/${planId}`);
            setSnackbarMessage('Plan deleted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            // Refresh plans
            const response = await axios.get(`${API_BASE_URL}/organization-plans`);
            setPlans(response.data.plans || []);
        } catch (error) {
            setSnackbarMessage('Failed to delete plan: ' + error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const formatFeatures = (planFeatures) => {
        if (!planFeatures || !Array.isArray(planFeatures)) return '';
        return planFeatures.map(pf => pf.feature.name).join(', ');
    };

    const rows = useMemo(() => {
        return plans.map(plan => ({
            ...plan,
            id: plan.id,
            features: formatFeatures(plan.planFeatures)
        }));
    }, [plans]);

    const columns = useMemo(() => [
        {
            field: 'name',
            headerName: 'Plan Name',
            flex: 0.6,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                    {params.row.is_most_popular && (
                        <WorkspacePremiumIcon sx={{ color: '#FFD700', ml: 1, fontSize: 20 }} />
                    )}
                </Box>
            )
        },
        {
            field: 'price_monthly',
            headerName: 'Price (Monthly)',
            flex: 0.4,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">
                        RM {parseFloat(params.value).toFixed(2)}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'max_users',
            headerName: 'Max Users',
            flex: 0.4,
            minWidth: 100,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'max_storage_gb',
            headerName: 'Storage (GB)',
            flex: 0.4,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">{params.value}GB</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.4,
            minWidth: 120,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <IconButton onClick={() => handleEditClick(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ], []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                    <Box>
                        <Typography variant="h4">Subscription Plans</Typography>
                        <Typography variant="h5">Manage organization subscription plans</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto' }}>
                        <Button
                            variant="save"
                            startIcon={<AddIcon />}
                            onClick={() => navigate('/admin/admin-subscription-settings')}
                        >
                            Add New Plan
                        </Button>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box />
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '52px !important',
                            maxHeight: '52px !important',
                        },
                        '& .MuiDataGrid-row': {
                            display: 'flex',
                            alignItems: 'center',
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Plans
                    </Typography>

                    <Divider sx={{ my: 0 }} />

                    {isInitialLoad ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="client"
                            checkboxSelection
                            disableRowSelectionOnClick
                            autoHeight
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-cell': {
                                    borderBottom: '1px solid var(--Gray-200, #EAECF0)',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#F9FAFB',
                                    borderBottom: '1px solid var(--Gray-200, #EAECF0)',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    borderTop: '1px solid var(--Gray-200, #EAECF0)',
                                },
                            }}
                        />
                    )}
                </Paper>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default AdminSubscriptionPlans;