import React, {useState, useMemo, useEffect} from 'react';
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import PromoSelection from "./PromoSelection";
import SubscriptionSetup from "./SubscriptionSetup";
import ReviewConfirm from "./ReviewConfirm";
import {promotionsByCategory} from "./AdminSubscriptionsSettingsData";
import {useLocation} from "react-router-dom";

const AdminSubscriptionsSettings = () => {
    const [value, setValue] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [planData, setPlanData] = useState(null);
    const location = useLocation();
    const [selectedCategories, setSelectedCategories] = useState({
        ticketing: false,
        products: false,
        bookings: false
    });
    const [selectedPromos, setSelectedPromos] = useState({
        ticketing: '',
        products: '',
        bookings: ''
    });
    const [subscriptionDetails, setSubscriptionDetails] = useState({
        ticketCount: '',
        bookingCount: '',
        productCount: '',
        startTime: new Date().toISOString().slice(0, 16),
        duration: 30,
        endTime: '',
        is_active: true,
        is_customized: false
    });

    useEffect(() => {
        if (location.state) {
            const { editMode, planData } = location.state;
            if (editMode && planData) {
                setEditMode(true);
                setPlanData(planData);
                setSubscriptionDetails(prev => ({
                    ...prev,
                    is_active: planData.is_active ?? true,
                    is_customized: planData.is_customized ?? false
                }));
            }
        }
    }, [location]);

    // Memoize the selected promotions to avoid unnecessary recalculations
    const selectedPromotions = useMemo(() => {
        return Object.entries(selectedCategories)
            .filter(([category, isSelected]) => isSelected && selectedPromos[category])
            .map(([category]) => {
                const promotion = promotionsByCategory[category].find(
                    promo => promo.id === selectedPromos[category]
                );
                return {
                    ...promotion,
                    category: category.charAt(0).toUpperCase() + category.slice(1)
                };
            });
    }, [selectedCategories, selectedPromos]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));

        // Clear the selected promotion when unchecking a category
        if (selectedCategories[category]) {
            setSelectedPromos(prev => ({
                ...prev,
                [category]: ''
            }));
        }
    };

    const handlePromoSelect = (category, promoId) => {
        setSelectedPromos(prev => ({
            ...prev,
            [category]: promoId
        }));
    };

    return (
        <Box>
            <Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    width: '100%'
                }}>
                    <Box>
                        <Typography variant='h4'>Admin Subscription Settings</Typography>
                        <Typography variant='h5'>Manage and view your subscription plans and status.</Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Plan Details" />
                        <Tab label="Subscription Limit" />
                        <Tab label="Review & Confirm" />
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {value === 0 && (
                        <PromoSelection
                            editMode={editMode}
                            planData={planData}
                            selectedCategories={selectedCategories}
                            selectedPromos={selectedPromos}
                            onCategoryChange={handleCategoryChange}
                            onPromoSelect={handlePromoSelect}
                            promotionsByCategory={promotionsByCategory}
                            isActive={subscriptionDetails.is_active}
                            isCustomized={subscriptionDetails.is_customized}
                            onToggleChange={(field, value) => setSubscriptionDetails(prev => ({
                                ...prev,
                                [field]: value
                            }))}
                        />
                    )}
                    {value === 1 && (
                        <SubscriptionSetup
                            subscriptionDetails={subscriptionDetails}
                            onSubscriptionChange={setSubscriptionDetails}
                            selectedCategories={selectedCategories}  
                        />
                    )}
                    {value === 2 && (
                        <ReviewConfirm
                            selectedPromotions={selectedPromotions}
                            subscriptionDetails={subscriptionDetails}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AdminSubscriptionsSettings;