import React, { useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    alpha,
    useTheme,
} from '@mui/material';

const SubscriptionSetup = ({ subscriptionDetails, onSubscriptionChange, selectedCategories }) => {
    const theme = useTheme();
    const [copied, setCopied] = React.useState(false);

    const MIN_COUNT = 1;
    const MAX_COUNT = 10;

    useEffect(() => {
        if (!subscriptionDetails.promoCode) {
            const now = new Date();
            onSubscriptionChange({
                ...subscriptionDetails,
                startTime: now.toISOString().slice(0, 16)
            });
        }
    }, []);

    useEffect(() => {
        if (subscriptionDetails.startTime && subscriptionDetails.duration) {
            const start = new Date(subscriptionDetails.startTime);
            const end = new Date(start.getTime() + subscriptionDetails.duration * 24 * 60 * 60 * 1000);
            onSubscriptionChange({
                ...subscriptionDetails,
                endTime: end.toISOString().slice(0, 16)
            });
        }
    }, [subscriptionDetails.startTime, subscriptionDetails.duration]);

    const handleCountChange = (field) => (e) => {
        const value = e.target.value;

        if (value === '') {
            onSubscriptionChange({
                ...subscriptionDetails,
                [field]: ''
            });
            return;
        }

        const numValue = parseInt(value);
        if (!isNaN(numValue)) {
            onSubscriptionChange({
                ...subscriptionDetails,
                [field]: numValue
            });
        }
    };

    const getTextFieldSx = (isDisabled) => ({
        width: '260px',
        mt: '2px',
        '& .MuiOutlinedInput-root': {
            backgroundColor: isDisabled ? alpha(theme.palette.action.disabledBackground, 0.8) : 'transparent',
            '& fieldset': {
                borderColor: isDisabled ? theme.palette.action.disabled : theme.palette.divider,
                borderWidth: isDisabled ? '1px' : '1px',
            },
            '&:hover fieldset': {
                borderColor: isDisabled ? theme.palette.action.disabled : theme.palette.primary.main,
            },
            '& input': {
                color: isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
                WebkitTextFillColor: isDisabled ? theme.palette.text.disabled : 'inherit',
                cursor: isDisabled ? 'not-allowed' : 'text',
            },
        },
    });

    return (
        <Box>
            <Typography variant='h6' sx={{ mb: 2 }}>Subscription Limit</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {/* Ticket Count Section */}
                <Box>
                    <Typography
                        variant="subtitle1"
                        component="label"
                        htmlFor="ticketCount"
                        sx={{
                            mb: 1,
                            color: selectedCategories.ticketing ? 'text.primary' : 'text.disabled',
                            transition: 'color 0.2s'
                        }}
                    >
                        Number of Tickets
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            id="ticketCount"
                            type="text"
                            placeholder="Enter number of tickets"
                            value={subscriptionDetails.ticketCount}
                            onChange={handleCountChange('ticketCount')}
                            size="small"
                            disabled={!selectedCategories.ticketing}
                            sx={getTextFieldSx(!selectedCategories.ticketing)}
                        />
                    </Box>
                </Box>

                {/* Booking Count Section */}
                <Box>
                    <Typography
                        variant="subtitle1"
                        component="label"
                        htmlFor="bookingCount"
                        sx={{
                            mb: 1,
                            color: selectedCategories.bookings ? 'text.primary' : 'text.disabled',
                            transition: 'color 0.2s'
                        }}
                    >
                        Number of Bookings
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            id="bookingCount"
                            type="text"
                            placeholder="Enter number of bookings"
                            value={subscriptionDetails.bookingCount}
                            onChange={handleCountChange('bookingCount')}
                            size="small"
                            disabled={!selectedCategories.bookings}
                            sx={getTextFieldSx(!selectedCategories.bookings)}
                        />
                    </Box>
                </Box>

                {/* Product Count Section */}
                <Box>
                    <Typography
                        variant="subtitle1"
                        component="label"
                        htmlFor="productCount"
                        sx={{
                            mb: 1,
                            color: selectedCategories.products ? 'text.primary' : 'text.disabled',
                            transition: 'color 0.2s'
                        }}
                    >
                        Number of Products
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            id="productCount"
                            type="text"
                            placeholder="Enter number of products"
                            value={subscriptionDetails.productCount}
                            onChange={handleCountChange('productCount')}
                            size="small"
                            disabled={!selectedCategories.products}
                            sx={getTextFieldSx(!selectedCategories.products)}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SubscriptionSetup;