import AdminAccountStatus from '../../admin/AdminSettingsContent/AdminAccountStatus';

const AccountStatus = () => {
    return (
        <>
            <AdminAccountStatus />
        </>
    );
};

export default AccountStatus;
