import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    OutlinedInput,
    InputAdornment,
    Skeleton
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    PaidOutlined as RevenueIcon,
    TrendingUpOutlined as GrowthIcon,
    AccountBalanceWalletOutlined as WalletIcon,
    StorefrontOutlined as MerchantIcon,
    Search as SearchIcon,
    PanoramaWideAngleSelect
} from '@mui/icons-material';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";

const RevenueOverview = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [revenueData, setRevenueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    // Mock API response
    const mockResponse = {
        code: 200,
        message: "OK",
        translation: "REVENUE_RETRIEVED_SUCCESS",
        description: "Revenue data retrieved successfully.",
        group: "Revenue",
        data: {
            revenues: [
                {
                    id: 1,
                    merchantName: 'Orchard Villa Sdn Bhd',
                    totalRevenue: 120000,
                    monthlyRevenue: 40000,
                    ticketCount: '1-20',
                    usageTier: 'Starter',
                    feeStructure: 'First 20 tickets free',
                    ticketsInTier: 20,
                    ourRevenue: 0,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 2,
                    merchantName: 'Orchard Villa Sdn Bhd',
                    totalRevenue: 120000,
                    monthlyRevenue: 40000,
                    ticketCount: '21-250',
                    usageTier: 'Starter',
                    feeStructure: '3% per ticket',
                    ticketsInTier: 230,
                    ourRevenue: 1380,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 3,
                    merchantName: 'Orchard Villa Sdn Bhd',
                    totalRevenue: 120000,
                    monthlyRevenue: 40000,
                    ticketCount: '251-500',
                    usageTier: 'Starter',
                    feeStructure: '2.6% per ticket',
                    ticketsInTier: 250,
                    ourRevenue: 1300,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 4,
                    merchantName: 'Orchard Villa Sdn Bhd',
                    totalRevenue: 120000,
                    monthlyRevenue: 40000,
                    ticketCount: '500+',
                    usageTier: 'Starter',
                    feeStructure: '2.4% per ticket',
                    ticketsInTier: 100,
                    ourRevenue: 480,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 5,
                    merchantName: "FELDA D'Saji Sdn. Bhd.",
                    totalRevenue: 150000,
                    monthlyRevenue: 50000,
                    ticketCount: '1-50',
                    usageTier: 'Basic',
                    feeStructure: 'First 50 tickets free',
                    ticketsInTier: 50,
                    ourRevenue: 99,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 6,
                    merchantName: "FELDA D'Saji Sdn. Bhd.",
                    totalRevenue: 150000,
                    monthlyRevenue: 50000,
                    ticketCount: '51-250',
                    usageTier: 'Basic',
                    feeStructure: '2.0% per ticket',
                    ticketsInTier: 200,
                    ourRevenue: 800,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 7,
                    merchantName: "FELDA D'Saji Sdn. Bhd.",
                    totalRevenue: 150000,
                    monthlyRevenue: 50000,
                    ticketCount: '251-500',
                    usageTier: 'Basic',
                    feeStructure: '1.8% per ticket',
                    ticketsInTier: 250,
                    ourRevenue: 900,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                },
                {
                    id: 8,
                    merchantName: "FELDA D'Saji Sdn. Bhd.",
                    totalRevenue: 150000,
                    monthlyRevenue: 50000,
                    ticketCount: '500+',
                    usageTier: 'Basic',
                    feeStructure: '1.6% per ticket',
                    ticketsInTier: 100,
                    ourRevenue: 320,
                    created_at: "2025-01-08T16:45:39+08:00",
                    updated_at: null
                }
            ]
        }
    };

    useEffect(() => {
        // Simulating API call
        setRevenueData(mockResponse.data.revenues);
        setLoading(false);
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    // Filter data based on search query
    const filteredData = useMemo(() => {
        if (!revenueData) return [];
        return revenueData.filter(item =>
            item.merchantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.usageTier.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.feeStructure.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [revenueData, searchQuery]);

    // Calculate totals correctly by unique merchants
    const calculateTotals = (data) => {
        if (!data) return {
            totalRevenue: 0,
            totalTickets: 0,
            ourTotalRevenue: 0
        };

        // Group by merchant first
        const merchantGroups = data.reduce((acc, curr) => {
            if (!acc[curr.merchantName]) {
                acc[curr.merchantName] = {
                    totalRevenue: curr.totalRevenue,
                    tickets: 0,
                    ourRevenue: 0
                };
            }
            // Add tickets and our revenue for each tier
            acc[curr.merchantName].tickets += curr.ticketsInTier;
            acc[curr.merchantName].ourRevenue += curr.ourRevenue;
            return acc;
        }, {});

        // Sum up the totals from each merchant
        return Object.values(merchantGroups).reduce((acc, merchant) => ({
            totalRevenue: acc.totalRevenue + merchant.totalRevenue,
            totalTickets: acc.totalTickets + merchant.tickets,
            ourTotalRevenue: acc.ourTotalRevenue + merchant.ourRevenue
        }), {
            totalRevenue: 0,
            totalTickets: 0,
            ourTotalRevenue: 0
        });
    };

    // Calculate filtered totals and memoize
    const totals = useMemo(() => calculateTotals(filteredData), [filteredData]);
    const avgRevenuePerTicket = totals.totalTickets > 0 
        ? totals.totalRevenue / totals.totalTickets 
        : 0;

    // Loading skeleton for analytics widget
    const AnalyticsWidgetSkeleton = () => (
        <Paper sx={{ p: 3, bgcolor: 'background.neutral' }}>
            <Skeleton variant="text" width="40%" height={24} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" width="100%" height={120} />
        </Paper>
    );

    if (loading) {
        return (
            <Box sx={{ display: 'flex', padding: { xs: 1, md: '32px' } }}>
                <Box flex={1}>
                    <Box sx={{ mb: { xs: 3, md: 5 } }}>
                        <Skeleton variant="text" width="30%" height={40} sx={{ mb: 1 }} />
                        <Skeleton variant="text" width="50%" height={32} />
                    </Box>

                    {/* Analytics Summary Skeleton */}
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        {[...Array(4)].map((_, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <AnalyticsWidgetSkeleton />
                            </Grid>
                        ))}
                    </Grid>

                    {/* DataGrid Skeleton */}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{ p: 2 }}>
                            <Skeleton variant="rectangular" width="100%" height={56} />
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Skeleton variant="rectangular" width="100%" height={400} />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        );
    }

    const totalCount = filteredData.length;

    return (
        <Box sx={{ display: 'flex', padding: { xs: 1, md: '32px' } }}>
            <Box flex={1}>
                <Box sx={{ mb: { xs: 3, md: 5 } }}>
                    <Typography variant='h4'>Revenue Overview</Typography>
                    <Typography variant='h5'>Revenue analysis across all merchants.</Typography>
                </Box>

                {/* Analytics Summary */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Tickets Sold"
                            percent={8.2}
                            total={totals.totalTickets}
                            color="info"
                            icon={<WalletIcon sx={{ width: 32, height: 32, color: 'info.main' }} />}
                            chart={{
                                series: [
                                    totals.totalTickets * 0.8,
                                    totals.totalTickets * 0.85,
                                    totals.totalTickets * 0.9,
                                    totals.totalTickets * 0.95,
                                    totals.totalTickets
                                ],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Growth"
                            percent={12.5}
                            total={25.8}
                            color="success"
                            icon={<GrowthIcon sx={{ width: 32, height: 32, color: 'success.main' }} />}
                            chart={{
                                series: [15.5, 18.2, 21.3, 23.4, 25.8],
                            }}
                            isCurrency={false}
                            isPercentage={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Net Sales"
                            percent={3.2}
                            total={totals.totalRevenue}
                            color="warning"
                            icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                            chart={{
                                series: [
                                    totals.totalRevenue * 0.8,
                                    totals.totalRevenue * 0.85,
                                    totals.totalRevenue * 0.9,
                                    totals.totalRevenue * 0.95,
                                    totals.totalRevenue
                                ],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Total Processing Fee"
                            percent={2.8}
                            total={totals.ourTotalRevenue}
                            color="error"
                            icon={<MerchantIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                            chart={{
                                series: [
                                    totals.ourTotalRevenue * 0.8,
                                    totals.ourTotalRevenue * 0.85,
                                    totals.ourTotalRevenue * 0.9,
                                    totals.ourTotalRevenue * 0.95,
                                    totals.ourTotalRevenue
                                ],
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Revenue Data Grid */}
                <Paper sx={{ p: 0, borderRadius: 4 }}>
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={filteredData}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalCount}
                        columns={[
                            {
                                field: 'merchantName',
                                headerName: 'Merchant',
                                flex: 2,
                                headerAlign: 'center',
                                align: 'center',
                                renderCell: (params) => (
                                    <Box sx={{ 
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            lineHeight: 1
                                        }}>
                                            <Typography variant="body2" sx={{ mb: 0 }}>{params.row.merchantName}</Typography>
                                            <Typography variant="caption" color="textSecondary" sx={{ lineHeight: 1 }}>
                                                {params.row.usageTier} Tier
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            },
                            {
                                field: 'feeStructure',
                                headerName: 'Fee',
                                flex: 1,
                                headerAlign: 'center',
                                align: 'center'
                            },
                            {
                                field: 'ticketCount',
                                headerName: 'Ticket Range',
                                flex: 1,
                                headerAlign: 'center',
                                align: 'center'
                            },
                            {
                                field: 'ticketsInTier',
                                headerName: 'Tickets',
                                flex: 1,
                                type: 'number',
                                headerAlign: 'center',
                                align: 'center',
                                valueFormatter: (params) => {
                                    return params;
                                }
                            },
                            {
                                field: 'totalRevenue',
                                headerName: 'Revenue',
                                flex: 1,
                                headerAlign: 'center',
                                align: 'center',
                                valueFormatter: (params) => {
                                    return params;
                                }
                            },
                            {
                                field: 'ourRevenue',
                                headerName: 'Our Revenue',
                                flex: 1,
                                type: 'number',
                                headerAlign: 'center',
                                align: 'center',
                                valueFormatter: (params) => {
                                    return params;
                                }
                            }
                        ]}
                        autoHeight
                        disableRowSelectionOnClick
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        loading={loading}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#f5f5f5',
                            },
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default RevenueOverview;