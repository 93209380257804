import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUploads = createAsyncThunk(
    'uploads/fetchUploads',
    async (productId, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const tokenData = authService.getTokenData();
            
            const response = await axios.get(`${API_BASE_URL}/uploads`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Organization-ID': tokenData?.organization_id
                },
                params: { page, limit }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while fetching uploads');
        }
    }
);

export const addUpload = createAsyncThunk(
    'uploads/addUpload',
    async ({ uploadInfo, productId }, { rejectWithValue, dispatch }) => {
        try {
            console.info('file data', uploadInfo);
            const token = await authService.ensureValidToken();
            const tokenData = authService.getTokenData();

            const jsonData = {
                key: uploadInfo.key,
                filename: uploadInfo.uniqueFilename,
                contentType: uploadInfo.type,
                size: uploadInfo.size,
                productId: uploadInfo.productId
            };

            const response = await axios.post(`${API_BASE_URL}/uploads`, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Organization-ID': tokenData?.organization_id
                },
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    dispatch(updateProgress({ fileName: uploadInfo.uniqueFilename, percentage }));
                },
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteUpload = createAsyncThunk(
    'uploads/deleteUpload',
    async (uploadId, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const tokenData = authService.getTokenData();
            await axios.delete(`${API_BASE_URL}/uploads?id=${uploadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Organization-ID': tokenData?.organization_id
                }
            });
            return uploadId;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while deleting upload');
        }
    }
);

const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: {
        uploads: [],
        uploadProgress: {},
        uploadedImages: [],
        error: null,
        pagination: null
    },
    reducers: {
        updateProgress: (state, action) => {
            const { fileName, percentage } = action.payload;
            state.uploadProgress[fileName] = percentage;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUploads.pending, (state) => {
                console.log('fetchUploads.pending');
                state.status = 'loading';
            })
            .addCase(fetchUploads.fulfilled, (state, action) => {
                console.log('fetchUploads.fulfilled');
                console.log('Action payload:', action.payload);

                if (action.payload && action.payload.data && Array.isArray(action.payload.data.uploads)) {
                    state.status = 'succeeded';
                    state.uploads = action.payload.data.uploads;
                    state.pagination = action.payload.data.pagination;
                    console.log('Updated state.uploads:', state.uploads);
                    console.log('Updated state.pagination:', state.pagination);
                } else {
                    console.error('Unexpected payload structure:', action.payload);
                    state.status = 'failed';
                    state.error = 'Received invalid data structure from API';
                }
            })
            .addCase(fetchUploads.rejected, (state, action) => {
                console.log('fetchUploads.rejected');
                console.error('Error:', action.error);
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addUpload.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addUpload.fulfilled, (state, action) => {
                state.uploads.push(action.payload.data); // Assuming you want to add to items, not uploads
                delete state.uploadProgress[action.payload.data.filename];
                state.status = 'succeeded';
            })
            .addCase(addUpload.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to upload files';
            })
            .addCase(deleteUpload.fulfilled, (state, action) => {
                state.uploads = state.uploads.filter(upload => upload.id !== action.payload);
            })
            .addCase(deleteUpload.rejected, (state, action) => {
                state.error = action.payload || 'Failed to delete upload';
            });
    },
});

export const { updateProgress } = uploadsSlice.actions;
export default uploadsSlice.reducer;
