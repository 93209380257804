import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link,
    IconButton,
    FormControl,
    Divider,
    Chip,
    Badge,
    Tooltip,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
} from '@mui/material';
import {
    Info,
    ContentCopy,
    CheckCircle,
    Warning,
    AccessTime,
    MoreVert,
    Shield,
    Security,
    Google,
    PersonAdd as NewMemberIcon,
} from '@mui/icons-material';
import axios from 'axios';
import keycloak from '../../../../keycloak';
import AddMemberModal from './AddMemberModal';
import PermissionGate from 'components/shared/PermissionGate';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const StatusChip = ({ type, label }) => {
    const getChipProps = () => {
        switch (type) {
            case 'invited':
                return {
                    icon: <AccessTime sx={{ fontSize: 16 }} />,
                    color: 'warning',
                    label,
                    size: 'medium',
                };
            case 'active':
                return {
                    icon: <CheckCircle sx={{ fontSize: 16 }} />,
                    color: 'success',
                    label,
                    size: 'medium',
                };
            case 'limited':
                return {
                    icon: <Warning sx={{ fontSize: 16 }} />,
                    color: 'default',
                    label,
                    size: 'medium',
                };
            default:
                return { label, size: 'medium' };
        }
    };

    return <Chip {...getChipProps()} sx={{ height: 24 }} />;
};

const ViewRolePermissions = () => {
    const [openModal, setOpenModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [userData, setUserData] = useState({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        lastActive: new Date().toISOString(),
        organizationId: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak
                .loadUserProfile()
                .then(kcProfile => {
                    setUserData(prevProfile => ({
                        ...prevProfile,
                        id: kcProfile.id,
                        email: kcProfile.email,
                        firstName: `${kcProfile.firstName}`,
                        lastName: `${kcProfile.lastName}`,
                        organizationId: kcProfile.organization_id,
                    }));
                })
                .catch(error => {
                    console.error('Failed to load user profile', error);
                });
        }
    }, [keycloak]);

    const fetchMembers = async () => {
        try {
            const organizationId = keycloak.tokenParsed?.organization_id;
            if (!organizationId) {
                throw new Error('Organization ID not found');
            }

            // Fetch organization members
            const membersResponse = await axios.get(`${BASE_API_URL}/user-organizations`, {
                params: { organization_id: organizationId },
            });

            // Fetch roles for the organization
            const rolesResponse = await axios.get(`${BASE_API_URL}/roles`, {
                params: { organization_id: organizationId },
            });

            setMembers(membersResponse.data.data.userOrganizations);
            setRoles(rolesResponse.data.data.roles);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
            setSnackbar({
                open: true,
                message: 'Failed to fetch team members',
                severity: 'error',
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMembers();
    }, []);

    const handleAddMember = async (email, selectedRoles) => {
        try {
            setLoading(true);
            const token = await keycloak.token;
            const organizationId = keycloak.tokenParsed?.organization_id;

            if (!organizationId) {
                throw new Error('Organization ID not found in token');
            }
            
            const response = await axios.post(
                `${BASE_API_URL}/organization-members`,
                {
                    email,
                    organizationId,
                    roleIds: selectedRoles,
                    inviterName: `${userData.firstName} ${userData.lastName}`,
                    organizationName: userData.organization?.[0] || 'Eventlah'
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setMembers(prevMembers => [...prevMembers, response.data.data.member]);
            setSnackbar({
                open: true,
                message: 'Member invited successfully',
                severity: 'success'
            });
            setOpenModal(false);
        } catch (error) {
            console.error('Error inviting member:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Error inviting member',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const formatLastActive = date => {
        if (!date) return 'Never';
        return new Date(date).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: '850px' }}>
            {/* Header Section */}
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant='h5' sx={{ fontWeight: '600', mb: 1 }}>
                        Users and permissions
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                        Manage access and control for your team members
                    </Typography>
                </Box>
            </Box>

            {/* Store Owner Section */}
            <Paper
                variant='outlined'
                sx={{
                    mb: 3,
                    borderRadius: 2,
                    borderColor: 'primary.light',
                    bgcolor: 'primary.50',
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Security sx={{ color: 'primary.main', mr: 1 }} />
                        <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                            Store owner
                        </Typography>
                    </Box>
                    <List sx={{ p: 0 }}>
                        <ListItem
                            sx={{
                                px: 0,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                                '&:hover': { bgcolor: 'grey.50' },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        borderRadius: 2,
                                        bgcolor: 'primary.main',
                                        width: 40,
                                        height: 40,
                                    }}
                                >
                                    {userData.firstName?.[0]}
                                    {userData.lastName?.[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: '500' }}>
                                            {userData.firstName} {userData.lastName}
                                        </Typography>
                                        <Tooltip title='Full administrative access'>
                                            <Info fontSize='small' sx={{ ml: 1, color: 'primary.main' }} />
                                        </Tooltip>
                                    </Box>
                                }
                                secondary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                        <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                                            {userData.email}
                                        </Typography>
                                        <AccessTime sx={{ fontSize: 14, mr: 0.5, color: 'text.secondary' }} />
                                        <Typography variant='caption' color='text.secondary'>
                                            Last active:{' '}
                                            {new Date().toLocaleString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                timeZoneName: 'short',
                                            })}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <StatusChip type='active' label='Active' />
                        </ListItem>
                    </List>
                </Box>
            </Paper>

            {/* Staff Section */}
            <Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography variant='subtitle1' sx={{ fontWeight: '600', mb: 0.5 }}>
                                Staff members
                            </Typography>
                            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
                                {members.length} members
                            </Typography>
                        </Box>
                        {/*<PermissionGate featureCode='CRTTEM' fallback={null}>*/}
                            <Button
                                variant='contained'
                                startIcon={<NewMemberIcon />}
                                onClick={() => setOpenModal(true)}
                                sx={{ textTransform: 'none' }}
                            >
                                Add Member
                            </Button>
                        {/*</PermissionGate>*/}
                    </Box>
                    <List sx={{ p: 0 }}>
                        {members.map((member, index) => (
                            <React.Fragment key={member.id}>
                                <ListItem
                                    sx={{
                                        px: 0,
                                        py: 2,
                                        borderRadius: 1,
                                        '&:hover': {
                                            bgcolor: 'grey.50',
                                            '& .actions': { visibility: 'visible' },
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ borderRadius: 1.5, bgcolor: `hsl(${index * 40}, 70%, 50%)` }}>
                                            {member.user?.first_name?.[0]}
                                            {member.user?.last_name?.[0]}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography sx={{ fontWeight: '500' }}>
                                                    {member.user?.first_name} {member.user?.last_name}
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary' sx={{ ml: 1 }}>
                                                    ({member.user?.email})
                                                </Typography>
                                            </Box>
                                        }
                                        secondary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                                <AccessTime sx={{ fontSize: 14, mr: 0.5, color: 'text.secondary' }} />
                                                <Typography variant='caption' color='text.secondary'>
                                                    Last active: {formatLastActive(member.updated_at)}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <StatusChip
                                            type={member.is_default ? 'active' : 'limited'}
                                            label={member.is_default ? 'Active' : 'Limited'}
                                        />
                                        <IconButton size='small' className='actions' sx={{ visibility: 'hidden' }}>
                                            <MoreVert />
                                        </IconButton>
                                    </Box>
                                </ListItem>
                                {index < members.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Box>

            <Divider sx={{ mt: 1 }} />

            <AddMemberModal open={openModal} onClose={() => setOpenModal(false)} onSubmit={handleAddMember} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant='filled'>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ViewRolePermissions;
