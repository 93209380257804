import axios from 'axios';
import keycloak from '../../../keycloak';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchPaymentTicketingData = async (paginationModel, searchQuery, organizationId, timeframe = 'current') => {
    try {
        if (keycloak.authenticated) {
            // Debug token contents
            console.log('Full token parsed:', keycloak.tokenParsed);
            console.log('Token attributes:', keycloak.tokenParsed?.realm_access);
            console.log('Payment Provider ID:', keycloak.tokenParsed?.payment_provider_id);

            const paymentProviderId = keycloak.tokenParsed?.payment_provider_id;
            if (!paymentProviderId) {
                throw new Error('Payment provider ID not found in token. Please check Keycloak mapper configuration.');
            }

            const response = await axios.get(`${API_BASE_URL}/purchase-ticketings`, {
                params: {
                    page: 1,  // Hardcode to match working example
                    limit: 10, // Hardcode to match working example
                    payment_processor_id: 3
                    // payment_processor_id: paymentProviderId
                },
                headers: {
                    Authorization: `Bearer ${keycloak.token}`
                }
            });
            return response.data;
        } else {
            throw new Error('User is not authenticated');
        }
    } catch (error) {
        console.error('API Error:', error.response || error);
        throw new Error('Failed to fetch ticket data: ' + (error.message || 'Unknown error'));
    }
};