import {useEffect, useState} from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    Alert,
    Skeleton,
    CardHeader,
    useTheme
} from '@mui/material';
import SalesPerAgent from "./SalesPerAgent";
import CommissionsPerAgents from "./CommissionsPerAgents";
import Revenues from "./Revenues";
import Payout from "./Payout";
import keycloak from "../../../keycloak";
import { createTheme } from "../../../theme/create-theme"
import { Chart, useChart } from '../../material-kit/chart';
import { alpha as hexAlpha } from '@mui/material/styles';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";
import {
    CancelOutlined as CancelIcon,
    LocalActivity as TicketIcon,
    PaidOutlined as RevenueIcon,
    SellOutlined as PriceIcon
} from "@mui/icons-material";

const AdminDashboard = () => {const [organizationId, setOrganizationId] = useState(null);
    const [chartData, setChartData] = useState({
        daily: null,
        weekly: null,
        monthly: null,
        quarterly: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const timeRanges = ['daily', 'weekly', 'monthly', 'quarterly'];

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            const checkAuthentication = () => {
                if (keycloak.authenticated) {
                    const token = keycloak.tokenParsed;
                    const organization = token?.organization;
                    setOrganizationId(organization);
                    // console.log('Organization ID:', organization);
                    fetchAllChartData();
                } else {
                    console.log('User is not authenticated');
                    setLoading(false);
                    setError('User is not authenticated. Please log in.');
                    // Optionally redirect to login
                    // keycloak.login();
                }
            };

            checkAuthentication();
        }
    }, [isMounted]);

    const fetchAllChartData = async () => {
        const apiEndpoints = {
            daily: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-daily',
            weekly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-weekly',
            monthly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-monthly',
            quarterly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-quarterly'
        };

        setLoading(true);
        setError(null);
        const newChartData = {};

        for (const [key, url] of Object.entries(apiEndpoints)) {
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error?.message || `HTTP error! status: ${response.status}`);
                }
                // console.log(`Data fetched for ${key}:`, data);
                newChartData[key] = data.data;
            } catch (error) {
                console.error(`Error fetching ${key} data:`, error);
                newChartData[key] = [];
                if (key === 'quarterly') {
                    setError(`Quarterly data is currently unavailable. Error: ${error.message}`);
                }
            }
        }

        // console.log('All chart data:', newChartData);
        setChartData(newChartData);
        setLoading(false);
    };

    const chart = {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        series: [
            { name: 'Product', data: [43, 33, 22, 37, 67, 68, 37, 24, 55] },
            { name: 'Ticketing', data: [51, 70, 47, 67, 40, 37, 24, 70, 24] },
            { name: 'Booking', data: [43, 33, 22, 37, 67, 68, 37, 24, 55] },
        ],
    };

    const theme = useTheme();

    const chartColors = chart.colors ?? [
        theme.palette.primary.dark,
        hexAlpha(theme.palette.primary.light, 0.64),
    ];

    const chartOptions = useChart({
        colors: chartColors,
        stroke: {
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: chart.categories,
        },
        legend: {
            show: true,
        },
        tooltip: {
            y: {
                formatter: (value) => `${value} tickets`,
            },
        },
        ...chart.options,
    });

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: { xs: 3, md: 5 } }}>
                Hi, Welcome back 👋
            </Typography>
            {error && <Alert severity="warning" style={{marginBottom: '20px'}}>{error}</Alert>}
            {loading ? (
                <Grid container spacing={3}>
                    {/* Add loading skeleton or spinner here */}
                </Grid>
            ) : (
                <>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                                title="Number of Merchants"
                                percent={3.2}
                                total={2845}
                                icon={<TicketIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                                title="Pending Approval/Verification"
                                percent={4.5}
                                total={15420}
                                color="secondary"
                                icon={<RevenueIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                                title="Merchant Status"
                                percent={1.8}
                                total={54.2}
                                color="warning"
                                icon={<PriceIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [40, 25, 48, 32, 50, 38, 45, 54.2],
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <AnalyticsWidgetSummary
                                title="Total Sales Generated"
                                percent={-0.5}
                                total={124}
                                color="error"
                                icon={<CancelIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                                chart={{
                                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                    series: [220, 280, 180, 250, 160, 200, 140, 124],
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Card>
                        <Chart
                            type="bar"
                            series={chart.series}
                            options={chartOptions}
                            height={364}
                            sx={{py: 2.5, pl: 1, pr: 2.5}}
                        />
                    </Card>
                </>
            )}
        </Container>
    );
};

export default AdminDashboard;