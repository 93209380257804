import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const KEYCLOAK_URL = 'https://accounts.eventlah.com';
const REALM = 'eventlah';
const CLIENT_ID = 'ui-merchants-app';

// Define standard scopes
const DEFAULT_SCOPES = ['openid', 'profile', 'email', 'roles', 'offline_access'];

class AuthService {
    async login(username, password) {
        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'password',
                    client_id: CLIENT_ID,
                    username,
                    password,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token, expires_in } = response.data;
            
            // Store tokens
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('token_expiry', new Date().getTime() + (expires_in * 1000));

            this.silentRefresh(); // Start silent refresh after successful login

            return response.data;
        } catch (error) {
            console.error('Login error:', error.response?.data || error.message);
            throw new Error(error.response?.data?.error_description || 'Login failed');
        }
    }

    async silentRefresh(intervalMs = 4 * 60 * 1000) { // 4 minutes
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        this.refreshInterval = setInterval(async () => {
            if (this.isAuthenticated()) {
                try {
                    await this.refreshToken();
                } catch (error) {
                    console.error('Silent refresh failed:', error);
                    // Optionally trigger a logout or other error handling
                }
            }
        }, intervalMs);
    }

    async refreshToken() {
        const refresh_token = this.getRefreshToken();
        if (!refresh_token) {
            this.clearTokens();
            throw new Error('No refresh token available');
        }

        try {
            const response = await axios.post(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`,
                new URLSearchParams({
                    grant_type: 'refresh_token',
                    client_id: CLIENT_ID,
                    refresh_token: refresh_token,
                    scope: DEFAULT_SCOPES.join(' ')
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const { access_token, refresh_token: new_refresh_token, expires_in } = response.data;
            
            // Update stored tokens
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', new_refresh_token);
            localStorage.setItem('token_expiry', new Date().getTime() + (expires_in * 1000));

            return response.data;
        } catch (error) {
            console.error('Token refresh error:', error.response?.data || error.message);
            this.clearTokens();
            throw new Error(error.response?.data?.error_description || 'Token refresh failed');
        }
    }

    async logout() {
        const refresh_token = this.getRefreshToken();
        try {
            if (refresh_token) {
                await axios.post(
                    `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/logout`,
                    new URLSearchParams({
                        client_id: CLIENT_ID,
                        refresh_token: refresh_token
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );
            }
        } catch (error) {
            console.error('Logout error:', error.response?.data || error.message);
        } finally {
            this.clearTokens();
        }

        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }

        this.clearTokens();
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    isTokenExpired() {
        const tokenExpiry = localStorage.getItem('token_expiry');
        if (!tokenExpiry) return true;

        // Add 30-second buffer to ensure we refresh before actual expiration
        return new Date().getTime() + 30000 > parseInt(tokenExpiry);
    }

    async ensureValidToken() {
        const accessToken = this.getAccessToken();
        const refreshToken = this.getRefreshToken();

        // If no tokens at all, user needs to log in
        if (!accessToken && !refreshToken) {
            throw new Error('Not authenticated');
        }

        // If token is expired and we have a refresh token, try to refresh
        if (this.isTokenExpired() && refreshToken) {
            try {
                const result = await this.refreshToken();
                return result.access_token;
            } catch (error) {
                console.error('Token refresh failed:', error);
                this.clearTokens();
                throw new Error('Authentication expired');
            }
        }

        // If token exists and is not expired, return it
        if (accessToken && !this.isTokenExpired()) {
            return accessToken;
        }

        // If we get here, something is wrong with the tokens
        this.clearTokens();
        throw new Error('Invalid authentication state');
    }

    clearTokens() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expiry');
    }

    async getUserInfo() {
        try {
            // Ensure we have a valid token before making the request
            const token = await this.ensureValidToken();
            
            const response = await axios.get(
                `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/userinfo`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Decode token to get additional info
            const decodedToken = jwtDecode(token);
            
            // Combine userinfo and token data
            return {
                ...response.data,
                organization_roles: decodedToken.organization_roles || [],
                realm_roles: decodedToken.realm_access?.roles || [],
                resource_access: decodedToken.resource_access || {},
                organization_id: decodedToken.organization_id
            };
        } catch (error) {
            console.error('Error fetching user info:', error);
            if (error.response?.status === 401) {
                this.clearTokens();
                throw new Error('Session expired');
            }
            throw error;
        }
    }
}

export const authService = new AuthService();
