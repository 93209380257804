import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const SimpleLayout = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default SimpleLayout;
