import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrganizationDomain } from '../api/organizationDomainApi';

const initialState = {
    domain: null,
    status: 'idle',
    error: null
};

export const fetchOrganizationDomain = createAsyncThunk(
    'organizationDomain/fetchDomain',
    async (organizationId, { rejectWithValue }) => {
        try {
            const response = await getOrganizationDomain(organizationId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const organizationDomainSlice = createSlice({
    name: 'organizationDomain',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationDomain.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationDomain.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.domain = action.payload;
            })
            .addCase(fetchOrganizationDomain.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch domain';
            });
    }
});

export const selectOrganizationDomain = (state) => state.organizationDomain.domain;
export const selectDomainStatus = (state) => state.organizationDomain.status;
export const selectDomainError = (state) => state.organizationDomain.error;

export default organizationDomainSlice.reducer;
