import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    Alert,
    Skeleton,
    CardHeader,
    useTheme,
} from '@mui/material';
import ChartComponent from './ChartComponent';
import keycloak from '../../keycloak';
import { Chart, useChart } from '../material-kit/chart';
import { alpha as hexAlpha } from '@mui/material/styles';
import AnalyticsWidgetSummary from '../shared/AnalyticsWidgetSummary';
import { AnalyticsCurrentVisits } from '../shared/analytics-current-visits';
import { AnalyticsWebsiteVisits } from '../shared/analytics-website-visits';
import {
    CancelOutlined as CancelIcon,
    LocalActivity as TicketIcon,
    PaidOutlined as RevenueIcon,
    SellOutlined as PriceIcon,
} from '@mui/icons-material';
import PermissionGate from '../shared/PermissionGate';
import { useKeycloak } from '../../contexts/KeycloakContext';

const MainDashboard = () => {
    const { isAuthenticated } = useKeycloak();
    const [organizationId, setOrganizationId] = useState(null);
    const [chartData, setChartData] = useState({
        daily: null,
        weekly: null,
        monthly: null,
        quarterly: null,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
  
    const timeRanges = ['daily', 'weekly', 'monthly', 'quarterly'];

    useEffect(() => {
        console.log('MainDashboard component has mounted');
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && isAuthenticated) {
            const token = keycloak.tokenParsed;
            const organization = token?.organization;
            setOrganizationId(organization);
            fetchAllChartData();
        }
    }, [isMounted, isAuthenticated]);

    const fetchAllChartData = async () => {
        const apiEndpoints = {
            daily: `${process.env.REACT_APP_API_BASE_URL}/merchant-reportings?report=line-daily`,
            weekly: `${process.env.REACT_APP_API_BASE_URL}/merchant-reportings?report=line-weekly`,
            monthly: `${process.env.REACT_APP_API_BASE_URL}/merchant-reportings?report=line-monthly`,
            quarterly: `${process.env.REACT_APP_API_BASE_URL}/merchant-reportings?report=line-quarterly`,
        };

        setLoading(true);
        setError(null);
        const newChartData = {};

        for (const [key, url] of Object.entries(apiEndpoints)) {
            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                    },
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error?.message || `HTTP error! status: ${response.status}`);
                }
                // console.log(`Data fetched for ${key}:`, data);
                newChartData[key] = data.data;
            } catch (error) {
                console.error(`Error fetching ${key} data:`, error);
                newChartData[key] = [];
                if (key === 'quarterly') {
                    setError(`Quarterly data is currently unavailable. Error: ${error.message}`);
                }
            }
        }

        // console.log('All chart data:', newChartData);
        setChartData(newChartData);
        setLoading(false);
    };

    const chart = {
        categories: Array.from({length: 31}, (_, i) => (i + 1).toString()),
        series: [
            { name: 'Adult', data: Array.from({length: 31}, () => Math.floor(Math.random() * (400 - 100 + 1)) + 100) },
            { name: 'Kids', data: Array.from({length: 31}, () => Math.floor(Math.random() * (200 - 50 + 1)) + 50) },
            { name: 'Senior', data: Array.from({length: 31}, () => Math.floor(Math.random() * (150 - 20 + 1)) + 20) },
        ],
    };

    const theme = useTheme();

    const chartColors = chart.colors ?? [theme.palette.primary.dark, hexAlpha(theme.palette.primary.light, 0.64)];

    const chartOptions = useChart({
        colors: chartColors,
        stroke: {
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: chart.categories,
        },
        legend: {
            show: true,
        },
        tooltip: {
            y: {
                formatter: value => `${value} tickets`,
            },
        },
        ...chart.options,
    });

    if (!isAuthenticated) {
        return <Alert severity='error'>User is not authenticated. Please log in.</Alert>;
    }

    console.log('MainDashboard component is being rendered');

    return (
        <Box sx={{ padding: '34px' }}>
            <Typography variant='h4' sx={{ mb: { xs: 3, md: 5 } }}>
                Hi, Welcome back 👋
            </Typography>
            {error && (
                <Alert severity='warning' style={{ marginBottom: '20px' }}>
                    {error}
                </Alert>
            )}
            {loading ? (
                <Grid container spacing={3}>
                    {/* Add loading skeleton or spinner here */}
                </Grid>
            ) : (
                <>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MERC_VIEW' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Monthly Tickets Sold'
                                    percent={3.2}
                                    total={2845}
                                    icon={
                                        <TicketIcon
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                color: '#ffeb3b',
                                            }}
                                        />
                                    }
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MERC_VIEW1' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title="Today's Revenue"
                                    percent={4.5}
                                    total={15420}
                                    color='secondary'
                                    icon={
                                        <RevenueIcon
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                color: 'secondary.main',
                                            }}
                                        />
                                    }
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MERC_VIEW2' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Average Ticket Price'
                                    percent={1.8}
                                    total={54.2}
                                    color='warning'
                                    icon={
                                        <PriceIcon
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                color: 'warning.main',
                                            }}
                                        />
                                    }
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [40, 25, 48, 32, 50, 38, 45, 54.2],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <PermissionGate featureCode='MERC_VIEW3' fallback={null}>
                                <AnalyticsWidgetSummary
                                    title='Cancelled Tickets'
                                    percent={-0.5}
                                    total={124}
                                    color='error'
                                    icon={
                                        <CancelIcon
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                color: 'error.main',
                                            }}
                                        />
                                    }
                                    chart={{
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        series: [220, 280, 180, 250, 160, 200, 140, 124],
                                    }}
                                />
                            </PermissionGate>
                        </Grid>
                    </Grid>

                    <PermissionGate featureCode='DASH1' fallback={null}>
                        <Card>
                            <Chart
                                type='bar'
                                series={chart.series}
                                options={chartOptions}
                                height={364}
                                sx={{ py: 2.5, pl: 1, pr: 2.5 }}
                            />
                        </Card>
                    </PermissionGate>
                </>
            )}
        </Box>
    );
};

export default MainDashboard;
