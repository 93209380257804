import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Divider,
    CircularProgress,
    Alert
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import axios from 'axios';
import keycloak from "../../../../keycloak";
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const FeatureSection = ({ feature, selectedPermissions, onPermissionChange }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => setExpanded(!expanded);

    const handleMainCheckboxChange = (event) => {
        const newPermissions = feature.children.map(child => ({
            featureId: child.id,
            allowed: event.target.checked
        }));
        onPermissionChange(newPermissions);
    };

    const handleItemCheckboxChange = (childId) => (event) => {
        onPermissionChange([{
            featureId: childId,
            allowed: event.target.checked
        }]);
    };

    const checkedCount = feature.children?.filter(
        child => selectedPermissions[child.id]?.allowed
    ).length || 0;
    const totalItems = feature?.children?.length || 0;

    return (
        <Box sx={{ mb: 1 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkedCount === totalItems && totalItems > 0}
                        indeterminate={checkedCount > 0 && checkedCount < totalItems}
                        onChange={handleMainCheckboxChange}
                    />
                }
                label={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography variant="subtitle1">{feature.name}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                {checkedCount}/{totalItems}
                            </Typography>
                            <Button
                                onClick={toggleExpand}
                                variant='text'
                                endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                                sx={{ minWidth: 'auto' }}
                            />
                        </Box>
                    </Box>
                }
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    ml: 0,
                    borderRadius: '8px',
                    padding: '4px',
                    boxSizing: 'border-box',
                    '& .MuiFormControlLabel-label': {
                        flex: 1,
                        fontWeight: '600',
                    }
                }}
            />
            {expanded && feature.children && (
                <Box sx={{ ml: 6, display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                    {feature.children.map((child) => {
                        const isAllowed = selectedPermissions[child.id]?.allowed || false;
                        
                        return (
                            <Box 
                                key={child.id} 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: 'background.paper',
                                    borderRadius: 1,
                                    p: 0.5
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography variant="body2">{child.name}</Typography>
                                    <Checkbox 
                                        checked={isAllowed}
                                        onChange={handleItemCheckboxChange(child.id)}
                                        size="small"
                                    />
                                </Box>
                                <Typography 
                                    variant="caption" 
                                    color="text.secondary"
                                    sx={{ 
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        ml: 2
                                    }}
                                >
                                    {child.description}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

const AddRoleModal = ({ open, onClose, onSubmit, editRole }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [features, setFeatures] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${BASE_API_URL}/features`);
                setFeatures(response.data.data.features || []);

                if (editRole) {
                    setName(editRole.name);
                    setDescription(editRole.description || '');
                    
                    const permissions = {};
                    editRole.roleFeatures?.forEach(rf => {
                        const featureId = rf.feature?.id;
                        if (featureId) {
                            permissions[featureId] = {
                                allowed: rf.can_read || rf.can_write || rf.can_delete
                            };
                        }
                    });
                    setSelectedPermissions(permissions);
                } else {
                    setName('');
                    setDescription('');
                    setSelectedPermissions({});
                }
            } catch (err) {
                console.error('Error fetching features:', err);
                setError('Failed to fetch features');
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchFeatures();
        }
    }, [open, editRole]);

    const handlePermissionChange = (featureChanges) => {
        const newPermissions = { ...selectedPermissions };
        featureChanges.forEach(change => {
            const { featureId, allowed } = change;
            newPermissions[featureId] = { allowed };
        });
        setSelectedPermissions(newPermissions);
    };

    const handleSubmit = () => {
        const organizationId = keycloak.tokenParsed?.organization_id;
        
        if (!organizationId) {
            setError('Organization ID not found. Please try logging in again.');
            return;
        }

        const roleFeatures = Object.entries(selectedPermissions)
            .filter(([_, value]) => value.allowed)
            .map(([featureId, _]) => ({
                feature_id: Number(featureId),
                can_read: true,
                can_write: true,
                can_delete: true
            }));

        onSubmit({
            id: editRole?.id,
            name,
            description,
            role_features: roleFeatures,
            organization_id: organizationId
        });
    };

    // Group features by their type
    const groupedFeatures = features.reduce((acc, feature) => {
        const type = feature.code.includes('OVW') ? 'overview' :
                    feature.code.includes('SET') ? 'setup' : 'other';
        if (!acc[type]) acc[type] = [];
        acc[type].push(feature);
        return acc;
    }, {});

    const sections = [
        { title: 'Overview Features', features: groupedFeatures.overview || [] },
        { title: 'Setup Features', features: groupedFeatures.setup || [] },
        { title: 'Other Features', features: groupedFeatures.other || [] }
    ];

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '900px',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <DialogTitle>{editRole ? 'Edit Role' : 'Create New Role'}</DialogTitle>
            <DialogContent sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                flex: 1,
                overflow: 'hidden'
            }}>
                <Box sx={{ 
                    pt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            label="Role Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={2}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                        />
                    </Box>
                    
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Permissions
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ 
                            flex: 1,
                            overflow: 'auto',
                            p: 2
                        }}>
                            {sections.map((section, index) => (
                                <React.Fragment key={section.title}>
                                    <Typography variant="h6" sx={{ mb: 1 }}>
                                        {section.title}
                                    </Typography>
                                    {section.features.map(feature => (
                                        <FeatureSection
                                            key={feature.id}
                                            feature={feature}
                                            selectedPermissions={selectedPermissions}
                                            onPermissionChange={handlePermissionChange}
                                        />
                                    ))}
                                    {index < sections.length - 1 && <Divider sx={{ my: 2 }} />}
                                </React.Fragment>
                            ))}
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2, bgcolor: 'background.paper' }}>
                <Button onClick={onClose} variant="outlined" color="inherit">
                    Cancel
                </Button>
                <Button 
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!name.trim() || loading}
                >
                    {editRole ? 'Save Changes' : 'Create Role'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddRoleModal;
