import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Paper, Container, CircularProgress, useMediaQuery, Alert } from '@mui/material';
import axios from 'axios';
import {CompanyLogo} from "../header/HeaderSvgImages";
import {ThemeProvider} from "../../theme/theme-provider";
import { createTheme } from "../../theme/create-theme";


const EmailVerificationPage = () => {
    const theme = createTheme();
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [showResend, setShowResend] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.email) {
            const email = location.state.email;
            setEmail(email);
            
            // Start countdown timer
            const timer = setInterval(() => {
                setCountdown(prev => {
                    const newCount = prev - 1;
                    if (newCount <= 0) {
                        clearInterval(timer);
                        setShowResend(true);
                        return 0;
                    }
                    return newCount;
                });
            }, 1000);
            
            return () => clearInterval(timer);
        }
    }, [location]);

    const handleChange = (index, value) => {
        const newCode = [...code];
        
        // Handle paste operation
        if (value.length > 1) {
            const pasteDigits = value.split('').slice(0, 6);
            pasteDigits.forEach((digit, i) => {
                if (i < newCode.length) {
                    newCode[i] = digit;
                }
            });
            setCode(newCode);
            
            // Move focus to last input
            if (pasteDigits.length === 6) {
                document.getElementById(`code-5`).focus();
            }
            return;
        }

        // Handle single digit input
        if (value.length === 1) {
            newCode[index] = value;
            setCode(newCode);
            
            // Move focus to the next input only if value exists and not last input
            if (value && index < 5) {
                document.getElementById(`code-${index + 1}`).focus();
            }
        } else if (value.length === 0) {
            // Handle backspace/delete
            newCode[index] = '';
            setCode(newCode);
            
            // Move focus to previous input if not first input
            if (index > 0) {
                document.getElementById(`code-${index - 1}`).focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        if (pasteData) {
            handleChange(0, pasteData);
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        setError('');

        // Validate all code digits are entered
        if (code.some(digit => !digit)) {
            setError('Please enter the full 6-digit OTP code');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/otp`, {
                email: email,
                code: code.join('')
            });

            if (response.status === 200 && response.data.code === 200) {
                setSuccess(true);
                setError(null);
                navigate('/first'); // Use navigate instead of router.push
            } else {
                setError(response.data?.description || 'Unexpected response from server');
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.description || 'Failed to verify OTP. Please try again.');
            } else if (error.request) {
                setError('No response from server. Please check your internet connection and try again.');
            } else {
                setError('An error occurred. Please try again.');
            }
            console.error('Error verifying OTP:', error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, backgroundColor: '#ffeb3b' }}>
                        <Typography sx={{fontSize: { xs: '20px', sm: '24px' }, fontWeight:'700', textAlign:'center', mt: 4}}>
                            Please check your email
                        </Typography>
                        <Typography variant="body2" gutterBottom align="center" color="text.secondary" sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                            We've sent a code to {email}
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', my: { xs: 2, sm: 4 } }}>
                            {code.map((digit, index) => (
                                <React.Fragment key={index}>
                                    <TextField
                                        id={`code-${index}`}
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onPaste={handlePaste}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 1,
                                            sx: { textAlign: 'center', backgroundColor: 'white' },
                                        }}
                                        sx={{
                                            width: { xs: '35px', sm: '40px' },
                                            m: { xs: 0.5, sm: 1 }
                                        }}
                                    />
                                    {index < code.length - 1 && !isMobile && (
                                        <Typography sx={{fontSize:'24px', fontWeight:'200', textAlign:'center', mx:1}}>-</Typography>
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                        {error && (
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                        {success && (
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="success">Email verified successfully!</Alert>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                                variant="cancel"
                                color="primary"
                                onClick={handleVerify}
                                disabled={isLoading}
                                sx={{
                                    width: { xs: '120px', sm: '150px' },
                                    borderRadius: '12px',
                                    mt: 2,
                                    mb: 2,
                                    fontSize: { xs: '14px', sm: '16px' }
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Verify'}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            {showResend ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                        try {
                                            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/otp?action=resend`, {
                                                email: email
                                            });
                                            
                                            if (response.status === 200 || response.status === 201) {
                                                setCode(['', '', '', '', '', '']);
                                                setCountdown(60);
                                                setShowResend(false);
                                                setError(null);
                                                // Restart the countdown timer
                                                const timer = setInterval(() => {
                                                    setCountdown(prev => {
                                                        const newCount = prev - 1;
                                                        if (newCount <= 0) {
                                                            clearInterval(timer);
                                                            setShowResend(true);
                                                            return 0;
                                                        }
                                                        return newCount;
                                                    });
                                                }, 1000);
                                                // Cleanup timer on component unmount
                                                return () => clearInterval(timer);
                                            }
                                        } catch (error) {
                                            if (error.response) {
                                                if (error.response.status === 429) {
                                                    const retryAfter = error.response.data.retryAfter || 60;
                                                    setCountdown(retryAfter);
                                                    setShowResend(false);
                                                    setError('Please wait before requesting a new OTP');
                                                } else {
                                                    setError(error.response.data?.message || 'Failed to resend OTP. Please try again.');
                                                }
                                            } else {
                                                setError('Error sending request. Please try again.');
                                            }
                                        }
                                    }}
                                    sx={{
                                        width: { xs: '120px', sm: '150px' },
                                        borderRadius: '12px',
                                        mt: 2,
                                        mb: 2,
                                        fontSize: { xs: '14px', sm: '16px' }
                                    }}
                                >
                                    Resend OTP
                                </Button>
                            ) : (
                                <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                    Resend code in {countdown} seconds
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default EmailVerificationPage;
