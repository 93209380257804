import {LocalActivity as LocalActivityIcon, Percent as PercentIcon, ShoppingCart as ShoppingCartIcon} from "@mui/icons-material";

 export const promotionsByCategory = {
    ticketing: [
        {
            id: 'percentage-discount',
            title: 'Percentage Discount',
            description: 'Offer a percentage off the total purchase amount',
            icon: PercentIcon,
            example: 'e.g., 20% off on all items',
        },
        {
            id: 'fixed-amount',
            title: 'Fixed Amount Discount',
            description: 'Provide a specific amount off the total purchase',
            icon: ShoppingCartIcon,
            example: 'e.g., RM10 off on purchases above RM50',
        },
        {
            id: 'free-tickets',
            title: 'Free Tickets Allocation',
            description: 'Grant a set number of free tickets',
            icon: LocalActivityIcon,
            example: 'e.g., Free entry pass with purchase',
        }
    ],
    products: [
        {
            id: 'percentage-discount',
            title: 'Percentage Discount',
            description: 'Offer a percentage off the total purchase amount',
            icon: PercentIcon,
            example: 'e.g., 20% off on all items',
        },
        {
            id: 'fixed-amount',
            title: 'Fixed Amount Discount',
            description: 'Provide a specific amount off the total purchase',
            icon: ShoppingCartIcon,
            example: 'e.g., RM10 off on purchases above RM50',
        },
        {
            id: 'free-tickets',
            title: 'Free Product Allocation',
            description: 'Grant a set number of free products',
            icon: LocalActivityIcon,
            example: 'e.g., Free product with purchase',
        }
    ],
    bookings: [
        {
            id: 'percentage-discount',
            title: 'Percentage Discount',
            description: 'Offer a percentage off the total purchase amount',
            icon: PercentIcon,
            example: 'e.g., 20% off on all items',
        },
        {
            id: 'fixed-amount',
            title: 'Fixed Amount Discount',
            description: 'Provide a specific amount off the total purchase',
            icon: ShoppingCartIcon,
            example: 'e.g., RM10 off on purchases above RM50',
        },
        {
            id: 'free-tickets',
            title: 'Free Booking Allocation',
            description: 'Grant a set number of free bookings',
            icon: LocalActivityIcon,
            example: 'e.g., Free booking with purchase',
        }
    ]
};