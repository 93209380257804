import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Divider,
    Skeleton,
    Snackbar,
    Alert,
    IconButton,
} from '@mui/material';
import {
    Add as AddIcon,
    EditOutlined as EditOutlinedIcon,
    DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import AddRoleModal from './AddRoleModal';
import axios from 'axios';
import { format } from 'date-fns';
import PermissionGate from 'components/shared/PermissionGate';

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

const Roles = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [editingRole, setEditingRole] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [roles, setRoles] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchRoles = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_API_URL}/roles`, {
                params: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    search: searchQuery,
                },
            });

            const { roles, pagination } = response.data.data;
            setRoles(
                roles.map(role => ({
                    ...role,
                    permissions: role.roleFeatures
                        ? role.roleFeatures.map(rf => ({
                              read: rf.can_read,
                              write: rf.can_write,
                              delete: rf.can_delete,
                              feature: rf.feature.name,
                          }))
                        : [],
                    lastUpdated: format(new Date(role.updated_at), 'MMM dd, yyyy'),
                }))
            );
            setTotalCount(pagination.totalCount);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setSnackbar({
                open: true,
                message: 'Failed to fetch roles',
                severity: 'error',
            });
        } finally {
            setLoading(false);
            setIsInitialLoad(false);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, [paginationModel, searchQuery]);

    const handleCreateOrUpdateRole = async roleData => {
        try {
            if (roleData.id) {
                // Update existing role
                await axios.put(`${BASE_API_URL}/roles?id=${roleData.id}`, roleData);
                setSnackbar({
                    open: true,
                    message: 'Role updated successfully',
                    severity: 'success',
                });
            } else {
                // Create new role
                await axios.post(`${BASE_API_URL}/roles`, roleData);
                setSnackbar({
                    open: true,
                    message: 'Role created successfully',
                    severity: 'success',
                });
            }
            setOpenModal(false);
            setEditingRole(null);
            fetchRoles(); // Refresh the roles list
        } catch (error) {
            console.error('Error saving role:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Failed to save role',
                severity: 'error',
            });
        }
    };

    const handleDeleteRole = async id => {
        try {
            await axios.delete(`${BASE_API_URL}/roles?id=${id}`);
            setSnackbar({
                open: true,
                message: 'Role deleted successfully',
                severity: 'success',
            });
            fetchRoles(); // Refresh the roles list
        } catch (error) {
            console.error('Error deleting role:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Failed to delete role',
                severity: 'error',
            });
        }
    };

    const handleEditRole = role => {
        setEditingRole(role);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setEditingRole(null);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'ROLE NAME',
            flex: 1,
            renderCell: params => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant='body2' sx={{ fontWeight: 500 }}>
                        {params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'description',
            headerName: 'DESCRIPTION',
            flex: 2,
            renderCell: params => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant='body2' color='text.secondary'>
                        {params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'lastUpdated',
            headerName: 'LAST UPDATED',
            flex: 1,
            renderCell: params => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant='body2' color='text.secondary'>
                        {params.value}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            renderCell: params => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {/*<PermissionGate featureCode='TEAM_UPDA' fallback={null}>*/}
                        <IconButton aria-label='edit' onClick={() => handleEditRole(params.row)} size='small'>
                            <EditOutlinedIcon />
                        </IconButton>
                    {/*</PermissionGate>*/}
                    {/*<PermissionGate featureCode='TEAM_DELE' fallback={null}>*/}
                        <IconButton aria-label='delete' onClick={() => handleDeleteRole(params.row.id)} size='small'>
                            <DeleteOutlineIcon />
                        </IconButton>
                    {/*</PermissionGate>*/}
                </Box>
            ),
        },
    ];

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    if (isInitialLoad) {
        return <Skeleton variant='rectangular' height={400} />;
    }

    return (
        <Box>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextField
                    size='small'
                    placeholder='Search roles...'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    sx={{ width: 300 }}
                />
                <PermissionGate featureCode='TEAM_CREA' fallback={null}>
                    <Button
                        variant='contained'
                        startIcon={<AddIcon />}
                        onClick={() => setOpenModal(true)}
                        sx={{ textTransform: 'none' }}
                    >
                        Create Role
                    </Button>
                </PermissionGate>
            </Box>

            <Paper elevation={0} variant='outlined'>
                <DataGrid
                    rows={roles}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 25]}
                    rowCount={totalCount}
                    loading={loading}
                    disableRowSelectionOnClick
                    autoHeight
                    paginationMode='server'
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderColor: 'divider',
                            alignItems: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            bgcolor: 'grey.50',
                            borderColor: 'divider',
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            padding: 2,
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                        '& .MuiDataGrid-row': {
                            alignItems: 'center',
                        },
                    }}
                />
            </Paper>

            <AddRoleModal
                open={openModal}
                onClose={handleCloseModal}
                onSubmit={handleCreateOrUpdateRole}
                editRole={editingRole}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant='filled'>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Roles;
