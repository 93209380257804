import {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Switch,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    styled,
    TextField,
    MenuItem,
    FormControl,
    InputAdornment, Alert, Snackbar
} from '@mui/material';
import {ContentState, convertFromHTML, Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";

const DocumentLinkInput = ({ links, onLinksChange, materialId, organizationId }) => {
    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
    const [newLink, setNewLink] = useState({ name: '', url: '' });

    const handleAddLink = async () => {
        if (newLink.name && newLink.url) {
            try {
                const response = await axios.post(`${BASE_API_URL}/agent-document-links`, {
                    document_name: newLink.name,
                    document_url: newLink.url,
                    organization_id: organizationId,
                    material_id: materialId,
                });
                if (response.data && response.data.data) {
                    onLinksChange([...links, response.data.data]);
                    setNewLink({ name: '', url: '' });
                }
            } catch (error) {
                console.error('Error adding document link:', error);
                // Optionally, show an error message to the user
            }
        } else {
            // Optionally, show a validation error to the user
            console.error('Both name and URL are required');
        }
    };

    const handleRemoveLink = async (id) => {
        try {
            await axios.delete(`${BASE_API_URL}/agent-document-links?id=${id}`);
            const updatedLinks = links.filter(link => link.id !== id);
            onLinksChange(updatedLinks);
        } catch (error) {
            console.error('Error removing document link:', error);
        }
    };

    return (
        <Box>
            <TextField
                label="Document Name"
                value={newLink.name}
                onChange={(e) => setNewLink({ ...newLink, name: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Document URL"
                value={newLink.url}
                onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleAddLink} variant="cancel" color="primary">
                Add Document Link
            </Button>
            <List>
                {links.map((link) => (
                    <ListItem key={link.id}>
                        <ListItemText primary={link.document_name} secondary={link.document_url} />
                        <IconButton onClick={() => handleRemoveLink(link.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const VideoEmbedInput = ({ videos, onVideosChange, addVideo }) => {
    const [newVideo, setNewVideo] = useState({ title: '', url: '', description: '' });
    const [error, setError] = useState('');

    const handleAddVideo = async () => {
        setError('');
        if (!newVideo.video_title || !newVideo.video_url) {
            setError('Both title and URL are required');
            return;
        }

        try {
            await addVideo(newVideo);
            setNewVideo({ video_title: '', video_url: '', video_description: '' });
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while adding the video');
        }
    };

    return (
        <Box>
            <TextField
                label="Video Title"
                value={newVideo.video_title}
                onChange={(e) => setNewVideo({ ...newVideo, video_title: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Video URL"
                value={newVideo.video_url}
                onChange={(e) => setNewVideo({ ...newVideo, video_url: e.target.value })}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Video Description"
                value={newVideo.video_description}
                onChange={(e) => setNewVideo({ ...newVideo, video_description: e.target.value })}
                fullWidth
                margin="normal"
                multiline
                rows={3}
            />
            <Button onClick={handleAddVideo} variant="cancel" color="primary">
                Add Video Link
            </Button>
            {error && <Typography color="error">{error}</Typography>}
            <List>
                {videos.map((video, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={video.video_title} secondary={video.video_url} />
                        <IconButton onClick={() => onVideosChange(videos.filter((_, i) => i !== index))}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const CustomEditor = ({ editorState, onEditorChange }) => {
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (inlineStyle) => {
        onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <IconButton onClick={() => toggleInlineStyle('BOLD')}>
                    <FormatBoldIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('ITALIC')}>
                    <FormatItalicIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('UNDERLINE')}>
                    <FormatUnderlinedIcon />
                </IconButton>
            </Box>
            <Box sx={{ border: '1px solid #ccc', borderRadius: '12px', minHeight: '200px', padding: '0 10px 10px 10px' }}>
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand}
                />
            </Box>
        </Box>
    );
};

const SettingRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: '16px 0',
    width: '100%',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ConstrainedContainer = styled(Box)({
    maxWidth: '320px',
    width: '100%'
});

const FieldContainer = styled(Box)({
    width: '100%'
});

const MaterialSettings = () => {
    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
    const [isSaving, setIsSaving] = useState(false);
    const [documentLinks, setDocumentLinks] = useState([]);
    const [trainingVideos, setTrainingVideos] = useState([]);
    const [settings, setSettings] = useState({
        provideTrainingMaterials: false
    });
    const [currentMaterialId, setCurrentMaterialId] = useState(null);
    const [organizationId, setOrganizationId] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [hasChanges, setHasChanges] = useState(false);
    const [materials, setMaterials] = useState([]);

    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        fetchMaterials();
        fetchDocumentLinks();
        fetchTrainingVideos();
        setOrganizationId(1);
    }, []);

    const fetchMaterials = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_API_URL}/agent-materials`);
            console.log('API Response:', response.data);

            if (response.data.data.materials.length > 0) {
                const material = response.data.data.materials[0];
                setCurrentMaterialId(material.id);
                console.log('First Material:', material);

                setSettings({
                    provideTrainingMaterials: material.enabled,
                    showToAgent: material.show_to_agent
                });

                // Log the content field
                console.log('Material Content:', material.content);

                // Convert the HTML content to EditorState
                const blocksFromHTML = convertFromHTML(material.content);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(contentState));

                setMaterials(response.data.data.materials);
            } else {
                await createNewMaterial();
            }
        } catch (error) {
            console.error('Error fetching materials:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDocumentLinks = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/agent-document-links`);
            if (response.data && response.data.data && response.data.data.documentLinks) {
                setDocumentLinks(response.data.data.documentLinks);
            }
        } catch (error) {
            console.error('Error fetching document links:', error);
        }
    };

    const fetchTrainingVideos = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/agent-training-videos`, {
                params: {
                    material_id: currentMaterialId,
                    organization_id: organizationId
                }
            });
            if (response.data && response.data.data && response.data.data.trainingVideos) {
                setTrainingVideos(response.data.data.trainingVideos);
            }
        } catch (error) {
            console.error('Error fetching training videos:', error);
        }
    };

    const createNewMaterial = async () => {
        try {
            const newMaterialData = {
                organization_id: 1, // Replace with actual org ID
                title: "New Material",
                content: "",
                is_active: true,
                sort_order: 1,
                show_to_agent: false,
                enabled: false
            };
            const response = await axios.post(`${BASE_API_URL}/api/agent-materials`, newMaterialData);
            setMaterials([response.data.data]);
            setSettings({
                provideTrainingMaterials: false,
                showToAgent: false
            });
            setEditorState(EditorState.createEmpty());
        } catch (error) {
            console.error('Error creating new material:', error);
        }
    };

    const addTrainingVideo = async (videoData) => {
        try {
            const response = await axios.post(`${BASE_API_URL}/agent-training-videos`, {
                ...videoData,
                material_id: currentMaterialId,
                organization_id: organizationId
            });
            if (response.data && response.data.data) {
                setTrainingVideos([...trainingVideos, response.data.data]);
            }
        } catch (error) {
            console.error('Error adding training video:', error);
            throw error;
        }
    };

    const handleDocumentLinksChange = (newLinks) => {
        setDocumentLinks(newLinks);
        setHasChanges(true);
    };

    const handleTrainingVideosChange = (newVideos) => {
        setTrainingVideos(newVideos);
        setHasChanges(true);
    };

    const handleChange = (field) => (event) => {
        setSettings(prev => ({
            ...prev,
            [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }));
        setHasChanges(true);
    };

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        setHasChanges(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const updatedMaterial = {
                ...materials[0],
                enabled: settings.provideTrainingMaterials,
                show_to_agent: settings.showToAgent,
                content: editorState.getCurrentContent().getPlainText(),
                // Add logic to include document links and training videos in the content
            };
            await axios.post(`${BASE_API_URL}/agent-materials?id=${updatedMaterial.id}`, updatedMaterial);
            setHasChanges(false);
            setSnackbar({
                open: true,
                message: 'Material saved successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error saving material:', error);
            setSnackbar({
                open: true,
                message: 'Error saving material. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsSaving(false);
        }
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <Box>
            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Provide Training Materials
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        Enable to provide training materials to agents
                    </Typography>
                </FieldContainer>
                <StyledSwitch
                    checked={settings.provideTrainingMaterials}
                    onChange={handleChange('provideTrainingMaterials')}
                />
            </SettingRow>

            {settings.provideTrainingMaterials && (
                <>
                    <SettingRow>
                        <FieldContainer>
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Show to Agent
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Toggle to show or hide this material from agents
                            </Typography>
                        </FieldContainer>
                        <StyledSwitch
                            checked={settings.showToAgent}
                            onChange={handleChange('showToAgent')}
                        />
                    </SettingRow>

                    <SettingRow>
                        <FieldContainer>
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Write your services or product selling points so agents can use them for promotions. Focus on unique features and benefits to help agents effectively communicate value to potential customers.
                            </Typography>
                            <CustomEditor
                                editorState={editorState}
                                onEditorChange={handleEditorChange}
                            />
                        </FieldContainer>
                    </SettingRow>
                    <SettingRow>
                        <FieldContainer>
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Document Links
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Add links to relevant documents, such as product manuals, pricing sheets, or case studies. Use descriptive names for each link.
                            </Typography>
                            <DocumentLinkInput
                                links={documentLinks}
                                onLinksChange={setDocumentLinks}
                                materialId={currentMaterialId}
                                organizationId={organizationId}
                            />
                        </FieldContainer>
                    </SettingRow>
                    <SettingRow>
                        <FieldContainer>
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Training Videos
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Embed video links from platforms like YouTube or Vimeo. Include product demos, tutorials, or customer testimonials to enhance agent training.
                            </Typography>
                            <VideoEmbedInput
                                videos={trainingVideos}
                                onVideosChange={handleTrainingVideosChange}
                                addVideo={addTrainingVideo}
                            />
                        </FieldContainer>
                    </SettingRow>
                </>
            )}

            <Box sx={{ mt: 3 }}>
                <Button
                    variant="save"
                    color="primary"
                    disabled={!hasChanges}
                    onClick={handleSave}
                >
                    Save Changes
                </Button>
            </Box>
        </Box>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    </>
    );
};

export default MaterialSettings;