import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography, Paper } from "@mui/material";
import StoreSetupForm from "../../shared/StoreSetupForm";
import { useKeycloak } from '../../../contexts/KeycloakContext';
import { registerUser } from '../../../api/auth';

const StoreSetup = () => {
    const [saveSuccess, setSaveSuccess] = useState(false);
    const { userInfo, isAuthenticated } = useKeycloak();
    const [organizationId, setOrganizationId] = useState(null);

    useEffect(() => {
        if (isAuthenticated && userInfo) {
            setOrganizationId(userInfo.organization_id);
        }
    }, [isAuthenticated, userInfo]);

    const handleSaveSuccess = async (storeData) => {
        try {
            if (!organizationId) {
                throw new Error('Organization ID is required');
            }

            // Add organization ID to store data
            const dataWithOrg = {
                ...storeData,
                organization_id: organizationId
            };

            // Call API to save store setup
            const response = await registerUser(dataWithOrg);
            
            if (response.status === 200) {
                setSaveSuccess(true);
            } else {
                throw new Error(response.error || 'Failed to save store setup');
            }
        } catch (error) {
            console.error('Store setup error:', error);
            // Handle error appropriately
        }
    };

    return (
        <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <Typography variant='h4'>
                    Store Setup
                </Typography>
                <Typography variant='h5'>
                    Please provide your store details to begin setting up your online presence on Eventlah.
                </Typography>
            </Box>

            <Divider/>

            <StoreSetupForm onSaveSuccess={handleSaveSuccess} />

            {saveSuccess && (
                <Typography color="success" sx={{ mt: 2 }}>
                    Store setup saved successfully!
                </Typography>
            )}
        </Paper>
    );
};

export default StoreSetup;
