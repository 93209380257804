import React, { useState, useEffect } from "react";
import { 
    Box, 
    Button, 
    Divider, 
    Paper, 
    Typography, 
    Stepper, 
    Step, 
    StepLabel, 
    Link, 
    Checkbox, 
    FormControlLabel, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    Container,
    CircularProgress,
    Tooltip,
    Snackbar,
    Alert
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { agreements } from "./Agreements";
import keycloak from "../../keycloak";
import { setCurrentStep, updateOnboardingRecord } from "../../redux/accountOnboardingSlice";
import { useDispatch } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const StripeKYCVerification = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(1);
    const [isTosChecked, setIsTosChecked] = useState(false);
    const [openTosDialog, setOpenTosDialog] = useState(false);
    const [currentAgreementIndex, setCurrentAgreementIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [verificationSessionUrl, setVerificationSessionUrl] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'warning'
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Initialize Stripe verification session when component mounts
        initializeStripeVerification();
    }, []);

    const initializeStripeVerification = async () => {
        try {
            setLoading(true);
            // Call your backend API to create a Stripe verification session
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verification/create-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${keycloak.token}`
                },
                body: JSON.stringify({
                    // Add any necessary data for verification
                    email: keycloak.tokenParsed?.email
                })
            });

            const data = await response.json();
            if (data.url) {
                setVerificationSessionUrl(data.url);
            }
        } catch (error) {
            console.error('Failed to initialize Stripe verification:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            dispatch(setCurrentStep('first'));
            navigate('/first');
        }
    };

    const handleConfirm = async () => {
        if (activeStep < steps.length - 1) {
            dispatch(setCurrentStep('third'));
            const token = keycloak.tokenParsed;
            try {
                const result = await dispatch(updateOnboardingRecord({
                    email: token?.email,
                    onboardingData: {
                        current_step: 'third',
                        completed_steps: ['first', 'second']
                    }
                })).unwrap();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } catch (error) {
                console.error('Failed to update server:', error);
            }
        }
    };

    const handleTosChange = (event) => {
        setIsTosChecked(event.target.checked);
    };

    const handleOpenTosDialog = () => {
        setOpenTosDialog(true);
        setCurrentAgreementIndex(0);
    };

    const handleCloseTosDialog = () => {
        setOpenTosDialog(false);
    };

    const handleNextAgreement = () => {
        if (currentAgreementIndex < agreements.length - 1) {
            setCurrentAgreementIndex((prevIndex) => prevIndex + 1);
        } else {
            setOpenTosDialog(false);
        }
    };

    const handleStartVerification = () => {
        if (verificationSessionUrl) {
            window.location.href = verificationSessionUrl;
        }
    };

    const handleDisabledNextClick = () => {
        if (!isTosChecked) {
            setSnackbar({
                open: true,
                message: 'Please agree to the Terms of Service before proceeding',
                severity: 'warning'
            });
            return;
        }

        if (verificationStatus !== 'verified') {
            setSnackbar({
                open: true,
                message: 'Please complete the verification process before proceeding',
                severity: 'warning'
            });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{padding: '32px', margin: '32px', borderRadius: '12px'}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    width: '100%'
                }}>
                    <Box>
                        <Typography variant='h4'>
                            Business Verification
                        </Typography>
                        <Typography variant='h5'>
                            Complete Your Business Verification with Stripe
                        </Typography>
                    </Box>
                </Box>

                <Divider sx={{my: 2}}/>

                {/* Stripe Verification Section */}
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Typography variant="body1" paragraph>
                        To comply with regulations and ensure secure transactions, we need to verify your business identity. 
                        This process is powered by Stripe, a leading payment processing platform.
                    </Typography>
                    
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleStartVerification}
                            disabled={!verificationSessionUrl}
                            sx={{ mt: 2 }}
                        >
                            Start Verification Process
                        </Button>
                    )}
                </Box>

                {/* TOS Section */}
                <Box sx={{mt: 2, display: 'flex', alignItems: 'flex-end'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isTosChecked}
                                onChange={handleTosChange}
                                color="primary"
                            />
                        }
                        label={
                            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                <Typography variant="body2" sx={{lineHeight: '1.2', display: 'flex', alignItems: 'flex-end'}}>
                                    I agree to the{' '}
                                    <Link
                                        component="button"
                                        onClick={handleOpenTosDialog}
                                        underline="hover"
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'orange',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: '1.2',
                                            ml: 0.5
                                        }}
                                    >
                                        Terms of Service
                                    </Link>.
                                </Typography>
                            </Box>
                        }
                    />
                </Box>

                {/* Navigation Buttons */}
                <Box sx={{ml: 'auto', mt: 5, display: 'flex', gap: '12px', justifyContent: 'flex-end'}}>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Tooltip title={
                        !isTosChecked 
                            ? "Please agree to the Terms of Service" 
                            : verificationStatus !== 'verified'
                                ? "Please complete the verification process"
                                : ""
                    }>
                        <span>
                            <Button
                                variant="save"
                                onClick={isTosChecked && verificationStatus === 'verified' ? handleConfirm : handleDisabledNextClick}
                                sx={{
                                    textTransform: 'none', 
                                    height: '34px',
                                    '&.Mui-disabled': {
                                        cursor: 'not-allowed',
                                    }
                                }}
                                disabled={!isTosChecked || verificationStatus !== 'verified'}
                            >
                                Next
                            </Button>
                        </span>
                    </Tooltip>
                </Box>

                {/* Snackbar for feedback */}
                <Snackbar 
                    open={snackbar.open} 
                    autoHideDuration={6000} 
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={handleSnackbarClose} 
                        severity={snackbar.severity} 
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>

                {/* TOS Dialog */}
                <Dialog open={openTosDialog} onClose={handleCloseTosDialog}>
                    <DialogContent>
                        <Container maxWidth="md" sx={{my: 4}}>
                            <Typography variant="h2" sx={{fontSize: '2.5rem'}} gutterBottom align="center">
                                {agreements[currentAgreementIndex].title}
                            </Typography>

                            <Divider sx={{my: 3}}/>

                            {agreements[currentAgreementIndex].content.map((section, index) => (
                                <div key={index}>
                                    <Typography variant="h6" gutterBottom>
                                        {section.header}
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        {section.text}
                                    </Typography>
                                </div>
                            ))}

                            <Divider sx={{my: 3}}/>
                        </Container>
                        <Typography variant="body2" color="textSecondary" align="center">
                            Last updated: 2/9/2024
                        </Typography>
                        <Typography align="center" variant="body2" sx={{mt: 2}}>
                            {`${currentAgreementIndex + 1} / ${agreements.length}`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="cancel" onClick={handleCloseTosDialog}>
                            Close
                        </Button>
                        <Button variant="save" onClick={handleNextAgreement}>
                            {currentAgreementIndex < agreements.length - 1 ? 'Next' : 'Finish'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default StripeKYCVerification;
