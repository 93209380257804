// eventlah-react-merchant/src/keycloak.js

import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: 'https://accounts.eventlah.com',
    realm: 'eventlah',
    clientId: 'ui-merchants-app'
};

const keycloak = new Keycloak(keycloakConfig);

let isInitialized = false;
let initPromise = null;

export const initKeycloak = () => {
    if (isInitialized) {
        return Promise.resolve(keycloak.authenticated);
    }

    if (initPromise) {
        return initPromise;
    }

    initPromise = keycloak
        .init({
            onLoad: 'check-sso',
            checkLoginIframe: false,
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            pkceMethod: 'S256' // Enable PKCE
        })
        .then(authenticated => {
            isInitialized = true;
            console.log('Keycloak initialized, authenticated:', authenticated);

            // Set up token refresh
            setInterval(() => {
                keycloak.updateToken(70).catch(() => {
                    console.error('Failed to refresh token');
                    keycloak.logout();
                });
            }, 60000); // Check token every minute

            return authenticated;
        })
        .catch(error => {
            console.error('Failed to initialize Keycloak:', error);
            isInitialized = false;
            initPromise = null;
            throw error;
        });

    return initPromise;
};

export const getToken = async () => {
    if (!isInitialized) {
        await initKeycloak();
    }

    try {
        await keycloak.updateToken(30);
        return keycloak.token;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        keycloak.logout();
        return null;
    }
};

// Add helper functions
export const isAuthenticated = () => {
    return keycloak.authenticated;
};

export const getUsername = () => {
    return keycloak.tokenParsed?.preferred_username;
};

export const hasRole = (role) => {
    return keycloak.hasRealmRole(role);
};

export const getOrganizationId = () => {
    return keycloak.tokenParsed?.organization_id;
};

export const updateToken = async (minValidity = 30) => {
    try {
        const refreshed = await keycloak.updateToken(minValidity);
        return refreshed;
    } catch (error) {
        console.error('Token refresh failed:', error);
        throw error;
    }
};

export default keycloak;
