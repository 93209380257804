import { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Divider } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PermissionGate from 'components/shared/PermissionGate';

const AgentCommissions = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const fetchCommissions = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/agent-commissions?page=${paginationModel.page + 1}&limit=${
                    paginationModel.pageSize
                }`
            );
            const result = await response.json();

            if (result.code === 200) {
                const transformedData = result.data.commissions.map(commission => ({
                    id: commission.id,
                    agent_id: commission.agent.id,
                    agent_name: commission.agent.name,
                    agent_email: commission.agent.email,
                    purchase_id: commission.purchase_id,
                    commission_rate: `${commission.commission_rate}%`,
                    commission_amount: `RM ${commission.commission_amount}`,
                    commission_status: commission.commission_status,
                    created_at: new Date(commission.created_at).toLocaleDateString(),
                    updated_at: new Date(commission.updated_at).toLocaleDateString(),
                }));

                setData(transformedData);
                setTotalCount(result.data.pagination.totalCount);
            }
        } catch (error) {
            console.error('Error fetching commissions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommissions();
    }, [paginationModel.page, paginationModel.pageSize]);

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = newModel => {
        setPaginationModel(newModel);
    };

    const columns = [
        { field: 'agent_name', headerName: 'Agent Name', flex: 1.5, minWidth: 150 },
        { field: 'agent_email', headerName: 'Agent Email', flex: 2, minWidth: 200 },
        { field: 'purchase_id', headerName: 'Purchase ID', flex: 1, minWidth: 100 },
        { field: 'commission_rate', headerName: 'Commission Rate', flex: 1, minWidth: 130 },
        { field: 'commission_amount', headerName: 'Commission Amount', flex: 1.5, minWidth: 150 },
    ];

    // Custom toolbar component with permission-gated features
    const CustomToolbar = () => (
        <Box>
            <GridToolbar
                componentsProps={{
                    toolbar: {
                        csvOptions: {
                            disableToolbarButton: !hasPermission('AGEN_EXPO'),
                        },
                        printOptions: {
                            disableToolbarButton: !hasPermission('AGEN_EXPO'),
                        },
                        columnsButton: {
                            disableToolbarButton: !hasPermission('AGEN_SETC'),
                        },
                        filterButton: {
                            disableToolbarButton: !hasPermission('AGEN_SETF'),
                        },
                        densitySelector: {
                            disableToolbarButton: !hasPermission('AGEN_SETD'),
                        },
                    },
                }}
            />
        </Box>
    );

    return (
        <PermissionGate featureCode='READ6' fallback={null}>
            <Box sx={{ padding: { xs: 1, md: '32px' } }}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                            width: '100%',
                        }}
                    >
                        <Box>
                            <Typography variant='h4'>Agent Commissions</Typography>
                            <Typography variant='h5'>Track your commission earnings from ticket sales</Typography>
                        </Box>
                    </Box>

                    <Divider />

                    <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                        <Box />
                        <Box display='flex' gap='12px'>
                            <PermissionGate featureCode='AGEN_SEAR1' fallback={null}>
                                <TextField
                                    variant='outlined'
                                    placeholder='Search commissions'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    size='small'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '34px',
                                            '& input': {
                                                height: '34px',
                                                padding: '0 14px',
                                            },
                                            '& .MuiInputBase-input': {
                                                height: '34px',
                                                padding: '0 14px',
                                            },
                                        },
                                    }}
                                />
                            </PermissionGate>
                        </Box>
                    </Box>

                    <Paper
                        sx={{
                            width: '100%',
                            borderRadius: '16px',
                            marginTop: '24px',
                            border: '1px solid var(--Gray-200, #EAECF0)',
                            background: 'var(--White, #FFF)',
                            boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Gray-900, #101828)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '28px',
                                padding: '20px 24px',
                            }}
                        >
                            Commission Details
                        </Typography>

                        <Divider sx={{ my: 0 }} />

                        <DataGrid
                            slots={{ toolbar: CustomToolbar }}
                            rows={data}
                            columns={columns}
                            rowCount={totalCount}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            paginationMode='server'
                            loading={loading}
                            checkboxSelection={hasPermission('AGEN_SELE')}
                        />
                    </Paper>
                </Box>
            </Box>
        </PermissionGate>
    );
};

// Helper function to simulate permission checking
const hasPermission = code => {
    // In a real application, this would check against your permission system
    // For now, we'll just return true to show all features
    return true;
};

export default AgentCommissions;
